import React from 'react'
import { usePrefixContext } from "../context/PrefixContext";
import { useAuthContext } from "../context/AuthContext";
import img_logox from "../Images/logox.png";

export default function Home() {
  const prefixContext = usePrefixContext();
  const authContext = useAuthContext();
  return (
    <div className='BackgroundIndex' style={{display:"flex" , justifyContent:"center" , alignItems:"center"}}>
      {prefixContext.isCompleted && authContext.isCompleted ? <img
                alt=""
                src={img_logox}
              /> : null}
      
    </div>
  )
}
