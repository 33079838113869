import React from "react";
import { Link } from "react-router-dom";
import { usePrefixContext } from "../../../context/PrefixContext";
import { useTranslation } from "react-i18next";

export default function StepRow(props) {
  const { bData, n, bgR } = props;
  const { prefix, getCurrentLanguage } = usePrefixContext();
  const { t } = useTranslation();

  //var play_text = null;
  //var play_hdc = "";
  var play_1h = null;
  if (bData[7][0][0] === 1) {
    //play_type = "แต้มต่อ";
    if (bData[7][0][9] === 1) {
      //play_text = <span className="GBGive4">{bData[7][0][7]}&nbsp;</span>;
      //play_hdc = (bData[7][0][2] !== "0" ? "-" : "") + hpdConv(bData[7][0][2]) + " ";
    } else {
      //play_text = <span className="GBTake4">{bData[7][0][7]}&nbsp;</span>;
      //play_hdc = hpdConv(bData[7][0][2]) + " ";
    }
  } else if (bData[7][0][0] === 2) {
    //play_type = "แต้มต่อ";
    if (bData[7][0][9] === 2) {
      //play_text = <span className="GBGive4">{bData[7][0][8]}&nbsp;</span>;
      //play_hdc = (bData[7][0][2] !== "0" ? "-" : "") + hpdConv(bData[7][0][2]) + " ";
    } else {
      //play_text = <span className="GBTake4">{bData[7][0][8]}&nbsp;</span>;
      //play_hdc = hpdConv(bData[7][0][2]) + " ";
    }
  } else if (bData[7][0][0] === 3) {
    //play_type = "สูง/ต่ำ";
    //play_hdc = "-" + hpdConv(bData[7][0][2]) + " ";
    //play_text = <span className="HdOver">สูง</span>;
  } else if (bData[7][0][0] === 4) {
    //play_type = "สูง/ต่ำ";
    //play_hdc = hpdConv(bData[7][0][2]) + " ";
    //play_text = <span className="HdUnder">ต่ำ</span>;
  } else if (bData[7][0][0] === 5) {
    //play_type = "1X2";
    //play_text = <span className="HdX12">{bData[7][0][7]} (ชนะ)</span>;
  } else if (bData[7][0][0] === 6) {
    //play_type = "1X2";
    //play_text = <span className="HdX12">{bData[7][0][8]} (ชนะ)</span>;
  } else if (bData[7][0][0] === 7) {
    //play_type = "1X2";
    //play_text = <span className="HdX12">{bData[7][0][7]} (เสมอ)</span>;
  } else if (bData[7][0][0] === 8) {
    //play_type = "คี่ / คู่";
    //play_text = <span className="HdOver">คี่</span>;
  } else if (bData[7][0][0] === 9) {
    //play_type = "คี่ / คู่";
    //play_text = <span className="HdUnder">คู่</span>;
  } else if (bData[7][0][0] === 10) {
    //play_type = "แต้มต่อ";
    play_1h = " ("+t("first_Half")+") ";
    if (bData[7][0][9] === 1) {
      //play_hdc = (bData[7][0][2] !== "0" ? "-" : "") + hpdConv(bData[7][0][2]) + " ";
      //play_text = <span className="GBGive4">{bData[7][0][7]}&nbsp;</span>;
    } else {
      //play_hdc = hpdConv(bData[7][0][2]) + " ";
      //play_text = <span className="GBTake4">{bData[7][0][7]}&nbsp;</span>;
    }
  } else if (bData[7][0][0] === 11) {
    //play_type = "แต้มต่อ";
    play_1h = " ("+t("first_Half")+") ";
    if (bData[7][0][9] === 2) {
      //play_hdc = (bData[7][0][2] !== "0" ? "-" : "") + hpdConv(bData[7][0][2]) + " ";
      //play_text = <span className="GBGive4">{bData[7][0][8]}&nbsp;</span>;
    } else {
      //play_hdc = hpdConv(bData[7][0][2]) + " ";
      //play_text = <span className="GBTake4">{bData[7][0][8]}&nbsp;</span>;
    }
  } else if (bData[7][0][0] === 12) {
    //play_type = "สูง/ต่ำ";
    play_1h = " ("+t("first_Half")+") ";
    //play_hdc = "-" + hpdConv(bData[7][0][2]) + " ";
    //play_text = <span className="HdOver">สูง</span>;
  } else if (bData[7][0][0] === 13) {
    //play_type = "สูง/ต่ำ";
    play_1h = " ("+t("first_Half")+") ";
    //play_hdc = hpdConv(bData[7][0][2]) + " ";
    //play_text = <span className="HdUnder">ต่ำ</span>;
  } else if (bData[7][0][0] === 14) {
    //play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    //play_text = <span className="HdX12">{bData[7][0][7]} (ชนะ)</span>;
  } else if (bData[7][0][0] === 15) {
    //play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    //play_text = <span className="HdX12">{bData[7][0][8]} (ชนะ)</span>;
  } else if (bData[7][0][0] === 16) {
    //play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    //play_text = <span className="HdX12">{bData[7][0][7]} (เสมอ)</span>;
  } else if (bData[7][0][0] === 17) {
    //play_type = "คี่ / คู่";
    play_1h = " ("+t("first_Half")+") ";
    //play_text = <span className="HdOver">คี่</span>;
  } else if (bData[7][0][0] === 18) {
    //play_type = "คี่ / คู่";
    play_1h = " ("+t("first_Half")+") ";
    //play_text = <span className="HdUnder">คู่</span>;
  }

  return (
    <tr className={bgR} align="center">
      <td className="GridColor" align="left">
        {" "}
        &nbsp;{n}{" "}
      </td>
      <td className="GridColor" align="left">
        {" "}
        {t(prefix.sport_list[bData[8]])}{" "}
      </td>
      <td className="GridColor" align="left">
        <span className="Hd2B">{bData[0]}</span>
        <br />
        {bData[1]}
      </td>
      <td className="GridColor" align="left">
        <span className="Hd2B">
          {bData[7][0][6]} <br />
          {bData[7][0][7]}{" "}
        </span>{" "}
        -vs- <span className="Hd2B">{bData[7][0][8]}&nbsp;&nbsp;</span>
        {play_1h}{bData[7][0][10] > 0 ? " (" + bData[7][0][12].replace("-", " - ") + ")" : ""}
        <br />
        <span className="Hd2B">{bData[7][0][14]}</span>
      </td>
      <td className="GridColor" align="left">
        <Link to="/" className="Normal_Stake" onClick={() => window.open("/ParTrans?id="+bData[0], "", "width=300,height=450,top=200,left=400,toolbars=no,scrollbars=yes,status=no,resizable=yes").focus()}>
          <span className="HdParlay">{t("mix_parlay")}</span>
          <br />
          &nbsp;@&nbsp;&nbsp; <span className="Hd2B">{formatMoney(bData[6], 3)}</span>
        </Link>
      </td>
      <td className="GridColor" align="right">
        <span className="Simple">{formatMoney(bData[2])}</span>
        <br />
        <font style={{ color: "Gray" }}>
          <span className="Simple">{formatMoney(bData[9], 2)}</span>
        </font>
      </td>
      <td className="GridColor" align="center">
        {bData[5] === 3 ? <span className="Error">{t("Waiting")}</span> : (bData[5] === 1 || bData[5] === 0) && bData[4] === 4 ? <span className="GBGive4">ถูกปฏิเสธ</span> : <span className="Accepted">{t("ACCEPTED")}</span>}
      </td>
    </tr>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
