import React, { useEffect , useRef } from "react";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

var zbBlink = {};
export default function ColumHDP(props) {
  const { pt , atChange , b_hf, dPrice , sortTable , setBusketBet } = props;
  const prevBall = usePrevious(dPrice);
  const prevSort = usePrevious(sortTable);
  
  

  //console.log(prevBall);
  //console.log((b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_hdp");
  //var zbBlink = {};
  /*if(sportBlink!==""){
    zbBlink = JSON.parse(sportBlink);
  }*/
  if(prevBall!==undefined && atChange===false && sortTable===prevSort){
    if(dPrice[0]===prevBall[0]){
      if(dPrice[8]!==prevBall[8] || dPrice[9]!==prevBall[9]){
        zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_hdp"] = new Date();
      }else{
        var nb = zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_hdp"];
        var now_t = new Date();
        var d_time = getMinutesBetweenDates(nb, now_t);
        if(d_time>7){
          delete zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_hdp"];
        }
      }

      if(dPrice[24]!==prevBall[24] || dPrice[25]!==prevBall[25]){
        zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_hdp"] = new Date();
      }else{
        var nb = zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_hdp"];
        var now_t = new Date();
        var d_time = getMinutesBetweenDates(nb, now_t);
        if(d_time>7){
          delete zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_hdp"];
        }
      }
    }
  }

  /*if(atx!==activeUser.at){
    
    console.log(activeUser.at);
  }*/
  //console.log(zbBlink);

  var b_hdc = "";
  var b_hdc_1 = "";
  var b_hdc_2 = "";

  var b_big = 1;

  if (b_hf === 1) {
    if (dPrice[23] !== "") {
      b_hdc = dPrice[23];
      b_hdc_1 = formatMoney(dPrice[24], 2);
      b_hdc_2 = formatMoney(dPrice[25], 2);
      b_big = dPrice[22];
    }
  } else {
    if (dPrice[7] !== "") {
      b_hdc = dPrice[7];
      b_hdc_1 = formatMoney(dPrice[8], 2);
      b_hdc_2 = formatMoney(dPrice[9], 2);
      b_big = dPrice[6];
    }
  }

  const selectBet = (t) => {
    var sp_select = {};
    sp_select["BetHF"] = b_hf;
    sp_select["BetAdd"] = dPrice[1];
    sp_select["BetMatch"] = dPrice[0];
    sp_select["BetType"] = t;
    sp_select["id_type"] = (b_hf===1 ? dPrice[21] : dPrice[5]);
    sp_select["id_type_num"] = (b_hf===1 ? 5 : 1);
    setBusketBet(sp_select);
  };

  /*if(dPrice[0]===8408579){
    console.log(b_hdc_1 , dPrice[8]);
  }*/

  //console.log(b_hdc_1);

  return (
    <>
      {pt === 0 ? (
        <>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
            className="HDPBG_GridItem"
          >
            <span className="Heading8">{b_hdc}</span>
          </div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
            className={"HDPBG_GridItem"+(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_hdp"]>0 && b_hdc !== "" ? " NewOdds2" : "")}
          >
            <span
              style={{ cursor: "pointer", textDecoration: "none" }}
              className={parseFloat(b_hdc_1) < 0.0 ? "NegOdds" : "PosOdds"}
            >
              <label onClick={() => selectBet("home")}>{b_hdc_1}</label>
            </span>
          </div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
            className={"HDPBG_GridItem"+(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_hdp"]>0 && b_hdc !== "" ? " NewOdds2" : "")}
          >
            <span
              style={{ cursor: "pointer", textDecoration: "none" }}
              className={parseFloat(b_hdc_2) < 0.0 ? "NegOdds" : "PosOdds"}
            >
              <label onClick={() => selectBet("away")}>{b_hdc_2}</label>
            </span>
          </div>
        </>
      ) : pt === 1 ? (
        <div style={{ display: "table-cell", width: "82px" }}>
          <div style={{ display: "table" }} className="StrStyleSoc">
            <div style={{ display: "table-row" }}>
              <div
                style={{
                  display: "table-cell",
                  width: "41px",
                  textAlign: "left",
                }}
                className="Heading8"
              >
                &nbsp;{b_big === 1 ? b_hdc : ""}
              </div>
              <div
                style={{
                  display: "table-cell",
                  width: "41px",
                  textAlign: "right",
                }}
                className={(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_hdp"]>0 && b_hdc !== "" ? " NewOdds2" : "")}
              >
                <span
                  className={parseFloat(b_hdc_1) < 0.0 ? "NegOdds" : "PosOdds"}
                >
                  <label onClick={() => selectBet("home")}>{b_hdc_1}</label>
                </span>
                &nbsp;
              </div>
            </div>
            <div style={{ display: "table-row" }}>
              <div
                style={{
                  display: "table-cell",
                  textAlign: "left",
                }}
                className="Heading8"
              >
                &nbsp;{b_big === 2 ? b_hdc : ""}
              </div>
              <div
                style={{
                  display: "table-cell",
                  textAlign: "right",
                }}
                className={(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_hdp"]>0 && b_hdc !== "" ? " NewOdds2" : "")}
              >
                <span
                  className={parseFloat(b_hdc_2) < 0.0 ? "NegOdds" : "PosOdds"}
                >
                  <label onClick={() => selectBet("away")}>{b_hdc_2}</label>
                </span>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      ) : pt === 2 ? (
        ""
      ) : (
        ""
      )}
    </>
  );
}

function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}

function getMinutesBetweenDates(startDate, endDate) {
  var diffMs = endDate - startDate;
  var diffSec = diffMs / 1000;
  return diffSec;
}