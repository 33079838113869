import React from "react";

export default function ParList(props) {
  const { parData } = props;

  var play_type = "";
  var play_text = null;
  var play_hdc = "";
  var play_1h = "";
  if (parData[0] === 1) {
    play_type = "แต้มต่อ";
    if(parData[9]===1){
        play_text = parData[7];
        play_hdc = (parData[2]!=="0" ? "-" : "")+hpdConv(parData[2]);
    }else{
        play_text = parData[7];
        play_hdc = hpdConv(parData[2]);
    }
  } else if (parData[0] === 2) {
    play_type = "แต้มต่อ";
    if(parData[9]===2){
        play_text = parData[8];
        play_hdc = (parData[2]!=="0" ? "-" : "")+hpdConv(parData[2]);
    }else{
        play_text = parData[8];
        play_hdc = hpdConv(parData[2]);
    }
  } else if (parData[0] === 3) {
    play_type = "สูง/ต่ำ";
    play_hdc = parData[2];
    play_text = "สูง";
  } else if (parData[0] === 4) {
    play_type = "สูง/ต่ำ";
    play_hdc = parData[2];
    play_text = "ต่ำ";
  } else if (parData[0] === 5) {
    play_type = "1X2";
    play_text = "Home Win";
  } else if (parData[0] === 6) {
    play_type = "1X2";
    play_text = "Away Win";
  } else if (parData[0] === 7) {
    play_type = "1X2";
    play_text = "Draw";
  } else if (parData[0] === 8) {
    play_type = "คี่ / คู่";
    play_text = "คี่";
  } else if (parData[0] === 9) {
    play_type = "คี่ / คู่";
    play_text = "คู่";
  } else if (parData[0] === 10) {
    play_type = "แต้มต่อ";
    play_1h = "(ครึ่งแรก)";
    if(parData[9]===1){
        play_hdc = (parData[2]!=="0" ? "-" : "")+hpdConv(parData[2]);
        play_text = parData[7];
    }else{
        play_hdc = hpdConv(parData[2]);
        play_text = parData[7];
    }
  } else if (parData[0] === 11) {
    play_type = "แต้มต่อ";
    play_1h = "(ครึ่งแรก)";
    if(parData[9]===2){
        play_hdc = (parData[2]!=="0" ? "-" : "")+hpdConv(parData[2]);
        play_text = parData[8];
    }else{
        play_hdc = hpdConv(parData[2]);
        play_text = parData[8];
    }
  } else if (parData[0] === 12) {
    play_type = "สูง/ต่ำ";
    play_1h = "(ครึ่งแรก)";
    play_hdc = parData[2];
    play_text = "สูง";
  } else if (parData[0] === 13) {
    play_type = "สูง/ต่ำ";
    play_1h = "(ครึ่งแรก)";
    play_hdc = parData[2];
    play_text = "ต่ำ";
  } else if (parData[0] === 14) {
    play_type = "1X2";
    play_1h = "(ครึ่งแรก)";
    play_text = "Home Win";
  } else if (parData[0] === 15) {
    play_type = "1X2";
    play_1h = "(ครึ่งแรก)";
    play_text = "Away Win";
  } else if (parData[0] === 16) {
    play_type = "1X2";
    play_1h = "(ครึ่งแรก)";
    play_text = "Draw";
  } else if (parData[0] === 17) {
    play_type = "คี่ / คู่";
    play_1h = "(ครึ่งแรก)";
    play_text = "คี่";
  } else if (parData[0] === 18) {
    play_type = "คี่ / คู่";
    play_1h = "(ครึ่งแรก)";
    play_text = "คู่";
  }

  return (
    <tr>
      <td>
        <table className="bold" style={{ borderCollapse: "collapse" }} cellSpacing={0} cellPadding={3} width="100%" border={0}>
          <tbody>
            <tr>
              <td> {parData[6]} </td>
            </tr>
            <tr>
              <td>
                {" "}
                {parData[7]} -vs- {parData[8]} {(parData[10]>0 ? <br /> : null)} {(parData[10]>0 ? "("+parData[12].replace("-", " - ")+")" : "")} <br />
                {parData[5]}{" "}{play_1h}
              </td>
            </tr>
            <tr>
              <td> ชนิด : {play_type} &nbsp;&nbsp;&nbsp;อัตราต่อรอง : {formatMoney(parData[1] , 2)} </td>
            </tr>
            <tr>
              <td>
                {" "}
                เดิมพัน : {play_text} <br />
                &nbsp;{play_hdc} @&nbsp;&nbsp;{formatMoney(parData[1] , 2)}{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
function hpdConv(val) {
  var $pos1 = val.indexOf("-");
  if (val.length === 1 || $pos1 < 0) {
    return val;
  } else {
    var lv = val.split("-");
    //console.log(lv);
    var new_val = parseFloat(lv[0]) + (parseFloat(lv[1]) - parseFloat(lv[0])) / 2;
    return formatMoney(new_val, 2);
  }
}
