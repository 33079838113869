import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePrefixContext } from '../../context/PrefixContext';
import BetPar from "./BetPar";
import BetOne from "./BetOne";


import axios from "axios";
import apiBet from "../../js/apiBet";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function ViewLast10bet() {
  const [isCompleted, setIsCompleted] = useState(false);
  const [betListData, setBetListData] = useState([]);
  const { getCurrentLanguage } = usePrefixContext();
  const { t } = useTranslation();
  
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        setIsCompleted(false);
        apiBet.defaults.headers.common["x-lang"] = getCurrentLanguage();
        await apiBet
          .get(`getListBet?view=2`, { cancelToken: cancelToken.token })
          .then((res) => {
            setBetListData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            }
          });
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [getCurrentLanguage]);

  return (
    <>
      {isCompleted === true ? (
        betListData.length > 0 ? (
          <div className="StakeBg1 " style={{ padding: 2 }}>
            {betListData.map(function (tlist, j) {
              return (
                (tlist[3]==="step" ? <BetPar key={j} bData={tlist} /> : <BetOne key={j} bData={tlist} />)
              );
            })}
          </div>
        ) : (
          <div
            className="GridItemBet StakeBg2 PanelStakeGrid"
            style={{
              padding: 3,
              marginTop: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t("No_bet")}
          </div>
        )
      ) : (
        <div
          className="GridItemBet StakeBg2 PanelStakeGrid"
          style={{
            padding: 3,
            marginTop: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkeletonTheme
            baseColor="#816847"
            highlightColor="#d0bf70"
            duration={1}
          >
            <Skeleton />
            <Skeleton />
          </SkeletonTheme>
        </div>
      )}
    </>
  );
}

