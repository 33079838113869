import React, { useState, useEffect } from "react";
var hourTemp,
  minute,
  minuteTemp,
  second,
  secondTemp,
  monthnumber,
  monthnumberTemp,
  monthday,
  monthdayTemp,
  year,
  ap;

var gmt = "GMT+7:00";
function timefunction() {
  var serverTime = new Date();
  hourTemp = serverTime.getHours();

  minuteTemp = minute = serverTime.getMinutes();
  if (minute.toString().length === 1) minuteTemp = "0" + minute.toString();

  secondTemp = second = serverTime.getSeconds();
  if (second.toString().length === 1) secondTemp = "0" + second.toString();

  monthnumberTemp = monthnumber = serverTime.getMonth();
  if ((monthnumber + 1).toString().length === 1)
    monthnumberTemp = "0" + (monthnumber + 1).toString();
  else monthnumberTemp = (monthnumber + 1).toString();

  monthdayTemp = monthday = serverTime.getDate();
  if (monthday.toString().length === 1)
    monthdayTemp = "0" + monthday.toString();
  year = serverTime.getFullYear();
  ap = "AM";
  if (hourTemp > 11) {
    ap = "PM";
  }
  if (hourTemp > 12) {
    hourTemp = hourTemp - 12;
  }
  if (hourTemp === 0) {
    hourTemp = 12;
  }
  if (hourTemp.toString().length === 1) hourTemp = "0" + hourTemp.toString();

  return (
    monthdayTemp +
    "-" +
    monthnumberTemp +
    "-" +
    year +
    " " +
    hourTemp +
    ":" +
    minuteTemp +
    " " +
    ap +
    " (" +
    gmt +
    ")"
  );
}
export default function TimeFunc() {
  const [timecontainer, setTimecontainer] = useState(timefunction());

  useEffect(() => {
    const timerI = setInterval(() => {
      //console.log(timefunction());
      setTimecontainer(timefunction());
    }, 1000);

    return () => {
      clearTimeout(timerI);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div>{timecontainer}</div>;
}
