import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { usePrefixContext } from '../../context/PrefixContext';

import BetPar from "./BetPar";
import BetOne from "./BetOne";



import { Link } from "react-router-dom";

import axios from "axios";
import apiBet from "../../js/apiBet";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function ViewWait() {
  const [isCompleted, setIsCompleted] = useState(false);
  const [betListData, setBetListData] = useState([]);
  const [cTime, setCTime] = useState(5);
  const [activeState, setActiveState] = useState(false);
  const { getCurrentLanguage } = usePrefixContext();
  const { t } = useTranslation();
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    var newLoad = null;
    var cTT = 5;
    async function fetchData(c) {
      try {
        if(c==="f"){
          setIsCompleted(false);
        }
        apiBet.defaults.headers.common["x-lang"] = getCurrentLanguage();
        await apiBet
          .get(`getListBet?view=1`, { cancelToken: cancelToken.token })
          .then((res) => {
            setBetListData(res.data.data);
            if(res.data.data.length>0){
              cTT = 5;
              setCTime(cTT);
              newLoad = setInterval(count_load , 1000);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            }
          });
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }

    fetchData("f");
    

    function count_load(){
      if(cTT>0){
        cTT = cTT-1;
        setCTime(cTT);
      }else{
        clearInterval(newLoad);
        fetchData("r");
      }
      //console.log(new Date());
    }

    return () => {
      cancelToken.cancel();
      clearInterval(newLoad);
    };
  }, [activeState , getCurrentLanguage]);
  const handleClick = () => {
    const newState = !activeState
    setActiveState(newState);
  };


  return (
    <>
      {isCompleted === true ? (
        betListData.length > 0 ? (
          <div className="StakeBg1 " style={{ padding: 2 }}>
            <div style={{width: '100%', textAlign: 'center'}}>
              <Link to="/" className='btnGrey' onClick={handleClick}>
                <span>{t("Refresh")}&nbsp;{cTime}</span>
              </Link>
            </div>
            {betListData.map(function (tlist, j) {
              return (
                (tlist[3]==="step" ? <BetPar key={j} bData={tlist} /> : <BetOne key={j} bData={tlist} />)
              );
            })}
          </div>
        ) : (
          <div
            className="GridItemBet StakeBg2 PanelStakeGrid"
            style={{
              padding: 3,
              marginTop: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t("No_waiting_bet")}
          </div>
        )
      ) : (
        <div
          className="GridItemBet StakeBg2 PanelStakeGrid"
          style={{
            padding: 3,
            marginTop: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SkeletonTheme
            baseColor="#816847"
            highlightColor="#d0bf70"
            duration={1}
          >
            <Skeleton />
            <Skeleton />
          </SkeletonTheme>
        </div>
      )}
    </>
  )
}