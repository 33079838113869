import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import tabHIcon from "../../Images/tabHIcon.png";

import axios from "axios";
import apiBet from "../../js/apiBet";
import LoadSkeletonInfo from "../LoadSkeletonInfo";
export default function AccInfo() {
  const { activeUser } = useAuthContext();
  const [isCompleted, setIsCompleted] = useState(false);
  const [betAccData, setBetAccData] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        setIsCompleted(false);
        //const token = localStorage.getItem("a-token");
        //api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        await apiBet
          .get(`getBetInfo`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setBetAccData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <div style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }} className="Backgroundx ">
      <div style={{ height: "12px" }}></div>
      <div className="preferenceTB">
        <div></div>
        <div style={{ width: "775px" }} className="tabGridBg1">
          <table width="100%" height="100%" border="0" cellSpacing="1" cellPadding="0">
            <tbody>
              <tr className="tabHeaderBg">
                <td>
                  <table width="100%" height="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td style={{ width: "10px" }}></td>
                        <td style={{ width: "25px" }} align="left">
                          <img alt="" src={tabHIcon} />
                        </td>
                        <td align="left" className="GridHeaderx">
                        {t("Account_Info")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="tabGridBg2">
                  <table width="100%" height="100%" border="0" cellSpacing="5" cellPadding="0">
                    <tbody>
                      <tr>
                        <td className="tabGridBg3">
                          <table width="100%" height="100%" border="0" cellSpacing="5" cellPadding="0">
                            <tbody>
                              <tr>
                                <td className="tabGridBg4">
                                  {isCompleted === true ? (
                                    <table width="100%" height="100%" border={0} cellSpacing={0} cellPadding={5} className="GridTable">
                                      <tbody>
                                        <tr>
                                          <td className="r GridItemStyle AccItemStyle" width="30%">
                                            <span>{t("User_Name")}</span>:
                                          </td>
                                          <td className="l GridAltItemStyle AccItemStyle" width="70%">
                                            <span id="lblmUserName" className="Bold">
                                              {activeUser.m_user}
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="r GridItemStyle AccItemStyle">
                                            <span>{t("Currency")}</span>:
                                          </td>
                                          <td className="l GridAltItemStyle AccItemStyle">
                                            <span id="lblmCurrency" className="Bold">
                                              THB
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="r GridItemStyle AccAltItemStyle">
                                            <span>
                                            {t("Total_Balance")}
                                            </span>
                                            :
                                          </td>
                                          <td className="l GridAltItemStyle AccAltItemStyle">
                                            <span id="lblmTotalBalance" className="Bold">
                                              <span className={betAccData[0] < 0 ? "Negative" : ""}>{formatMoney(betAccData[0], 2)}</span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="r GridItemStyle AccItemStyle">
                                            <span>{t("Remaining_Sportsbook_Credit_Limit")}</span>:
                                          </td>
                                          <td className="l GridAltItemStyle AccItemStyle">
                                            <span id="lblmCredit" className="Bold">
                                              <span className="Positive">{formatMoney(activeUser.m_count, 2)}</span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="r GridItemStyle AccItemStyle">
                                            <span>{t("Min_Max_Bet")}</span>:
                                          </td>
                                          <td className="l GridAltItemStyle AccItemStyle">
                                            <span id="lblmMinBet" className="Bold">
                                              <span className="Positive">{formatMoney(activeUser.m_min_bet, 0)}</span> / <span className="Positive">{formatMoney(activeUser.m_max_bet, 0)}</span>
                                            </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="r GridItemStyle AccAltItemStyle">
                                            <span>{t("Total_Outstanding")}</span>:
                                          </td>
                                          <td className="l GridAltItemStyle AccAltItemStyle">
                                            <span id="lblmTotalOutstanding" className="Bold">
                                              <span className="Positive">{formatMoney(betAccData[2], 0)}</span> ({formatMoney(betAccData[1], 0)} {t("bets")}){" "}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  ) : <LoadSkeletonInfo />}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
