import React from "react";
import { useTranslation } from 'react-i18next';

export default function TitleBasketball() {
  const { t } = useTranslation();
  return (
    <>
        <div style={{ display: "table-row" }} className="GridHeader">
          <div style={{ display: "table-cell", width: "45px" }} className="table_th3">{t("time")}</div>
          <div style={{ display: "table-cell", width: "221px" }} className="table_th4">{t("event")}</div>
          <div style={{ display: "table-cell" , width: "45px"}}>
            <div style={{ width: "1px", border:"none" }}>
                <div style={{ position: "relative", width: "508px", overflow: "hidden", border:"none" }}>
                    <div style={{ display: "table" , borderWidth:"0px 0px 0px 0px" }}>
                        <div style={{ display: "table-row", borderWidth:"0px 0px 0px 0px" }} className="GridHeader">
                            <div style={{ display: "table-cell" , width: "45px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">
                            <div style={{ width: "1px", border:"none" }}>
                                <div style={{ position: "relative", width: "254px", overflow: "hidden", border:"none" }}>{t("full_Time")}</div>
                            </div>
                            </div>
                            <div style={{ display: "table-cell" , width: "82px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                            <div style={{ display: "table-cell" , width: "82px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                            <div style={{ display: "table-cell" , width: "45px" , borderWidth:"0px 1px 1px 0px" }} className="table_th1">&nbsp;</div>
                            <div style={{ display: "table-cell" , width: "45px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">
                            <div style={{ width: "1px", border:"none" }}>
                                <div style={{ position: "relative", width: "254px", overflow: "hidden", border:"none" }}>{t("first_Half")}</div>
                            </div>
                            </div>
                            <div style={{ display: "table-cell" , width: "82px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                            <div style={{ display: "table-cell" , width: "82px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                            <div style={{ display: "table-cell" , width: "45px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                        </div>
                        <div style={{ display: "table-row", borderWidth:"0px 0px 0px 0px" }} className="GridHeader">
                            <div style={{ display: "table-cell" , width: "45px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">ML</div>
                            <div style={{ display: "table-cell" , width: "82px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">แต้มต่อ</div>
                            <div style={{ display: "table-cell" , width: "82px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">สูงต่ำ</div>
                            <div style={{ display: "table-cell" , width: "45px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">คึ่/คู่</div>
                            <div style={{ display: "table-cell" , width: "45px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">ML</div>
                            <div style={{ display: "table-cell" , width: "82px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">แต้มต่อ</div>
                            <div style={{ display: "table-cell" , width: "82px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">สูงต่ำ</div>
                            <div style={{ display: "table-cell" , width: "45px", borderWidth:"0px" }} className="table_th1">คึ่/คู่</div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div style={{ display: "table-cell" , width: "82px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "82px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "45px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "45px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "82px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "82px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "45px" }}>&nbsp;</div>
      </div>
      
    </>
  );
}
