import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import img_print from "../../Images/print.gif";
import img_mbModIcon from "../../Images/mbModIcon.png";

import ParList from "./ParList";

export default function BetPar(props) {
  const { bData } = props;
  const [toogle_bet, setToogle_bet] = useState(false);
  const { t } = useTranslation();

  const handleToogle_bet = () => {
    setToogle_bet(!toogle_bet);
  };

  return (
    <div
      className="GridItemBet StakeBg2"
      style={{
        marginTop: 3,
        padding: 2,
        border: "1px solid #c0c0c0",
        borderCollapse: "collapse",
        height: "100%",
        lineHeight: "1.3",
      }}
    >
      <font size={1}>
        {bData[0]}&nbsp;({bData[1]})
      </font>
      <br />
      <div style={{ display: "inline-block", width: "100%", marginTop: 2 }}>
        <div style={{ float: "left" }}>
          <font size={1} className=" Bold">
            {t("mix_parlay")} &nbsp; {formatMoney(bData[6], 3)}
          </font>
        </div>
        <div style={{ float: "right" }}>
          <img src={img_mbModIcon} style={{ cursor: "pointer" }} border={0} height={14} width={14} alt="" onClick={handleToogle_bet} />
        </div>
      </div>
      <div style={{ display: toogle_bet === true ? "" : "none" }}>
        {bData[7].map(function (tlist, j) {
          return <ParList key={j} parData={tlist} />;
        })}
      </div>
      <div style={{ display: "inline-block", width: "100%", marginTop: 5 }}>
        <div style={{ float: "left" }}>{bData[5] === 3 ? <span className="Waiting ">{t("Waiting")}</span> : (bData[5]===1 || bData[5]===0) && bData[4]===4 ? <span className="Rejected">ถูกปฏิเสธ</span> : <span className="Accepted2 ">{t("RunningBet")}</span>}</div>
        <div className="Bold" style={{ float: "right" }}>
          {formatMoney(bData[2])}
          {bData[5] === 1 && bData[4] === 5 ? (
            <Link to={"/BetSlip?id=" + bData[0]} target="_blank">
              <img src={img_print} border={0} height={14} width={14} alt="" />
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
