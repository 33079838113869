import React from "react";
import { useTranslation } from 'react-i18next';

export default function TitleBall1Row() {
  const { t } = useTranslation();
  return (
    <>
        <div style={{ display: "table-row" }} className="GridHeader">
          <div style={{ display: "table-cell", width: "45px" }} className="table_th3">{t("time")}</div>
          <div style={{ display: "table-cell", width: "175px" }} className="table_th4">{t("event")}</div>
          <div style={{ display: "table-cell" , width: "44px"}}>
            <div style={{ width: "1px", border:"none" }}>
            <div style={{ position: "relative", width: "555px", overflow: "hidden", border:"none" }}>
            <div style={{ display: "table" , borderWidth:"0px 0px 0px 0px" }}>
              <div style={{ display: "table-row", borderWidth:"0px 0px 0px 0px" }} className="GridHeader">
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">
                  <div style={{ width: "1px", border:"none" }}>
                    <div style={{ position: "relative", width: "265px", overflow: "hidden", border:"none" }}>{t("full_Time")}</div>
                  </div>
                </div>
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 1px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "45px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">
                  <div style={{ width: "1px", border:"none" }}>
                    <div style={{ position: "relative", width: "286px", overflow: "hidden", border:"none" }}>{t("first_Half")}</div>
                  </div>
                </div>
                <div style={{ display: "table-cell" , width: "45px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "44px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                <div style={{ display: "table-cell" , width: "65px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
              </div>
              <div style={{ display: "table-row", borderWidth:"0px 0px 0px 0px" }} className="GridHeader">
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("hdp")}</div>
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("home")}</div>
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("away")}</div>
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("goal")}</div>
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("over")}</div>
                <div style={{ display: "table-cell" , width: "45px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("under")}</div>
                <div style={{ display: "table-cell" , width: "45px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("hdp")}</div>
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("home")}</div>
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("away")}</div>
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("goal")}</div>
                <div style={{ display: "table-cell" , width: "44px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">{t("over")}</div>
                <div style={{ display: "table-cell" , width: "65px", borderWidth:"0px 0px 0px 0px" }} className="table_th1">{t("under")}</div>
              </div>
            </div>
            </div>
            </div>
          </div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "45px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "45px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "44px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "21px" }}>&nbsp;</div>
      </div>
      
    </>
  );
}
