import React, { useEffect , useRef } from "react";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

var zbBlink = {};
export default function Colum1X2(props) {
  const { b_hf , atChange , dPrice, SportsPriceSelect , sortTable , setBusketBet } = props;
  const prevBall = usePrevious(dPrice);
  const prevSort = usePrevious(sortTable);

  if(prevBall!==undefined && atChange===false && sortTable===prevSort){
    if(dPrice[0]===prevBall[0]){
      if(dPrice[15]!==prevBall[15] || dPrice[16]!==prevBall[16] || dPrice[17]!==prevBall[17]){
        zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_1x2"] = new Date();
      }else{
        var nb = zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_1x2"];
        var now_t = new Date();
        var d_time = getMinutesBetweenDates(nb, now_t);
        if(d_time>7){
          delete zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_1x2"];
        }
      }

      if(dPrice[31]!==prevBall[31] || dPrice[32]!==prevBall[32] || dPrice[33]!==prevBall[33]){
        zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_1x2"] = new Date();
      }else{
        var nb = zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_1x2"];
        var now_t = new Date();
        var d_time = getMinutesBetweenDates(nb, now_t);
        if(d_time>7){
          delete zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_1x2"];
        }
      }
    }
  }

  var b_1x2_1 = "";
  var b_1x2_x = "";
  var b_1x2_2 = "";

  if(b_hf===1){
    if(dPrice[31]>0.00 && dPrice[32]>0.00 && dPrice[33]>0.00){
      b_1x2_1 = formatMoney(dPrice[31], 2);
      b_1x2_x = formatMoney(dPrice[32], 2);
      b_1x2_2 = formatMoney(dPrice[33], 2);
    }
  }else{
    if(dPrice[15]>0.00 && dPrice[16]>0.00 && dPrice[17]>0.00){
      b_1x2_1 = formatMoney(dPrice[15], 2);
      b_1x2_x = formatMoney(dPrice[16], 2);
      b_1x2_2 = formatMoney(dPrice[17], 2);
    }
  }

  const selectBet = (t) => {
    var sp_select = {};
    sp_select["BetHF"] = b_hf;
    sp_select["BetAdd"] = dPrice[1];
    sp_select["BetMatch"] = dPrice[0];
    sp_select["BetType"] = t;
    sp_select["id_type"] = (b_hf===1 ? dPrice[30] : dPrice[14]);
    sp_select["id_type_num"] = (b_hf===1 ? 7 : 3);
    setBusketBet(sp_select);
  };

  if (SportsPriceSelect === 3) {

    return (
      <>
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
          }}
          className={
            "HDPBG_GridItem" +
            (zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_1x2"]>0 && b_1x2_1>0.00 && b_1x2_2>0.00 && b_1x2_x>0.00 ? " NewOdds2" : "")
          }
        >
          <span
            style={{ cursor: "pointer", textDecoration: "none" }}
            className={parseFloat(b_1x2_1) < 0.0 ? "NegOdds" : "PosOdds"}
          >
            <label onClick={() => selectBet("home")}>{b_1x2_1}</label>
          </span>
        </div>
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
          }}
          className={
            "HDPBG_GridItem" +
            (zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_1x2"]>0 && b_1x2_1>0.00 && b_1x2_2>0.00 && b_1x2_x>0.00 ? " NewOdds2" : "")
          }
        >
          <span
            style={{ cursor: "pointer", textDecoration: "none" }}
            className={parseFloat(b_1x2_x) < 0.0 ? "NegOdds" : "PosOdds"}
          >
            <label onClick={() => selectBet("draw")}>{b_1x2_x}</label>
          </span>
        </div>
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
          }}
          className={
            "HDPBG_GridItem" +
            (zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_1x2"]>0 && b_1x2_1>0.00 && b_1x2_2>0.00 && b_1x2_x>0.00 ? " NewOdds2" : "")
          }
        >
          <span
            style={{ cursor: "pointer", textDecoration: "none" }}
            className={parseFloat(b_1x2_2) < 0.0 ? "NegOdds" : "PosOdds"}
          >
            <label onClick={() => selectBet("away")}>{b_1x2_2}</label>
          </span>
        </div>
      </>
    );
  }

  

  return (
    <div
      style={{
        display: "table-cell",
        width: "40px",
        textAlign: "center",
      }}
    >
      <div style={{ display: "table" }} className="StrStyleSoc">
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              width: "40px",
              textAlign: "center",
            }}
            className={(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_1x2"]>0 && b_1x2_1>0.00 && b_1x2_2>0.00 && b_1x2_x>0.00 ? " NewOdds2" : "")}
          >
            <span className="PosOdds">
              <label onClick={() => selectBet("home")}>{(b_1x2_1==="" ? '\u00A0' : b_1x2_1)}</label>
            </span>
          </div>
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              width: "40px",
              textAlign: "center",
            }}
            className={(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_1x2"]>0 && b_1x2_1>0.00 && b_1x2_2>0.00 && b_1x2_x>0.00 ? " NewOdds2" : "")}
          >
            <span className="PosOdds">
              <label onClick={() => selectBet("away")}>{(b_1x2_2==="" ? '\u00A0' : b_1x2_2)}</label>
            </span>
          </div>
        </div>
        <div style={{ display: "table-row" }}>
          <div
            style={{
              display: "table-cell",
              width: "40px",
              textAlign: "center",
            }}
            className={(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_1x2"]>0 && b_1x2_1>0.00 && b_1x2_2>0.00 && b_1x2_x>0.00 ? " NewOdds2" : "")}
          >
            <span className="PosOdds">
              <label onClick={() => selectBet("draw")}>{(b_1x2_x==="" ? '\u00A0' : b_1x2_x)}</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}


function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN(cx = Math.abs(cx)) ? 0 : cx,
      d = dx === undefined ? "." : dx,
      t = tx === undefined ? "," : tx,
      s = n < 0 ? "-" : "",
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j = (jx = i.length) > 3 ? jx % 3 : 0;

  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}

function getMinutesBetweenDates(startDate, endDate) {
  var diffMs = endDate - startDate;
  var diffSec = diffMs / 1000;
  return diffSec;
}