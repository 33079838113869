import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function LoadSkeleton(props) {
  const { liveTB } = props;
  var bg1f = "";
  var bg2f = "";
  if (liveTB === 1) {
    bg1f = "#fbcbbd";
    bg2f = "#fdddd2";
  } else {
    bg1f = "#e8e8e8";
    bg2f = "#f8f8f8";
  }

  return (
    <SkeletonTheme baseColor="#816847" highlightColor="#d0bf70" duration={1}>
      <table
        width={"100%"}
        cellPadding={5}
        cellSpacing={0}
        className="GridBorder"
      >
        <thead>
          <tr className="GridHeader">
            <td className="table_th1_R" style={{ height: "15px" }}>
              &nbsp;
            </td>
          </tr>
        </thead>
        <tbody>
          {liveTB === 1 ? (
            <>
              <tr className="Event">
                <td style={{}}>
                  <Skeleton />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr className="Event">
                <td style={{}}>
                  <Skeleton />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="Event">
                <td style={{}}>
                  <Skeleton />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="Event">
                <td style={{}}>
                  <Skeleton />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </SkeletonTheme>
  );
}
