import React, { useState, useEffect } from "react";
import LoadSkeletonHistory from "../../LoadSkeletonHistory";
import RowsHis from "./RowsHis";

import { useTranslation } from "react-i18next";

import axios from "axios";
import apiBet from "../../../js/apiBet";

export default function AccHis(props) {
  const { cc_link } = props;
  const [isCompleted, setIsCompleted] = useState(false);
  const [betListData, setBetListData] = useState([]);
  const [showWeek, setShowWeek] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        setIsCompleted(false);
        await apiBet
          .get(`getHistory?id=${showWeek}`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setBetListData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [showWeek]);
  return (
    <table width="100%" height="100%" border={0} cellSpacing={0} cellPadding={0}>
      <tbody>
        <tr className="">
          <td
            align="right"
            style={{
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <button className="BtnNormal" style={{ height: 20 }} onClick={() => setShowWeek(1)}>
            {t("Last_Week")}
            </button>
            &nbsp;
            <button className="BtnNormal" style={{ height: 20 }} onClick={() => setShowWeek(0)}>
            {t("This_Week")}
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <table
              className="GridStyle"
              cellSpacing={0}
              cellPadding={3}
              rules="all"
              id="ctl00_grdHistory"
              style={{
                borderWidth: 1,
                borderStyle: "solid",
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr className="GridHeaderStyle" align="right">
                  <td className="GridColor" align="left">
                    {" "}
                    {t("Date")}{" "}
                  </td>
                  <td className="GridColor">
                    <span className="ellipsis" title="เดิมพัน" style={{ maxWidth: 70 }}>
                    {t("Stake")}
                    </span>
                  </td>
                  <td className="GridColor">
                    <span className="ellipsis" title="ชนะหรือแพ้" style={{ maxWidth: 70 }}>
                    {t("W_L")}
                    </span>
                  </td>
                  <td className="GridColor">
                    <span className="ellipsis" title="ค่าคอม" style={{ maxWidth: 70 }}>
                    {t("Com")}
                    </span>
                  </td>
                  <td className="GridColor">
                    <span className="ellipsis" title="การโอนยอด" style={{ maxWidth: 70 }}>
                    {t("Settled")}
                    </span>
                  </td>
                  <td className="GridColor">
                    <span className="ellipsis" title="ยอดเงิน" style={{ maxWidth: 70 }}>
                    {t("Balance")}
                    </span>
                  </td>
                </tr>
                {isCompleted === true ? (
                  betListData.map(function (tlist, j) {
                    var bgR = "";
                    if (j % 2 === 0) {
                      bgR = "GridItemStyle";
                    } else {
                      bgR = "GridAltItemStyle";
                    }
                    return <RowsHis key={j} bData={tlist} bgR={bgR} cc_link={cc_link} />;
                  })
                ) : (
                  <LoadSkeletonHistory />
                )}
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
