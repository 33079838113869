import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

export default function RowsHis(props) {
  const { bData, bgR , cc_link } = props;

  const { t } = useTranslation();

  var list_date_cc = [t("Sun"), t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat")];
  return (
    <tr className={bgR} align="right">
      <td className="GridColor" align="left">
        {/*" "}
        {list_date_cc[bData[0]]} {bData[1]}{" "*/}
        
        {
          bData[2] > 0 ? <Link className="Link2" to="/" onClick={() => cc_link(1 , bData[7])}>{" "}{list_date_cc[bData[0]]} {bData[1]}{" "}</Link> : " "+list_date_cc[bData[0]]+" "+bData[1]+" "
        }
      </td>
      <td className="GridColor" align="right" style={{ width: 80 }}>
        <span className="Simple">{formatMoney(bData[2], 2)}</span>
      </td>
      <td className="GridColor" align="right" style={{ width: 80 }}>
        <span className={bData[3] >= 0 ? "Hd9 Simple" : "Negative Simple"}>{formatMoney(bData[3], 2)}</span>
      </td>
      <td className="GridColor" align="right" style={{ width: 60 }}>
        <span className="Hd9 Simple">0.00</span>
      </td>
      <td className="GridColor" align="right" style={{ width: 80 }}>
        <span className="Simple">0.00</span>
      </td>
      <td className="GridColor" align="right" style={{ width: 80 }}>
        <span className={bData[6] >= 0 ? "Positive" : "Negative"}>{formatMoney(bData[6], 2)}</span>
      </td>
    </tr>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}