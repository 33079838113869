import React from "react";
import { useAuthContext } from "./context/AuthContext";
import { usePrefixContext } from './context/PrefixContext';
import { useTranslation } from 'react-i18next';
import WebMain from "./WebMain";
import Maintainence from "./components/Maintainence";
import Home from "./components/Home";


function App() {
  const authContext = useAuthContext();
  const { prefix } = usePrefixContext();
  useTranslation();

  //console.log(authContext);
  return (
    <>
      {authContext.isCompleted && authContext.isNoMember===false ? (
        <WebMain />
      ) : prefix!==null && prefix.mtn===0 ?
      <Maintainence />
      :
      <Home />
      }
    </>
  );
}

export default App;
