import React, { createContext, useContext, useState, useEffect, useRef } from 'react'
import LoadingItem from '../components/LoadingItem';
import api from '../js/api';
import { console_log } from '../js/dev';
import './../css/loading.css';

export const LoadingContext = createContext();

function LoadingContextProvider(props) {
    const animRef = useRef(null);
    const [taskCount, setTaskCount] = useState(0);

    useEffect(() => {
        // Add a request interceptor
        api.interceptors.request.use(function (config) {
            // config.loading === true && presentLoading();
            return config;
        }, function (error) {
            // Do something with request error
            // dismissLoading();
            return Promise.reject(error);
        });

        // Add a response interceptor
        api.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // dismissLoading();
            return response;
        }, function (error) {
            // dismissLoading()
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });
        console_log(`add api interceptors`);
    }, []);


    useEffect(() => {
        // If loading start to show then reset animation
        if(taskCount === 1){
            animRef.current.goToAndPlay(0, true);
        }
    }, [taskCount]);
    
    const presentLoading = () => {
        const n = taskCount + 1;
        setTaskCount(n);
        console_log(`present loading, current task = ${n}`);
    }

    const dismissLoading = () => {
        const x = taskCount - 1;
        const n =  x < 0 ? 0 : x;
        setTaskCount(n);
        console_log(`dismiss loading, current task = ${n}`);
    }

    const value = { presentLoading, dismissLoading };

    return (
        <LoadingContext.Provider value={value}>
            {props.children}
            <LoadingItem ref={animRef} taskCount={taskCount} />
        </LoadingContext.Provider>
    )
}

function useLoadingContext() {
    const context = useContext(LoadingContext)
    if (context === undefined) {
        throw new Error('useLoadingContext must be used within a LoadingProvider')
    }
    return context
}

export { useLoadingContext }

export default LoadingContextProvider;