import React from "react";
import { usePrefixContext } from '../context/PrefixContext';
import { useTranslation } from 'react-i18next';

import img_ft_close from "../Images/ft_close.gif";

export default function BoxStep(props) {
  const { stepData , removeBet } = props;
  const { prefix } = usePrefixContext();
  const { t } = useTranslation();

  var price_data = {};

  if (stepData.id_type_num === 1 && stepData.BetHF === 2) {
    if (stepData.BetType === "home") {
      price_data["BetPlay"] = (
        <span className={stepData.IsHomeGive === true ? "txtBetRedBold" : "txtBetBlueBold"}>
          {stepData.Home}
        </span>
      );
    } else {
      price_data["BetPlay"] = (
        <span className={stepData.IsHomeGive === false ? "txtBetRedBold" : "txtBetBlueBold"}>
          {stepData.Away}
        </span>
      );
    }
    price_data["title_bet"] = t(prefix.sport_list[stepData.Zone])+" / แต้มต่อ";
  } else if (stepData.id_type_num === 2 && stepData.BetHF === 2) {
    if (stepData.BetType === "home") {
      price_data["BetPlay"] = <span className="txtBet">สูง</span>;
    } else {
      price_data["BetPlay"] = <span className="txtBet">ต่ำ</span>;
    }
    price_data["title_bet"] = t(prefix.sport_list[stepData.Zone])+" / สูง/ต่ำ";
  } else if (stepData.id_type_num === 3 && stepData.BetHF === 2) {
    if (stepData.BetType === "home") {
      price_data["BetPlay"] = (
        <>
          <span className="txtBet">{stepData.Home}</span>&nbsp;&nbsp;&nbsp;
          <span className="txtBetBlueBold">เต็มเวลา.1</span>
        </>
      );
    } else if (stepData.BetType === "away") {
      price_data["BetPlay"] = (
        <>
          <span className="txtBet">{stepData.Away}</span>&nbsp;&nbsp;&nbsp;
          <span className="txtBetBlueBold">เต็มเวลา.2</span>
        </>
      );
    } else {
      price_data["BetPlay"] = (
        <>
          <span className="txtBet">{stepData.Home}</span>&nbsp;&nbsp;&nbsp;
          <span className="txtBetBlueBold">เต็มเวลา.X</span>
        </>
      );
    }
    price_data["title_bet"] = t(prefix.sport_list[stepData.Zone])+" / 1X2";
  } else if (stepData.id_type_num === 4 && stepData.BetHF === 2) {
    if (stepData.BetType === "odd") {
      price_data["BetPlay"] = <span className="txtBet">คี่</span>;
    } else {
      price_data["BetPlay"] = <span className="txtBet">คู่</span>;
    }
    price_data["title_bet"] = t(prefix.sport_list[stepData.Zone])+" / คี่/คู่";
  } else if (stepData.id_type_num === 5 && stepData.BetHF === 1) {
    if (stepData.BetType === "home") {
      price_data["BetPlay"] = (
        <span className={stepData.IsHomeGive === true ? "txtBetRedBold" : "txtBetBlueBold"}>
          {stepData.Home}
        </span>
      );
    } else {
      price_data["BetPlay"] = (
        <span className={stepData.IsHomeGive === false ? "txtBetRedBold" : "txtBetBlueBold"}>
          {stepData.Away}
        </span>
      );
    }
    price_data["title_bet"] = t(prefix.sport_list[stepData.Zone])+" / ครึ่งแรก แต้มต่อ";
  } else if (stepData.id_type_num === 6 && stepData.BetHF === 1) {
    if (stepData.BetType === "home") {
      price_data["BetPlay"] = <span className="txtBet">สูง</span>;
    } else {
      price_data["BetPlay"] = <span className="txtBet">ต่ำ</span>;
    }

    price_data["title_bet"] = t(prefix.sport_list[stepData.Zone])+" / ครึ่งแรก สูง/ต่ำ";
  } else if (stepData.id_type_num === 7 && stepData.BetHF === 1) {
    if (stepData.BetType === "home") {
      price_data["BetPlay"] = (
        <>
          <span className="txtBet">{stepData.Home}</span>&nbsp;&nbsp;&nbsp;
          <span className="txtBetBlueBold">ครึ่งแรก.1</span>
        </>
      );
    } else if (stepData.BetType === "away") {
      price_data["BetPlay"] = (
        <>
          <span className="txtBet">{stepData.Away}</span>&nbsp;&nbsp;&nbsp;
          <span className="txtBetBlueBold">ครึ่งแรก.2</span>
        </>
      );
    } else {
      price_data["BetPlay"] = (
        <>
          <span className="txtBet">{stepData.Home}</span>&nbsp;&nbsp;&nbsp;
          <span className="txtBetBlueBold">ครึ่งแรก.X</span>
        </>
      );
    }

    price_data["title_bet"] = t(prefix.sport_list[stepData.Zone])+" / ครึ่งแรก 1X2";
  } else if (stepData.id_type_num === 8 && stepData.BetHF === 1) {
    if (stepData.BetType === "odd") {
      price_data["BetPlay"] = <span className="txtBet">คี่</span>;
    } else {
      price_data["BetPlay"] = <span className="txtBet">คู่</span>;
    }
    price_data["title_bet"] = t(prefix.sport_list[stepData.Zone])+" / ครึ่งแรก คี่/คู่";
  }

  var showBlink = 0;
  var now_t = new Date();
  var d_time = getMinutesBetweenDates(stepData.betBlink, now_t);
  if(d_time<7){
    showBlink = 1;
  }else{
    showBlink = 0;
  }

  return (
    <div
      className={(stepData.IsRun === true ? "GridRunItemBet" : "GridItemBet")+(stepData.betStatus !== 1 ? " BetBoxBlink" : "")}
      style={{
        display: "inline-block",
        width: "100%",
        height: "100%",
        padding: "3px 5px 7px 5px",
        boxSizing: "border-box",
        lineHeight: "1.4",
      }}
    >
      <div
        style={{
          display: "inline-block",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className={stepData.IsRun === true ? "title-R" : "title-T"}
          style={{
            textAlign: "left",
            float: "left",
            fontWeight: "bold",
            width: "90%",
          }}
        >
          {price_data["title_bet"]}
        </div>
        <div
          style={{
            display: "inline-block",
            textAlign: "right",
            width: "10%",
          }}
        >
          <img
            id="btnDelete"
            src={img_ft_close}
            width="14px"
            height="14px"
            alt=""
            border={0}
            style={{ cursor: "pointer" }}
            onClick={() => removeBet(stepData)}
          />
        </div>
      </div>
      <div
        className="txtBetBold border-R"
        style={{
          display: "inline-block",
          textAlign: "left",
          width: "95%",
          paddingLeft: 3,
        }}
      >
        {price_data["BetPlay"]}
        <br />
        {((stepData.IsHomeGive === true && stepData.BetType === "home" && stepData.BetHdp !== "0") || (stepData.IsHomeGive === false && stepData.BetType === "away" && stepData.BetHdp !== "0" && stepData.BetHdp !== "") ? "-" : "")+stepData.BetHdp}&nbsp;
        <span style={{ color: "#666666", fontWeight: "normal" }}>{stepData.IsRun === true ? "["+stepData.Score+"]" : ""}</span>
        &nbsp;@&nbsp;&nbsp;
        <label className={(showBlink===1 ? "OddsChgBlink" : "")}>
        {stepData.BetOdds}
        </label>
      </div>
      <div style={{ color: "#666666", textAlign: "left" }}>
        {stepData.Home}
      </div>
      <div style={{ color: "#666666", textAlign: "left" }}>
        {stepData.Away}
      </div>
      {stepData.betStatus !== 1 ? <div style={{textAlign: 'left', color: 'red', fontSize: 9}}>ไม่เปิดราคาสำหรับคู่นี้</div> : null}
    </div>
  );
}
function getMinutesBetweenDates(startDate, endDate) {
    var diffMs = endDate - startDate;
    var diffSec = diffMs / 1000;
    return diffSec;
  }