import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import axios from "axios";
import apiBet from "./js/apiBet";

import ParTransList from "./components/betView/ParTransList";

export default function ParTrans() {
  const [betData, setBetData] = useState([]);
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    async function fetchData() {
      try {
        var stk = searchParams.get("id");
        //setIsCompleted(false);
        const token = localStorage.getItem("a-token");
        apiBet.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiBet.defaults.headers.common["x-lang"] = localStorage.getItem("lang");
        await apiBet
          .get(`getBet?id=${stk}`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setBetData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
        //setIsCompleted(true);
      } catch (error) {
        //setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [searchParams]);

  if (betData.length <= 0) {
    return null;
  }

  return (
    <table style={{paddingBottom:"1.4em"}}>
      <tbody>
        <tr>
          <td />
          <td>
            <table className="BoldBet" cellSpacing={0} cellPadding={0} width={250}>
              <tbody>
                <tr>
                  <td width={100}>
                    <span>{t("Ref_No")}</span> :
                  </td>
                  <td>{betData[0]}</td>
                </tr>
                <tr>
                  <td width={100}>
                    <span>{t("Date_Time")}</span> :
                  </td>
                  <td>{betData[1]}</td>
                </tr>
                <tr>
                  <td width={100}>
                    <span>{t("Game_Type")}</span> :
                  </td>
                  <td>
                    <span>{t("mix_parlay")}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <table className="BoldBet" cellSpacing={0} cellPadding={3} align="Center" rules="all" id="ParTrans_mb1_g" style={{ borderColor: "Black", borderWidth: 1, borderStyle: "solid", width: "100%", borderCollapse: "collapse" }}>
                      <tbody>
                        {betData[7].map(function (tlist, j) {
                          return <ParTransList key={j} n={j+1} parData={tlist} />;
                        })}
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>&nbsp;</td>
                </tr>
                <tr>
                  <td width={100}>
                    <span>{t("Bet_Amount")}</span> :
                  </td>
                  <td>{formatMoney(betData[2], 2)}</td>
                </tr>
                <tr>
                  <td width={100}>
                    <span>{t("Odds")}</span> :
                  </td>
                  <td>{formatMoney(betData[6], 3)}</td>
                </tr>
                <tr>
                  <td width={100}>
                    <span>{t("Est_Payout")}</span> :
                  </td>
                  <td>{formatMoney(betData[8] , 2)}</td>
                </tr>
                {
                  betData[4] !== 5 && betData[4] !== 4 ? <tr>
                  <td width={100}>
                    <span>{t("Act_Payout")}</span> :
                  </td>
                  <td>{formatMoney(betData[10], 2)}</td>
                </tr>
                :
                null
                }
                
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
