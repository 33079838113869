import React, { useEffect , useRef } from "react";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

var zbBlink = {};
export default function ColumOU(props) {
  const { pt , atChange , b_hf, dPrice , sortTable , setBusketBet } = props;
  const prevBall = usePrevious(dPrice);
  const prevSort = usePrevious(sortTable);

  if (prevBall !== undefined && atChange===false && sortTable===prevSort) {
    if(dPrice[0]===prevBall[0]){
      if(dPrice[12]!==prevBall[12] || dPrice[13]!==prevBall[13]){
        zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_ou"] = new Date();
      }else{
        var nb = zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_ou"];
        var now_t = new Date();
        var d_time = getMinutesBetweenDates(nb, now_t);
        if(d_time>7){
          delete zbBlink["ft_"+dPrice[0]+"_"+dPrice[1] + "_ou"];
        }
      }

      if(dPrice[28]!==prevBall[28] || dPrice[29]!==prevBall[29]){
        zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_ou"] = new Date();
      }else{
        var nb = zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_ou"];
        var now_t = new Date();
        var d_time = getMinutesBetweenDates(nb, now_t);
        if(d_time>7){
          delete zbBlink["1h_"+dPrice[0]+"_"+dPrice[1] + "_ou"];
        }
      }
    }
  }

  var b_goal = "";
  var b_goal_1 = "";
  var b_goal_2 = "";

  if (b_hf === 1) {
    if (dPrice[27] !== "") {
      b_goal = dPrice[27];
      b_goal_1 = formatMoney(dPrice[28], 2);
      b_goal_2 = formatMoney(dPrice[29], 2);
    }
  } else {
    if (dPrice[11] !== "") {
      b_goal = dPrice[11];
      b_goal_1 = formatMoney(dPrice[12], 2);
      b_goal_2 = formatMoney(dPrice[13], 2);
    }
  }

  const selectBet = (t) => {
    var sp_select = {};
    sp_select["BetHF"] = b_hf;
    sp_select["BetAdd"] = dPrice[1];
    sp_select["BetMatch"] = dPrice[0];
    sp_select["BetType"] = t;
    sp_select["id_type"] = (b_hf===1 ? dPrice[26] : dPrice[10]);
    sp_select["id_type_num"] = (b_hf===1 ? 6 : 2);
    setBusketBet(sp_select);
  };

  return (
    <>
      {pt === 0 ? (
        <>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
            className="HDPBG_GridItem"
          >
            <span className="Heading8">{b_goal}</span>
          </div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
            className={"HDPBG_GridItem"+(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_ou"]>0 && b_goal !== "" ? " NewOdds2" : "")}
          >
            <span
              style={{ cursor: "pointer", textDecoration: "none" }}
              className={parseFloat(b_goal_1) < 0.0 ? "NegOdds" : "PosOdds"}
            >
              <label onClick={() => selectBet("home")}>{b_goal_1}</label>
            </span>
          </div>
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textAlign: "center",
            }}
            className={(b_hf === 2 ? "SEP_R" : "HDPBG_GridItem")+(zbBlink[(b_hf===1 ? "1h_" : "ft_")+dPrice[0] + "_" + dPrice[1]+"_ou"]>0 && b_goal !== "" ? " NewOdds2" : "")}
          >
            <span
              style={{ cursor: "pointer", textDecoration: "none" }}
              className={parseFloat(b_goal_2) < 0.0 ? "NegOdds" : "PosOdds"}
            >
              <label onClick={() => selectBet("away")}>{b_goal_2}</label>
            </span>
          </div>
        </>
      ) : pt === 1 ? (
        <div style={{ display: "table-cell", width: "82px" }}>
          <div style={{ display: "table" }} className="StrStyleSoc">
            <div style={{ display: "table-row" }}>
              <div
                style={{
                  display: "table-cell",
                  width: "45px",
                  textAlign: "left",
                }}
                className="Heading8"
              >
                &nbsp;{b_goal}
              </div>
              <div
                style={{
                  display: "table-cell",
                  width: "37px",
                  textAlign: "right",
                }}
                className={
                  zbBlink[
                    (b_hf === 1 ? "1h_" : "ft_") +
                      dPrice[0] +
                      "_" +
                      dPrice[1] +
                      "_ou"
                  ] > 0 && b_goal !== ""
                    ? " NewOdds2"
                    : ""
                }
              >
                <span
                  className={parseFloat(b_goal_1) < 0.0 ? "NegOdds" : "PosOdds"}
                >
                  <label onClick={() => selectBet("home")}>{b_goal_1}</label>
                </span>
                &nbsp;
              </div>
            </div>
            <div style={{ display: "table-row" }}>
              <div
                style={{
                  display: "table-cell",
                  textAlign: "left",
                }}
              >
                &nbsp;
                <span className="HeadingOU_U">{b_goal === "" ? "" : "u"}</span>
              </div>
              <div
                style={{
                  display: "table-cell",
                  textAlign: "right",
                }}
                className={
                  zbBlink[
                    (b_hf === 1 ? "1h_" : "ft_") +
                      dPrice[0] +
                      "_" +
                      dPrice[1] +
                      "_ou"
                  ] > 0 && b_goal !== ""
                    ? " NewOdds2"
                    : ""
                }
              >
                <span
                  className={parseFloat(b_goal_2) < 0.0 ? "NegOdds" : "PosOdds"}
                >
                  <label onClick={() => selectBet("away")}>{b_goal_2}</label>
                </span>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      ) : pt === 2 ? (
        ""
      ) : (
        ""
      )}
    </>
  );
}



function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}

function getMinutesBetweenDates(startDate, endDate) {
  var diffMs = endDate - startDate;
  var diffSec = diffMs / 1000;
  return diffSec;
}