import React from "react";
import { useTranslation } from "react-i18next";

export default function ParTransList(props) {
  const { parData , n } = props;

  const { t } = useTranslation();

  var play_status = {
    "1" : t("Win_All"),
    "2" : t("Win_Half"),
    "3" : t("draw").toUpperCase(),
    "4" : t("Lose_All"),
    "5" : t("Lose_Half"),
    "6" : "ถูกปฏิเสธ",
    "7" : "รอผล"};

  var play_type = "";
  var play_text = null;
  var play_hdc = "";
  var play_1h = "";
  if (parData[0] === 1) {
    play_type = t("Handicap");
    if (parData[9] === 1) {
        play_text = <span className="GiveBet">{parData[7]}&nbsp;</span>;
      play_hdc = (parData[2] !== "0" ? "-" : "") + hpdConv(parData[2]);
    } else {
        play_text = <span className="TakeBet">{parData[7]}&nbsp;</span>;
      play_hdc = hpdConv(parData[2]);
    }
  } else if (parData[0] === 2) {
    play_type = t("Handicap");
    if (parData[9] === 2) {
        play_text = <span className="GiveBet">{parData[8]}&nbsp;</span>;
      play_hdc = (parData[2] !== "0" ? "-" : "") + hpdConv(parData[2]);
    } else {
        play_text = <span className="TakeBet">{parData[8]}&nbsp;</span>;
      play_hdc = hpdConv(parData[2]);
    }
  } else if (parData[0] === 3) {
    play_type = t("OVER_UNDER");
    play_hdc = parData[2];
    play_text = <span className="Over">{t("over")}</span>;
  } else if (parData[0] === 4) {
    play_type = t("OVER_UNDER");
    play_hdc = parData[2];
    play_text = <span className="Under">{t("under")}</span>;
  } else if (parData[0] === 5) {
    play_type = "1X2";
    play_text = <><span>{parData[7]}</span>&nbsp;&nbsp;&nbsp;<span className="gbX12">({t("FT").toUpperCase()}.1)</span></>;
  } else if (parData[0] === 6) {
    play_type = "1X2";
    play_text = <><span>{parData[8]}</span>&nbsp;&nbsp;&nbsp;<span className="gbX12">({t("FT").toUpperCase()}.2)</span></>;
  } else if (parData[0] === 7) {
    play_type = "1X2";
    play_text = <><span>{parData[7]}</span>&nbsp;&nbsp;&nbsp;<span className="gbX12">({t("FT").toUpperCase()}.X)</span></>;
  } else if (parData[0] === 8) {
    play_type = t("ODD_EVEN");
    play_text = <span className="Over">{t("Odd")}</span>;
  } else if (parData[0] === 9) {
    play_type = t("ODD_EVEN");
    play_text = <span className="Under">{t("Even")}</span>;
  } else if (parData[0] === 10) {
    play_type = t("Handicap");
    play_1h = t("first_Half");
    if (parData[9] === 1) {
      play_hdc = (parData[2] !== "0" ? "-" : "") + hpdConv(parData[2]);
      play_text = <span className="GiveBet">{parData[7]}&nbsp;</span>;
    } else {
      play_hdc = hpdConv(parData[2]);
      play_text = <span className="TakeBet">{parData[7]}&nbsp;</span>;
    }
  } else if (parData[0] === 11) {
    play_type = t("Handicap");
    play_1h = t("first_Half");
    if (parData[9] === 2) {
      play_hdc = (parData[2] !== "0" ? "-" : "") + hpdConv(parData[2]);
      play_text = <span className="GiveBet">{parData[8]}&nbsp;</span>;
    } else {
      play_hdc = hpdConv(parData[2]);
      play_text = <span className="TakeBet">{parData[8]}&nbsp;</span>;
    }
  } else if (parData[0] === 12) {
    play_type = t("OVER_UNDER");
    play_1h = t("first_Half");
    play_hdc = parData[2];
    play_text = <span className="Over">{t("over")}</span>;
  } else if (parData[0] === 13) {
    play_type = t("OVER_UNDER");
    play_1h = t("first_Half");
    play_hdc = parData[2];
    play_text = <span className="Under">{t("under")}</span>;
  } else if (parData[0] === 14) {
    play_type = "1X2";
    play_1h = t("first_Half");
    play_text = <><span>{parData[7]}</span>&nbsp;&nbsp;&nbsp;<span className="gbX12">({t("HT").toUpperCase()}.1)</span></>;
  } else if (parData[0] === 15) {
    play_type = "1X2";
    play_1h = t("first_Half");
    play_text = <><span>{parData[8]}</span>&nbsp;&nbsp;&nbsp;<span className="gbX12">({t("HT").toUpperCase()}.2)</span></>;
  } else if (parData[0] === 16) {
    play_type = "1X2";
    play_1h = t("first_Half");
    play_text = <><span>{parData[7]}</span>&nbsp;&nbsp;&nbsp;<span className="gbX12">({t("HT").toUpperCase()}.X)</span></>;
  } else if (parData[0] === 17) {
    play_type = t("ODD_EVEN");
    play_1h = t("first_Half");
    play_text = <span className="Over">{t("Odd")}</span>;
  } else if (parData[0] === 18) {
    play_type = t("ODD_EVEN");
    play_1h = t("first_Half");
    play_text = <span className="Under">{t("Even")}</span>;
  }
  return (
    <tr>
      <td className="Normal" align="left" valign="top" style={{ width: 20, whiteSpace: "nowrap" }}>
        &nbsp;{n}
      </td>
      <td>
        <table className="BoldBet" style={{ borderCollapse: "collapse" }} cellSpacing={0} cellPadding={0} width="100%" border={0}>
          <tbody>
            <tr>
              <td className="gb">
                <span className="Bold">{parData[6]}</span>
              </td>
            </tr>
            <tr>
              <td className="gb">{play_1h}</td>
            </tr>
            <tr>
              <td className="gb">
                <span className="gb">
                {parData[7]} -vs- {parData[8]}{(parData[10]>0 ? " ("+parData[12].replace("-", " - ")+")" : "")}
                  <br />
                  {parData[5]}
                  <br />
                  {t("Result")} : <strong>{(play_1h!=="" ? parData[14].replace("-", " - ") : parData[13].replace("-", " - "))} {(parData[3]!==7 ? "("+play_status[parData[3]]+")" : "")}</strong>
                </span>
              </td>
            </tr>
            <tr>
              <td className="gb">
                <span>{t("Type")}</span> : <span>{play_type}</span>
                &nbsp;&nbsp;&nbsp; <span>{t("Odds")}</span> : {formatMoney(parData[1] , 2)}
              </td>
            </tr>
            <tr>
              <td className="gb">
                <span>{t("Bet")}</span> : &nbsp;{play_text}&nbsp;
                <br />
                &nbsp;{play_hdc} @&nbsp;&nbsp;{formatMoney(parData[1] , 2)}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
function hpdConv(val) {
  var $pos1 = val.indexOf("-");
  if (val.length === 1 || $pos1 < 0) {
    return val;
  } else {
    var lv = val.split("-");
    //console.log(lv);
    var new_val = parseFloat(lv[0]) + (parseFloat(lv[1]) - parseFloat(lv[0])) / 2;
    return formatMoney(new_val, 2);
  }
}
