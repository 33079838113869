import React from "react";
import { usePrefixContext } from "../../../context/PrefixContext";
import { useTranslation } from "react-i18next";

export default function OneRow(props) {
  const { bData, n, bgR } = props;
  const { prefix } = usePrefixContext();
  const { t } = useTranslation();

  var play_status = {
    "1" : t("WON"),
    "2" : t("WON")+" (0.5)",
    "3" : t("draw").toUpperCase(),
    "4" : t("LOSE"),
    "5" : t("LOSE")+" (0.5)",
    "6" : "ถูกปฏิเสธ",
    "7" : "รอผล"};

  var play_text = null;
  var play_hdc = "";
  var play_type = "";
  var play_1h = null;
  if (bData[7][0][0] === 1) {
    play_type = t("hdp");
    if (bData[7][0][9] === 1) {
      play_text = <span className="HomeGiveTrue">{bData[7][0][7]}&nbsp;</span>;
      play_hdc = (bData[7][0][2] !== "0" ? "-" : "") + hpdConv(bData[7][0][2]) + " ";
    } else {
      play_text = <span className="HomeGiveFalse">{bData[7][0][7]}&nbsp;</span>;
      play_hdc = hpdConv(bData[7][0][2]) + " ";
    }
  } else if (bData[7][0][0] === 2) {
    play_type = t("hdp");
    if (bData[7][0][9] === 2) {
      play_text = <span className="HomeGiveTrue">{bData[7][0][8]}&nbsp;</span>;
      play_hdc = (bData[7][0][2] !== "0" ? "-" : "") + hpdConv(bData[7][0][2]) + " ";
    } else {
      play_text = <span className="HomeGiveFalse">{bData[7][0][8]}&nbsp;</span>;
      play_hdc = hpdConv(bData[7][0][2]) + " ";
    }
  } else if (bData[7][0][0] === 3) {
    play_type = t("O_U");
    play_hdc = "-" + hpdConv(bData[7][0][2]) + " ";
    play_text = <span className="HdOver">{t("over").toUpperCase()}</span>;
  } else if (bData[7][0][0] === 4) {
    play_type = t("O_U");
    play_hdc = hpdConv(bData[7][0][2]) + " ";
    play_text = <span className="HdUnder">{t("under").toUpperCase()}</span>;
  } else if (bData[7][0][0] === 5) {
    play_type = "1X2";
    play_text = <span className="HdX12">{bData[7][0][7]} ({t("WIN")})</span>;
  } else if (bData[7][0][0] === 6) {
    play_type = "1X2";
    play_text = <span className="HdX12">{bData[7][0][8]} ({t("WIN")})</span>;
  } else if (bData[7][0][0] === 7) {
    play_type = "1X2";
    play_text = <span className="HdX12">{bData[7][0][7]} ({t("DRAWBet")})</span>;
  } else if (bData[7][0][0] === 8) {
    play_type = t("odd")+" / "+t("even");
    play_text = <span className="HdOver">{t("Odd").toUpperCase()}</span>;
  } else if (bData[7][0][0] === 9) {
    play_type = t("odd")+" / "+t("even");
    play_text = <span className="HdUnder">{t("Even").toUpperCase()}</span>;
  } else if (bData[7][0][0] === 10) {
    play_type = t("hdp");
    play_1h = " ("+t("first_Half")+") ";
    if (bData[7][0][9] === 1) {
      play_hdc = (bData[7][0][2] !== "0" ? "-" : "") + hpdConv(bData[7][0][2]) + " ";
      play_text = <span className="HomeGiveTrue">{bData[7][0][7]}&nbsp;</span>;
    } else {
      play_hdc = hpdConv(bData[7][0][2]) + " ";
      play_text = <span className="HomeGiveFalse">{bData[7][0][7]}&nbsp;</span>;
    }
  } else if (bData[7][0][0] === 11) {
    play_type = t("hdp");
    play_1h = " ("+t("first_Half")+") ";
    if (bData[7][0][9] === 2) {
      play_hdc = (bData[7][0][2] !== "0" ? "-" : "") + hpdConv(bData[7][0][2]) + " ";
      play_text = <span className="HomeGiveTrue">{bData[7][0][8]}&nbsp;</span>;
    } else {
      play_hdc = hpdConv(bData[7][0][2]) + " ";
      play_text = <span className="HomeGiveFalse">{bData[7][0][8]}&nbsp;</span>;
    }
  } else if (bData[7][0][0] === 12) {
    play_type = t("O_U");
    play_1h = " ("+t("first_Half")+") ";
    play_hdc = "-" + hpdConv(bData[7][0][2]) + " ";
    play_text = <span className="GBOver Bold2">{t("over").toUpperCase()}</span>;
  } else if (bData[7][0][0] === 13) {
    play_type = t("O_U");
    play_1h = " ("+t("first_Half")+") ";
    play_hdc = hpdConv(bData[7][0][2]) + " ";
    play_text = <span className="HdUnder">{t("under").toUpperCase()}</span>;
  } else if (bData[7][0][0] === 14) {
    play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="HdX12">{bData[7][0][7]} ({t("WIN")})</span>;
  } else if (bData[7][0][0] === 15) {
    play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="HdX12">{bData[7][0][8]} ({t("WIN")})</span>;
  } else if (bData[7][0][0] === 16) {
    play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="HdX12">{bData[7][0][7]} ({t("DRAWBet")})</span>;
  } else if (bData[7][0][0] === 17) {
    play_type = t("odd")+" / "+t("even");
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="GBOver Bold2">{t("Odd").toUpperCase()}</span>;
  } else if (bData[7][0][0] === 18) {
    play_type = t("odd")+" / "+t("even");
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="HdUnder">{t("Even").toUpperCase()}</span>;
  }


  return (
    <tr className={bgR} align="right">
      <td className="GridColor" align="left">
      {n}
      </td>
      <td className="GridColor" align="left">
        <span className="bold">{bData[0]}</span>
        <br />
        {bData[1]}
      </td>
      <td className="GridColor" align="left">
      {t(prefix.sport_list[bData[8]])}
        <br />
        {play_type}
      </td>
      <td className="GridColor" align="left">
        <span>
        {bData[7][0][6]}
          <br />
          {bData[7][0][7]}
        </span>{" "}
        -vs- <span>{bData[7][0][8]}&nbsp;&nbsp;</span>{play_1h}{bData[7][0][10] > 0 ? " (" + bData[7][0][12].replace("-", " - ") + ")" : ""}
        <br />
        <span>{bData[7][0][14]}</span>
        <br />
        {play_text}
        <br />
        {play_hdc}@&nbsp;<span>{formatMoney(bData[7][0][1], 2)}{(bData[7][0][0]===5 || bData[7][0][0]===6 || bData[7][0][0]===7 || bData[7][0][0]===14 || bData[7][0][0]===15 || bData[7][0][0]===16 ? null : "("+bData[10]+")")}</span>
      </td>
      <td className="GridColor" align="center">
        <span className="Normal">{(play_1h!==null ? bData[7][0][16].replace("-", " - ") : bData[7][0][15].replace("-", " - "))}</span>
        <br />
        <span className={(bData[7][0][3]>=4 ? "Error" : "Normal")}>{play_status[bData[7][0][3]]}</span>
      </td>
      <td className="GridColor" align="right">
        <span className="Simple">{formatMoney(bData[2], 2)}</span>
      </td>
      <td className="GridColor" align="center">
      {bData[5] === 3 ? <span className="Error">{t("Waiting")}</span> : (bData[5] === 1 || bData[5] === 0) && bData[4] === 4 ? <span className="GBGive4">ถูกปฏิเสธ</span> : <span className="Accepted">{t("ACCEPTED")}</span>}
      </td>
      <td className="GridColor" align="right">
        <span className="Hd9 Simple">0.00</span>
      </td>
      <td className="GridColor" align="right">
        <span className={(bData[11]<0 ? "Negative Simple" : "Hd9 Simple")}>{formatMoney(bData[11], 2)}</span>
      </td>
    </tr>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
    var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
      d = dx === undefined ? "." : dx,
      t = tx === undefined ? "," : tx,
      s = n < 0 ? "-" : "",
      i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
      j = (jx = i.length) > 3 ? jx % 3 : 0;
  
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  }
  function hpdConv(val) {
    var $pos1 = val.indexOf("-");
    if (val.length === 1 || $pos1 < 0) {
      return val;
    } else {
      var lv = val.split("-");
      //console.log(lv);
      var new_val = parseFloat(lv[0]) + (parseFloat(lv[1]) - parseFloat(lv[0])) / 2;
      return formatMoney(new_val, 2);
    }
  }