import React , { useState , useEffect } from "react";
import BoxStep from "./BoxStep";
import { useAuthContext } from "../context/AuthContext";
import IntlCurrencyInput from "react-intl-currency-input"
import ClipLoader from 'react-spinners/ClipLoader';

import apiBet from "../js/apiBet";

const currencyConfig = {
  locale: "th-TH",
  formats: {
    number: {
      THB: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  },
};


export default function BetBox(props) {
  const { busketBetPar , setBusketBetPar , stepBusket , stepOdds , stepBlinkOdds , removeBet , setViewBet , setToogle_menu } = props;
  const { activeUser , setActUser } = useAuthContext();
  const [currentMinBet, setCurrentMinBet] = useState(activeUser.m_step_min_bet);
  //const [currentMaxBet, setCurrentMaxBet] = useState(activeUser.m_max_step);
  const [inputxBet, setInputBet] = useState(0);
  const [loadingInProgress, setLoading] = useState(false);

  useEffect(() => {
    setCurrentMinBet(activeUser.m_step_min_bet);
    //setCurrentMaxBet(activeUser.m_max_step);
    setInputBet(0);
    return () => {
      setInputBet(0);
    };
  }, [busketBetPar , activeUser]);

  const closeBet = () => {
    setBusketBetPar();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Call your function here
      saveBusket();
      // You can replace this with the desired function
    }
  };

  const handleChange = (event, value) => {
    event.preventDefault();
    setInputBet(value);
  };

  var currentMaxBet = activeUser.m_step_max_bet;
  var max_pay = 0;
  max_pay = (inputxBet*(stepOdds.toFixed(3)-1));
  
  var over_pay1 = (parseFloat(activeUser.m_step_max_bet)*(stepOdds.toFixed(3)-1));
  if(over_pay1>parseFloat(activeUser.m_paymax_step)){
    currentMaxBet = Math.floor((parseFloat(activeUser.m_paymax_step)/(stepOdds.toFixed(3)-1)));
  }


  var showBlink = 0;
  var now_t = new Date();
  var d_time = getMinutesBetweenDates(stepBlinkOdds, now_t);
  if(d_time<7){
    showBlink = 1;
  }else{
    showBlink = 0;
  }

  const saveBusket = async () => {
    try {
      if (window.confirm("ตกลงยอมรับทุกการเปลี่ยนแปลงราคาที่เกิดขึ้นของการเดิมพันนี้?") === true) {

        if(stepBusket.length<2){
          alert("อย่างน้อยต้องเลือก 2 ทีม สำหรับพาร์เลย์!");
          return false;
        }

        //console.log(stepBusket);


        if(inputxBet<currentMinBet || currentMinBet===undefined){
          alert("ยอดเดิมพันต่ำกว่าจำนวนที่กำหนด");
          return false;
        }

        if(inputxBet>currentMaxBet){
          alert("ยอดเดิมพันสูงกว่าจำนวนที่กำหนด");
          return false;
        }
        
        var $play_my_loss = inputxBet;

        if($play_my_loss>parseFloat(activeUser.m_count)){
          alert("เครดิตไม่เพียงพอ");
          return false;
        }

        var have_not = 0;
        for(var isp in stepBusket){
          var isp_data = stepBusket[isp];
          if(isp_data["betStatus"]===2){
            have_not = 1;
          }
        }

        if(have_not===1){
          return false;
        }
        
        //console.log(inputxBet , parseFloat(activeUser.m_count));
        //const token = localStorage.getItem("a-token");
        //api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        //console.log(token);
        setLoading(true);
        const res = await apiBet.post(`PanelBetStep`, {"amt":inputxBet , "betData":stepBusket });

        if(res.data.status===1){
          var old_act = activeUser;
          old_act["m_count"] = res.data.m_count;
          alert(res.data.data);
          setBusketBetPar();
          setToogle_menu(false);
          setActUser(old_act);
          setViewBet(res.data.w);
          setLoading(false);
        }else{
          alert(res.data.message);
          setLoading(false);
        }
        //console.log(res);
        
      }
      //alert(inputRef.current.value); 
    } catch (error) {
       alert("Error");
       setLoading(false);
    }
  };

  return (
    <div className="tbBetBoxPar" style={{display:(busketBetPar.length === 0 ? "none" : "") , width:"184px" , margin:"auto", position:"relative"}}>
      <div className="PanelBetBoxBgToday">
        <div className="BetBoxGrid" style={{ width: 174, padding: 5 }}>
          <div className="BetBoxBg3">
            <div className="BetBoxGrid3 txtBet" style={{ padding: "5px 2px" }}>
              <div id="divParMatch">
                {
                  stepBusket.map(function (tlist, j) {
                    return <BoxStep key={j} stepData={tlist} removeBet={removeBet} />
                  })
                }
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <div
                  className="txtBetBold"
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    width: "30%",
                    padding: "3px 0px",
                  }}
                >
                  คี่ :
                </div>
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    width: "60%",
                    padding: "3px 0px",
                  }}
                >
                  <span id="divParPO" className={"Boldbg"+(showBlink===1 ? " OddsChgBlink" : "")}>
                  {formatMoney(stepOdds, 3)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="txtBetBold"
            style={{
              display: "inline-block",
              width: "100%",
              height: "100%",
              padding: "3px 5px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "inline-block",
                textAlign: "left",
                width: "40%",
              }}
            >
              จำนวนเงิน:
            </div>
            <div
              style={{
                display: "inline-block",
                textAlign: "right",
                width: "60%",
              }}
            >
              <IntlCurrencyInput className="BetTextBox" style={{
                                    width: 100,
                                    height: 20,
                                    boxSizing: " border-box",
                                  }} 
                                  currency="THB" 
                                  autoFocus={true}
                                  config={currencyConfig} 
                                  max={Number(currentMaxBet)}
                                  value={inputxBet}
                                  onChange={handleChange}
                                  onKeyPress={handleKeyPress} />
            </div>
          </div>
          <div
            className="txtBetBold"
            style={{
              width: "100%",
              height: "100%",
              padding: "3px 5px",
              boxSizing: "border-box",
            }}
          >
            <div style={{ width: "98%" }}>
              <div
                className="BetBoxBg5"
                style={{
                  display: "inline-block",
                  width: "100%",
                  borderBottom: "1px solid #e0e4f0",
                }}
              >
                <div
                  className="BetBoxBg4"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "50%",
                    padding: "3px 0px",
                    verticalAlign: "middle",
                  }}
                >
                  <div style={{ paddingLeft: 4 }}>การจ่ายเงินขั้นสูงสุด</div>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    width: "50%",
                    padding: "3px 0px",
                    verticalAlign: "middle",
                  }}
                >
                  <div id="divMaxPayout" style={{ paddingRight: 4 }}>
                  {formatMoney(max_pay, 2)}
                  </div>
                </div>
              </div>
              <div
                className="BetBoxBg5"
                style={{
                  display: "inline-block",
                  width: "100%",
                  borderBottom: "1px solid #e0e4f0",
                }}
              >
                <div
                  className="BetBoxBg4"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "50%",
                    padding: "3px 0px",
                  }}
                >
                  <div style={{ paddingLeft: 4 }}>พนันขั้นต่ำสุด</div>
                </div>
                <div
                  className="txtBlue"
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    maxWidth: "50%",
                    width: "50%",
                    padding: "3px 0px",
                  }}
                >
                  <div id="divMinLimit" style={{ paddingRight: 4 }}>
                  {formatMoney(currentMinBet, 0)}
                  </div>
                </div>
              </div>
              <div
                className="BetBoxBg5"
                style={{
                  display: "inline-block",
                  width: "100%",
                  borderBottom: "1px solid #e0e4f0",
                }}
              >
                <div
                  className="BetBoxBg4"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "50%",
                    padding: "3px 0px",
                  }}
                >
                  <div style={{ paddingLeft: 4 }}>เดิมพันสูงสุด</div>
                </div>
                <div
                  className="txtRed"
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    width: "50%",
                    padding: "3px 0px",
                  }}
                >
                  <div id="divMaxLimit" style={{ paddingRight: 4 }}>
                  {formatMoney(currentMaxBet, 0)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="txtBetBold"
            style={{
              display: "inline-block",
              width: "100%",
              height: "100%",
              padding: "3px 5px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "inline-block",
                textAlign: "left",
                width: "50%",
              }}
            >
              <span style={{ cursor: "pointer" }}>
                <img
                  id="betBtnBet"
                  alt=""
                  src={"/locales/TH/submit.jpg"}
                  onMouseOver={(e) =>
                    (e.currentTarget.src = "/locales/TH/submit2.jpg")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.src = "/locales/TH/submit.jpg")
                  }
                  onClick={saveBusket}
                />
              </span>
            </div>
            <div
              style={{
                display: "inline-block",
                textAlign: "right",
                width: "50%",
              }}
            >
              <span style={{ cursor: "pointer" }}>
                <img
                  id="betBtnCancel"
                  alt=""
                  src={"/locales/TH/cancel.jpg"}
                  onMouseOver={(e) =>
                    (e.currentTarget.src = "/locales/TH/cancel2.jpg")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.src = "/locales/TH/cancel.jpg")
                  }
                  onClick={closeBet}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      {loadingInProgress ? (
        <div className="loader-container-bet">
          <ClipLoader color={'#d0bf70'} size={100} />
        </div>
      ) : (null)}
    </div>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
function getMinutesBetweenDates(startDate, endDate) {
  var diffMs = endDate - startDate;
  var diffSec = diffMs / 1000;
  return diffSec;
}