import React from "react";

import TitleBall2Row from "./TitleBall2Row";
import TitleBall1Row from "./TitleBall1Row";
import TitleBallOE from "./TitleBallOE";
import TitleBall1X2 from "./TitleBall1X2";
import TitleBallStep from "./TitleBallStep";

import TitleMuay from "./TitleMuay";
import TitleBasketball from "./TitleBasketball";

export default function TitleTBBall(props) {
  const { pt, liveTB, SportsSelect, SportsPriceSelect } = props;
  return (
    <div style={{ display: "table-row-group", position: "relative" }}>
      {SportsSelect === 1 ? (
        <TitleMuay />
      ) : SportsSelect === 7 ? (
        <TitleBasketball />
      ) : SportsPriceSelect === 2 ? (
        <TitleBallOE />
      ) : SportsPriceSelect === 3 ? (
        <TitleBall1X2 />
      ) : SportsPriceSelect === 4 ? (
        <TitleBallStep />
      ) : pt === 0 ? (
        <TitleBall1Row />
      ) : pt === 1 ? (
        <TitleBall2Row liveTB={liveTB} />
      ) : pt === 2 ? (
        ""
      ) : (
        ""
      )}
    </div>
  );
}
