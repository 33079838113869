import React, { createContext, useContext, useState, useEffect } from 'react';
import api from './../js/api';
import i18n, { fallbackLng } from '../i18nextConf';
import { useLoadingContext } from './LoadingContext';
//import { useAuthContext } from "./AuthContext";
export const PrefixContext = createContext();

const PrefixContextProvider = (props) => {
    const [prefix, setPrefix] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [error, setError] = useState(undefined);
    const [language, setLanguage] = useState(null);
    const { presentLoading } = useLoadingContext();
    const [currentFetch, setCurrentFetch] = useState("");
    //const authContext = useAuthContext();

    useEffect(() => {
        //if (authContext.isCompleted) {
            setCurrentLanguage(getCurrentLanguage());
       // }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getCurrentLanguage = () => {
        if (language !== null)
            return language;
        return localStorage.getItem("lang");
    }

    const setCurrentLanguage = async (l , cm) => {
        
        if (l === null) {
            l = fallbackLng[0];
        }
        api.defaults.headers.common["Accept-Language"] = l;
        setLanguage(l);
        i18n.changeLanguage(l);
        localStorage.setItem("lang", l);
        if(cm!=="cm"){
            presentLoading();
            await getPrefix();
        }
        return l;
    }

    const getPrefix = async () => {
        const timestamp = new Date().getTime();
        try {
            
            const p = await api.get(`prefix`);
            //console.log("ssssss");
            setPrefix(p.data);
        } catch (error) {
            setError(error);
            //console.log("wwwwww");
        } finally {
            //console.log(timestamp)
            setCurrentFetch(timestamp)
            setIsCompleted(true);
            //console.log("dddddd");
            //dismissLoading();
        }
    }

    const value = { prefix, isCompleted, error, getCurrentLanguage, setCurrentLanguage, currentFetch };

    return (
        <PrefixContext.Provider value={value}>
            {props.children}
        </PrefixContext.Provider>
    )
}

function usePrefixContext() {
    const context = useContext(PrefixContext)
    if (context === undefined) {
        throw new Error('usePrefix must be used within a PrefixProvider')
    }
    return context
}

export { usePrefixContext }

export default PrefixContextProvider;