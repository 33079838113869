import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

//const arrPA = { 0: "ลายเดี่ยว", 1: "ลายคู่", 2: "ง่าย" };
//const arrAt = { MY: "MY", HK: "ฮ่องกง", ID: "ID", EU: "EU" };

export default function OddsSel(props) {
  const { width, toggleOddsSel, onPA, pa , arrPA , disable } = props;

  const [toogle_pa, setToogle_pa] = useState(false);

  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setToogle_pa(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const handlePA = (value) => {
    onPA(value);
  };


  const handleToogle_pa = () => {
    if(disable===true){
      return false;
    }
    if (toogle_pa === false) {
      setToogle_pa(true);
    } else {
      setToogle_pa(false);
    }
  };


  const showP = { width: width, position: "absolute" };
  const hideP = { width: width, position: "absolute", display: "none" };

  var showPA = Object.keys(arrPA).map(function (tlist, j) {
    return (
      <div className="OddsSel2Bg" key={j} onClick={() => handlePA(tlist)}>
        <Link className="OddsSel2" to="/">
          {arrPA[tlist]}
        </Link>
        <br />
      </div>
    );
  });

  return (
    <div
      style={{ width: width, float: "left" }}
      className="divOddsSel"
      ref={myRef}
      onClick={handleToogle_pa}
    >
      <span>
        {arrPA[pa]}
      </span>
      <div
        className="OddsSel"
        id={toggleOddsSel}
        style={
          toogle_pa === false
            ? hideP
            : showP
        }
      >
        {showPA}
      </div>
    </div>
  );
}
