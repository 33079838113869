import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSearchParams , useNavigate } from "react-router-dom";

import { useLoadingContext } from './LoadingContext';
import { usePrefixContext } from "./PrefixContext";
import { console_log } from '../js/dev';
import api from './../js/api';
import apiBet from './../js/apiBet';

export const AuthContext = createContext();

function AuthContextProvider(props) {
    const [activeUser, setActiveUser] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [isNoMember, setIsNoMember] = useState(true);

    const prefixContext = usePrefixContext();
    const { prefix } = usePrefixContext();

    const navigate = useNavigate();
    
    //console.log(prefix);

    const { dismissLoading } = useLoadingContext();

    useEffect(() => {
        if (prefixContext.isCompleted && prefix.mtn===1) {
            updateApiAuthentication(0);
        }else if(prefix!==null && prefix.mtn===0){
            dismissLoading();
        }
    }, [prefixContext.isCompleted , prefix]);// eslint-disable-line react-hooks/exhaustive-deps

    const updateApiAuthentication = async (tx) => {
        
       // console.log(searchParams.get("token"));
        //presentLoading();
        if(searchParams.get("token")!==null && tx===0){
            var stk = searchParams.get("token");
            //setSearchParams("");
            //searchParams.delete("token");
            navigate("/" , {replace:true});
            logIn({"token":stk});
        }else{
            const token = localStorage.getItem(accessTokenStorageKey);
            if (token !== null) {
                console_log('add token to api header');
                api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                apiBet.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                if (activeUser === null) {
                    try {
                        var mdata = await fetchUserData();     
                        //const user = await fetchUserData();  
                        if(mdata.status===0){
                            alert(mdata.message);
                            delete api.defaults.headers.common["Authorization"];
                            delete apiBet.defaults.headers.common["Authorization"];
                            //setSearchParams("");
                            //setIsNoMember(true);
                            localStorage.removeItem(accessTokenStorageKey);
                            setIsCompleted(true);
                            dismissLoading();
                        }else{
                            setActiveUser(mdata);  
                            setIsNoMember(false); 
                            if (!isCompleted) {
                                //setSearchParams("");
                                setIsCompleted(true);
                                dismissLoading();
                            }
                        }
                                
                    } catch (error) {
                        console_log('remove token to api header');
                        logOut();
                    }
                }
            } else {
                console_log('remove token to api header');
                delete api.defaults.headers.common["Authorization"];
                delete apiBet.defaults.headers.common["Authorization"];
                if(searchParams.get("token")!==null){
                    var stk2 = searchParams.get("token");
                    //setSearchParams("");
                    //searchParams.delete("token");
                    navigate("/" , {replace:true});
                    logIn({"token":stk2});
                }else{
                    //setIsNoMember(true);
                    setIsCompleted(true);
                    dismissLoading();
                }
            }
        }
        
    }

    const fetchUserData = async () => {
        try {
            const res = await api.get(`member`);
            return res.data;
        } catch (error) {
            throw error;
        }
    }

    const saveAuthenticationToken = (a, u) => {
        // console_log(a, r, u)
        if (a) {
            localStorage.setItem(accessTokenStorageKey, a);
        }else{
            u = false;
        }

        if (u === true) {
            updateApiAuthentication(1);
        }else{
            //setSearchParams("");
            dismissLoading();
        }
    }

    const logOut = async () => {
        setActiveUser(null);
        setIsNoMember(true);
        localStorage.removeItem(accessTokenStorageKey);
        delete api.defaults.headers.common["Authorization"];
        delete apiBet.defaults.headers.common["Authorization"];
    }

    const logIn = async (data) => {
        try {
            const res = await api.post(`login`, data);
            //console.log(res);
            if(res.data.status===0){
                alert(res.data.message);
                setIsCompleted(true);
                dismissLoading();
                localStorage.removeItem(accessTokenStorageKey);
                delete api.defaults.headers.common["Authorization"];
                delete apiBet.defaults.headers.common["Authorization"];
                return false;
            }else{
                await saveAuthenticationToken(res.data.token, true);
                return res.data;
            }
        } catch (error) {
            throw error;
        } 
    }

    const setActUser = (data) => {
        setActiveUser(data);
    }

    const value = { activeUser, isCompleted, fetchUserData, updateApiAuthentication, saveAuthenticationToken, logOut, logIn, isNoMember , setActUser };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

function useAuthContext() {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error('usePrefix must be used within a AuthProvider')
    }
    return context
}

const accessTokenStorageKey = "a-token";

export { useAuthContext, accessTokenStorageKey }

export default AuthContextProvider;