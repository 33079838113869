import React from "react";
import { useTranslation } from 'react-i18next';

export default function TitleMuay(props) {
  const { liveTB } = props;
  const { t } = useTranslation();
  return (
    <div style={{ display: "table-row" }} className="GridHeader">
      <div
        style={{ display: "table-cell", width: "45px" }}
        className="table_th1_R"
      >
        {t("time")}
      </div>
      <div
        style={{
          display: "table-cell",
          width: "446px",
        }}
        className="table_th2_R"
      >
        {t("event")}
      </div>
      <div
        style={{ display: "table-cell", width: "65px" }}
        className="table_th1_R"
      >
        ML
      </div>
      <div
        style={{ display: "table-cell", width: "82px" }}
        className="table_th2_R"
      >
        {t("ft_hdp_title")}
      </div>
      <div
        style={{ display: "table-cell", width: "82px" }}
        className="table_th2_R"
      >
        {t("ft_ou_title")}
      </div>
      <div
        style={{ display: "table-cell", width: "55px" }}
        className="table_th2_R"
      >
        {t("oe_title")}
      </div>
    </div>
  );
}
