import React, { useEffect } from "react";
import ParList from "./ParList";

export default function SlipParX(props) {
  const { bData } = props;
  useEffect(() => {
    //window.print();
    return () => {
    };
  }, []);
  return (
    <>
      <div id="panParlay" />
      <table className="bold" cellSpacing={0} cellPadding={0} width="100%">
        <tbody>
          <tr>
            <td width={100}> S/No : </td>
            <td> {bData[0]} </td>
          </tr>
          <tr>
            <td width={100}> Branch Code : </td>
            <td> {bData[1]} </td>
          </tr>
          <tr>
            <td width={100}> วันที่ / เวลา : </td>
            <td>{bData[1]} </td>
          </tr>
          <tr>
            <td width={100}> ประเภทเกม : </td>
            <td> มิกซ์ พาร์เลย์ </td>
          </tr>
          <tr>
            <td colSpan={2}> &nbsp; </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <table className="bold" cellSpacing={0} cellPadding={3} align="Center" rules="all" id="g" style={{ borderWidth: 1, borderStyle: "solid", width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                {
                  bData[7].map(function (tlist, j) {
                    return <ParList key={j} parData={tlist} />
                  })
                }
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={2}> &nbsp; </td>
          </tr>
          <tr>
            <td width={100}> จำนวนเงินที่สามารถวางเดิมพันได้ : </td>
            <td> {formatMoney(bData[2])} </td>
          </tr>
          <tr>
            <td width={100}> อัตราต่อรอง : </td>
            <td> {formatMoney(bData[6] , 3)} </td>
          </tr>
          <tr>
            <td width={100}> จ่ายเงินประมาณ : </td>
            <td> {formatMoney(bData[8] , 2)} </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
function hpdConv(val){
    var $pos1 = val.indexOf("-");
    if(val.length===1 || $pos1<0){
      return val;
    }else{
      var lv = val.split("-");
      //console.log(lv);
      var new_val = parseFloat(lv[0])+((parseFloat(lv[1])-parseFloat(lv[0]))/2);
      return formatMoney(new_val , 2);
    }
  }