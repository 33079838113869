import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { usePrefixContext } from '../context/PrefixContext';
import { useTranslation } from 'react-i18next';

import BetBox from './BetBox';
import BetBoxPar from './BetBoxPar';
import BetListMini from './BetListMini';

import img_divider from "../Images/divider.png";
import img_icoLive from "../Images/icoLive.gif";

import img_soccer from "../Images/icon/soccer.png";
import img_baseball from "../Images/icon/baseball.png";
import img_basketball from "../Images/icon/basketball.png";
import img_handball from "../Images/icon/handball.png";
import img_ice from "../Images/icon/ice.png";
import img_muay_thai from "../Images/icon/muay_thai.png";
import img_snook from "../Images/icon/snook.png";
import img_tennis from "../Images/icon/tennis.png";
import img_volleyball from "../Images/icon/volleyball.png";
import img_motor from "../Images/icon/motogp.png";
import img_badminton from "../Images/icon/badminton.png";
import img_boxing_mma from "../Images/icon/boxing.png";
import img_golf from "../Images/icon/golf.png";
import img_fighting_cock from "../Images/icon/fighting_cock.png";
import img_bulls from "../Images/icon/bulls.png";
import img_running_cow from "../Images/icon/running_cow.png";
import img_boat_racing from "../Images/icon/boat_racing.png";
import img_water_polo from "../Images/icon/water_polo.png";
import img_futsal from "../Images/icon/futsal.png";
import img_table_tennis from "../Images/icon/table_tennis.png";

var img_icon_list = {};
img_icon_list["soccer"] = img_soccer;
img_icon_list["baseball"] = img_baseball;
img_icon_list["basketball"] = img_basketball;
img_icon_list["handball"] = img_handball;
img_icon_list["ice_hockey"] = img_ice;
img_icon_list["muay_thai"] = img_muay_thai;
img_icon_list["snook"] = img_snook;
img_icon_list["tennis"] = img_tennis;
img_icon_list["volleyball"] = img_volleyball;
img_icon_list["motor"] = img_motor;
img_icon_list["badminton"] = img_badminton;
img_icon_list["boxing_mma"] = img_boxing_mma;
img_icon_list["golf"] = img_golf;
img_icon_list["fighting_cock"] = img_fighting_cock;
img_icon_list["bulls"] = img_bulls;
img_icon_list["running_cow"] = img_running_cow;
img_icon_list["boat_racing"] = img_boat_racing;
img_icon_list["water_polo"] = img_water_polo;
img_icon_list["futsal"] = img_futsal;
img_icon_list["table_tennis"] = img_table_tennis;

const RemoveDuplicates = (array, key) => {
  return array.reduce((arr, item) => {
    const removed = arr.filter((i) => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};

export default function Left(props) {
  const { prefix , getCurrentLanguage } = usePrefixContext();
  const { toogle_left, SportsSelect , onSportsSelect , onSportsPriceSelect , toogle_live , onToogle_live , sportData, sportPrice , onPageMain , busketBet , setBusketBet , busketBetPar , setBusketBetPar } = props;
  const [SportsMenu, setSportsMenu] = useState([]);

  const [toogle_menu, setToogle_menu] = useState(true);

  const [thisSportsSelect, setthisSportsSelect] = useState(SportsSelect);

  const [DataBusket, setDataBusket] = useState([]);
  const [PriceBusket, setPriceBusket] = useState({});

  const [StepBusket, setStepBusket] = useState([]);
  const [StepOdds, setStepOdds] = useState(0);
  const [StepBlinkOdds, setStepBlinkOdds] = useState(0);

  const [ViewBet, setViewBet] = useState(0);

  const { t } = useTranslation();

  //console.log(busketBet);

  useEffect(() => {

    var zbData = {};
    var zbPrice = {};
    var zbPriceStep = {};
    if (sportData !== "") {
      zbData = JSON.parse(sportData);
    }
    if (sportPrice !== "") {
      var zbPricex = JSON.parse(sportPrice);
      zbPrice = zbPricex[0];
      zbPriceStep = zbPricex[1];
    }

    var listSP = [];
    var listNSP = {};
    listNSP["hdpou"] = {};
    listNSP["oe"] = {};
    listNSP["1x2"] = {};
    listNSP["step"] = {};

    var listLiveNSP = {};
    listLiveNSP["hdpou"] = {};
    listLiveNSP["oe"] = {};
    listLiveNSP["1x2"] = {};
    listLiveNSP["step"] = {};

    for (var iy in zbData) {
      var spx = zbData[iy];
      if(spx[10] !== 4){
        var asc_sp;
        if(spx[5]===6){
          asc_sp = 0;
        }else if(spx[5]===7){
          asc_sp = 1;
        }else if(spx[5]===1){
          asc_sp = 2;
        }else{
          asc_sp = spx[5];
        }
        listSP.push({
          sp_key: spx[5],
          spASC: asc_sp,
        });

        if(listNSP["hdpou"][spx[5]]===undefined){
          listNSP["hdpou"][spx[5]] = 0;
        }
        if(listLiveNSP["hdpou"][spx[5]]===undefined){
          listLiveNSP["hdpou"][spx[5]] = 0;
        }

        if(listNSP["oe"][spx[5]]===undefined){
          listNSP["oe"][spx[5]] = 0;
        }
        if(listLiveNSP["oe"][spx[5]]===undefined){
          listLiveNSP["oe"][spx[5]] = 0;
        }

        if(listNSP["1x2"][spx[5]]===undefined){
          listNSP["1x2"][spx[5]] = 0;
        }
        if(listLiveNSP["1x2"][spx[5]]===undefined){
          listLiveNSP["1x2"][spx[5]] = 0;
        }

        if(listNSP["step"][spx[5]]===undefined){
          listNSP["step"][spx[5]] = 0;
        }
        if(listLiveNSP["step"][spx[5]]===undefined){
          listLiveNSP["step"][spx[5]] = 0;
        }

        listNSP["hdpou"][spx[5]] = parseInt(listNSP["hdpou"][spx[5]])+1;
        if(spx[10] !== 0){
          listLiveNSP["hdpou"][spx[5]] = parseInt(listLiveNSP["hdpou"][spx[5]])+1;
        }

        if(spx[4] === 2){
          listNSP["step"][spx[5]] = parseInt(listNSP["step"][spx[5]])+1;
          if(spx[10] !== 0){
            listLiveNSP["step"][spx[5]] = parseInt(listLiveNSP["step"][spx[5]])+1;
          }
        }

        var bidd = spx[0];
        for(var idp in zbPrice[bidd]){
          var spp = zbPrice[bidd][idp];
          if (spp[19] !== 0 && spp[20] !== 0 && spp[4] === 6) {
            listNSP["oe"][spx[5]] = parseInt(listNSP["oe"][spx[5]])+1;
            if(spx[10] !== 0){
              listLiveNSP["oe"][spx[5]] = parseInt(listLiveNSP["oe"][spx[5]])+1;
            }
          }

          if((spp[15]>0.00 && spp[16]>0.00 && spp[17]>0.00) || (spp[31]>0.00 && spp[32]>0.00 && spp[33]>0.00)){
            listNSP["1x2"][spx[5]] = parseInt(listNSP["1x2"][spx[5]])+1;
            if(spx[10] !== 0){
              listLiveNSP["1x2"][spx[5]] = parseInt(listLiveNSP["1x2"][spx[5]])+1;
            }
          }
        }

        
      }

    }


    listSP = RemoveDuplicates(listSP, "sp_key");
    listSP.sort(dynamicSortMultiple("spASC Asc"));
    var sslis = listSP.length;
    for(var lspe in prefix.sport_list){
      if(lspe!=="1" && lspe!=="6" && lspe!=="7"){
        sslis = sslis+1;
        listSP.push({"spASC":sslis , "sp_key":parseInt(lspe)});
      }
    }

    
    var dSportsMenu = [];
    for(var lsp in listSP){
      var listSPD = listSP[lsp];
      var listMData = {};
      listMData["sp_key"] = listSPD["sp_key"];
      listMData["dmenu_today"] = [];
      listMData["dmenu_live"] = [];

      var sum_all_hdpou = listNSP["hdpou"][listSPD["sp_key"]];
      var sum_live_hdpou = listLiveNSP["hdpou"][listSPD["sp_key"]];

      var sum_all_oe = listNSP["oe"][listSPD["sp_key"]];
      var sum_live_oe = listLiveNSP["oe"][listSPD["sp_key"]];

      var sum_all_1x2 = listNSP["1x2"][listSPD["sp_key"]];
      var sum_live_1x2 = listLiveNSP["1x2"][listSPD["sp_key"]];

      var sum_all_step = listNSP["step"][listSPD["sp_key"]];
      var sum_live_step = listLiveNSP["step"][listSPD["sp_key"]];

      if(sum_all_hdpou>0 || listSPD["sp_key"]===6){
        listMData["dmenu_today"].push({ menu_key: 1, menu_num: sum_all_hdpou });
      }
      if(sum_live_hdpou>0){
        listMData["dmenu_live"].push({ menu_key: 1, menu_num: sum_live_hdpou });
      }

      if(sum_all_oe>0 || listSPD["sp_key"]===6){
        listMData["dmenu_today"].push({ menu_key: 2, menu_num: sum_all_oe });
      }
      if(sum_live_oe>0){
        listMData["dmenu_live"].push({ menu_key: 2, menu_num: sum_live_oe });
      }

      if(sum_all_1x2>0 || listSPD["sp_key"]===6){
        listMData["dmenu_today"].push({ menu_key: 3, menu_num: sum_all_1x2 });
      }
      if(sum_live_1x2>0){
        listMData["dmenu_live"].push({ menu_key: 3, menu_num: sum_live_1x2 });
      }

      if(listSPD["sp_key"]===1 || listSPD["sp_key"]===6 || listSPD["sp_key"]===7){
        listMData["dmenu_today"].push({ menu_key: 4, menu_num: sum_all_step });
      }
      if(sum_live_step>0){
        listMData["dmenu_live"].push({ menu_key: 4, menu_num: sum_live_step });
      }

      dSportsMenu.push(listMData);
    }
    //console.log(dSportsMenu);
    setSportsMenu(dSportsMenu);

    if(Object.keys(busketBet).length > 0){

      if(zbData[busketBet.BetMatch]===undefined){
        setBusketBet({});
        setDataBusket([]);
        setPriceBusket({});
        return false;
      }
    
      var dBall = zbData[busketBet.BetMatch];
      if(dBall===undefined){
        setBusketBet({});
        setDataBusket([]);
        setPriceBusket({});
        return false;
      }

      setDataBusket(dBall);

      var b_name1 = {};
      b_name1["CN"] = (dBall[32]==="" ? dBall[26] : dBall[32]);
      b_name1["EN"] = dBall[26];
      b_name1["ES"] = dBall[26];
      b_name1["FR"] = dBall[26];
      b_name1["IN"] = dBall[26];
      b_name1["JP"] = dBall[26];
      b_name1["KH"] = dBall[26];
      b_name1["KR"] = dBall[26];
      b_name1["LA"] = dBall[26];
      b_name1["MM"] = dBall[26];
      b_name1["PT"] = dBall[26];
      b_name1["TH"] = (dBall[29]==="" ? dBall[26] : dBall[29]);
      b_name1["YN"] = dBall[26];

      var b_name2 = {};
      b_name2["CN"] = (dBall[33]==="" ? dBall[27] : dBall[33]);
      b_name2["EN"] = dBall[27];
      b_name2["ES"] = dBall[27];
      b_name2["FR"] = dBall[27];
      b_name2["IN"] = dBall[27];
      b_name2["JP"] = dBall[27];
      b_name2["KH"] = dBall[27];
      b_name2["KR"] = dBall[27];
      b_name2["LA"] = dBall[27];
      b_name2["MM"] = dBall[27];
      b_name2["PT"] = dBall[27];
      b_name2["TH"] = (dBall[30]==="" ? dBall[27] : dBall[30]);
      b_name2["YN"] = dBall[27];

      if(zbPrice[busketBet.BetMatch]===undefined){
        setBusketBet({});
        setDataBusket([]);
        setPriceBusket({});
        return false;
      }
      var addPrice = busketBet.BetAdd-1;
      if(zbPrice[busketBet.BetMatch][addPrice]===undefined){
        setBusketBet({});
        setDataBusket([]);
        setPriceBusket({});
        return false;
      }
      var dPrice = zbPrice[busketBet.BetMatch][addPrice];

      if(busketBet.id_type_num===1 && busketBet.BetHF===2){
        if(dPrice[5]!==busketBet.id_type){
          setBusketBet({});
          setDataBusket([]);
          setPriceBusket({});
        }else{
          var price_data = {};
          if(busketBet.BetType==="home"){
            price_data["BetOdds"] = dPrice[8];
            price_data["BetPlay"] = b_name1[getCurrentLanguage()];
            price_data["BetPlayClass"] = ((dPrice[6]===1) ? "txtBetRedBold" : "txtBetBlueBold");
          }else{
            price_data["BetOdds"] = dPrice[9];
            price_data["BetPlay"] = b_name2[getCurrentLanguage()];
            price_data["BetPlayClass"] = ((dPrice[6]===2) ? "txtBetRedBold" : "txtBetBlueBold");
          }

          price_data["IsHomeGive"] = ((dPrice[6]===1) ? true : false);
          price_data["BetHdp"] = hpdConv(dPrice[7]);
          price_data["BetHdpX"] = dPrice[7];
          price_data["title_bet"] = t(prefix.sport_list[dPrice[4]])+" / "+t("Handicap");
          price_data["type_bet"] = t("HDP"); 
          price_data["id_type"] = busketBet.id_type; 
          price_data["BetType"] = busketBet.BetType; 

          setPriceBusket(price_data);
        }
      }else if(busketBet.id_type_num===2 && busketBet.BetHF===2){
        if(dPrice[10]!==busketBet.id_type){
          setBusketBet({});
          setDataBusket([]);
          setPriceBusket({});
        }else{
          var price_data = {};
          if(busketBet.BetType==="home"){
            price_data["BetOdds"] = dPrice[12];
            price_data["BetPlay"] = t("over").toUpperCase();
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetRedBold";
          }else{
            price_data["BetOdds"] = dPrice[13];
            price_data["BetPlay"] = t("under").toUpperCase();
            price_data["IsHomeGive"] = true;
            price_data["BetPlayClass"] = "txtBetBold";
          }
        
          price_data["BetHdp"] = hpdConv(dPrice[11]);
          price_data["BetHdpX"] = dPrice[11];
          price_data["title_bet"] = t(prefix.sport_list[dPrice[4]])+" / "+t("Over_Under");
          price_data["type_bet"] = ""; 
          price_data["id_type"] = busketBet.id_type; 
          price_data["BetType"] = busketBet.BetType; 

          setPriceBusket(price_data);
        }
      }else if(busketBet.id_type_num===3 && busketBet.BetHF===2){
        if(dPrice[14]!==busketBet.id_type){
          setBusketBet({});
          setDataBusket([]);
          setPriceBusket({});
        }else{
          var price_data = {};
          if(busketBet.BetType==="home"){
            price_data["BetOdds"] = dPrice[15];
            price_data["BetPlay"] = b_name1[getCurrentLanguage()]+" ("+t("WIN")+")";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetBlueBold";
          }else if(busketBet.BetType==="away"){
            price_data["BetOdds"] = dPrice[17];
            price_data["BetPlay"] = b_name2[getCurrentLanguage()]+" ("+t("WIN")+")";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetBlueBold";
          }else{
            price_data["BetOdds"] = dPrice[16];
            price_data["BetPlay"] = b_name1[getCurrentLanguage()]+" ("+t("DRAWBet")+")";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetBlueBold";
          }
        
          price_data["BetHdp"] = "";
          price_data["BetHdpX"] = "";
          price_data["title_bet"] = t(prefix.sport_list[dPrice[4]])+" / 1X2";
          price_data["type_bet"] = ""; 
          price_data["id_type"] = busketBet.id_type; 
          price_data["BetType"] = busketBet.BetType; 

          setPriceBusket(price_data);
        }
      }else if(busketBet.id_type_num===4 && busketBet.BetHF===2){
        if(dPrice[18]!==busketBet.id_type){
          setBusketBet({});
          setDataBusket([]);
          setPriceBusket({});
        }else{
          var price_data = {};
          if(busketBet.BetType==="odd"){
            price_data["BetOdds"] = dPrice[19];
            price_data["BetPlay"] = t("Odd").toUpperCase();
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetRedBold";
          }else{
            price_data["BetOdds"] = dPrice[20];
            price_data["BetPlay"] = t("Even").toUpperCase();
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetBold";
          }
        
          price_data["BetHdp"] = "";
          price_data["BetHdpX"] = "";
          price_data["title_bet"] = t(prefix.sport_list[dPrice[4]])+" / "+t("Odd_Even");
          price_data["type_bet"] = ""; 
          price_data["id_type"] = busketBet.id_type; 
          price_data["BetType"] = busketBet.BetType; 

          setPriceBusket(price_data);
        }
      }else if(busketBet.id_type_num===5 && busketBet.BetHF===1){
        if(dPrice[21]!==busketBet.id_type){
          setBusketBet({});
          setDataBusket([]);
          setPriceBusket({});
        } else{
          var price_data = {};
          if(busketBet.BetType==="home"){
            price_data["BetOdds"] = dPrice[24];
            price_data["BetPlay"] = b_name1[getCurrentLanguage()];
            price_data["BetPlayClass"] = ((dPrice[22]===1) ? "txtBetRedBold" : "txtBetBlueBold");
          }else{
            price_data["BetOdds"] = dPrice[25];
            price_data["BetPlay"] = b_name2[getCurrentLanguage()];
            price_data["BetPlayClass"] = ((dPrice[22]===2) ? "txtBetRedBold" : "txtBetBlueBold");
          }
        
          price_data["IsHomeGive"] = ((dPrice[22]===1) ? true : false);
          price_data["BetHdp"] = hpdConv(dPrice[23]);
          price_data["BetHdpX"] = dPrice[23];
          price_data["title_bet"] = t(prefix.sport_list[dPrice[4]])+" / แต้มต่อ";
          price_data["type_bet"] = "แฮนดิแคป"; 
          price_data["id_type"] = busketBet.id_type; 
          price_data["BetType"] = busketBet.BetType; 

          setPriceBusket(price_data);
        }
      }else if(busketBet.id_type_num===6 && busketBet.BetHF===1){
        if(dPrice[26]!==busketBet.id_type){
          setBusketBet({});
          setDataBusket([]);
          setPriceBusket({});
        }else{
          var price_data = {};
          if(busketBet.BetType==="home"){
            price_data["BetOdds"] = dPrice[28];
            price_data["BetPlay"] = "สูง";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetRedBold";
          }else{
            price_data["BetOdds"] = dPrice[29];
            price_data["BetPlay"] = "ต่ำ";
            price_data["IsHomeGive"] = true;
            price_data["BetPlayClass"] = "txtBetBold";
          }
        
          price_data["BetHdp"] = hpdConv(dPrice[27]);
          price_data["BetHdpX"] = dPrice[27];
          price_data["title_bet"] = t(prefix.sport_list[dPrice[4]])+" / สูง/ต่ำ";
          price_data["type_bet"] = "";
          price_data["id_type"] = busketBet.id_type; 
          price_data["BetType"] = busketBet.BetType; 

          setPriceBusket(price_data);
        }
      }else if(busketBet.id_type_num===7 && busketBet.BetHF===1){
        if(dPrice[30]!==busketBet.id_type){
          setBusketBet({});
          setDataBusket([]);
          setPriceBusket({});
        }else{
          var price_data = {};
          if(busketBet.BetType==="home"){
            price_data["BetOdds"] = dPrice[31];
            price_data["BetPlay"] = b_name1[getCurrentLanguage()]+" (ชนะ)";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetBlueBold";
          }else if(busketBet.BetType==="away"){
            price_data["BetOdds"] = dPrice[33];
            price_data["BetPlay"] = b_name2[getCurrentLanguage()]+" (ชนะ)";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetBlueBold";
          }else{
            price_data["BetOdds"] = dPrice[32];
            price_data["BetPlay"] = b_name1[getCurrentLanguage()]+" (เสมอ)";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetBlueBold";
          }
        
          price_data["BetHdp"] = "";
          price_data["BetHdpX"] = "";
          price_data["title_bet"] = t(prefix.sport_list[dPrice[4]])+" / 1X2";
          price_data["type_bet"] = ""; 
          price_data["id_type"] = busketBet.id_type; 
          price_data["BetType"] = busketBet.BetType; 

          setPriceBusket(price_data);
        }
      }else if(busketBet.id_type_num===8 && busketBet.BetHF===1){
        if(dPrice[34]!==busketBet.id_type){
          setBusketBet({});
          setDataBusket([]);
          setPriceBusket({});
        }else{
          var price_data = {};
          if(busketBet.BetType==="odd"){
            price_data["BetOdds"] = dPrice[35];
            price_data["BetPlay"] = "คี่";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetRedBold";
          }else{
            price_data["BetOdds"] = dPrice[36];
            price_data["BetPlay"] = "คู่";
            price_data["IsHomeGive"] = false;
            price_data["BetPlayClass"] = "txtBetBold";
          }
        
          price_data["BetHdp"] = "";
          price_data["BetHdpX"] = "";
          price_data["title_bet"] = t(prefix.sport_list[dPrice[4]])+" / คี่/คู่";
          price_data["type_bet"] = ""; 
          price_data["id_type"] = busketBet.id_type; 
          price_data["BetType"] = busketBet.BetType; 

          setPriceBusket(price_data);
        }
      }else{
        setBusketBet({});
        setDataBusket([]);
        setPriceBusket({});
      }
    }

    if(busketBetPar.length > 0){
      //console.log(busketBetPar);
      var sum_odd = 1;
      var parData = [];
      var blink_sum = 0;
      for(var pli in busketBetPar){
        var parList = busketBetPar[pli];
        if(parList["betStatus"]===2){
          sum_odd = sum_odd*parseFloat(parList["BetOdds"]);
          parData.push(parList);
          continue;
        }
        var oldOdds = parList["BetOdds"];
        if(zbData[parList.BetMatch]===undefined){
          parList["betStatus"] = 2;
        }else{
          var dBall = zbData[parList.BetMatch];
          if(dBall===undefined){
            parList["betStatus"] = 2;
          }else{
            if(dBall[10]===4){
              parList["betStatus"] = 2;
            }else{
              var b_name1 = {};
              b_name1["CN"] = (dBall[32]==="" ? dBall[26] : dBall[32]);
              b_name1["EN"] = dBall[26];
              b_name1["ES"] = dBall[26];
              b_name1["FR"] = dBall[26];
              b_name1["IN"] = dBall[26];
              b_name1["JP"] = dBall[26];
              b_name1["KH"] = dBall[26];
              b_name1["KR"] = dBall[26];
              b_name1["LA"] = dBall[26];
              b_name1["MM"] = dBall[26];
              b_name1["PT"] = dBall[26];
              b_name1["TH"] = (dBall[29]==="" ? dBall[26] : dBall[29]);
              b_name1["YN"] = dBall[26];

              var b_name2 = {};
              b_name2["CN"] = (dBall[33]==="" ? dBall[27] : dBall[33]);
              b_name2["EN"] = dBall[27];
              b_name2["ES"] = dBall[27];
              b_name2["FR"] = dBall[27];
              b_name2["IN"] = dBall[27];
              b_name2["JP"] = dBall[27];
              b_name2["KH"] = dBall[27];
              b_name2["KR"] = dBall[27];
              b_name2["LA"] = dBall[27];
              b_name2["MM"] = dBall[27];
              b_name2["PT"] = dBall[27];
              b_name2["TH"] = (dBall[30]==="" ? dBall[27] : dBall[30]);
              b_name2["YN"] = dBall[27];

              parList["Home"] = b_name1[getCurrentLanguage()];
              parList["Away"] = b_name2[getCurrentLanguage()];
              parList["Score"] = dBall[21];
              parList["Zone"] = dBall[5];
              parList["IsRun"] = dBall[10] > 0 ? true : false;

              if(zbPriceStep[parList.BetMatch]===undefined){
                parList["betStatus"] = 0;
              }else{
                var addPrice = parList.BetAdd-1;
                if(zbPriceStep[parList.BetMatch][addPrice]===undefined){
                  parList["betStatus"] = 0;
                }else{
                  var dPrice = zbPriceStep[parList.BetMatch][addPrice];
                  parList["betStatus"] = 1;
                  if(parList.id_type_num===1 && parList.BetHF===2){
                    if(dPrice[5]!==parList.id_type){
                      parList["betStatus"] = 0;
                    }else{
                      if(parList.BetType==="home"){
                        parList["BetOdds"] = formatMoney(dPrice[8], 2);
                      }else{
                        parList["BetOdds"] = formatMoney(dPrice[9], 2);
                      }
                      parList["IsHomeGive"] = ((dPrice[6]===1) ? true : false);
                      parList["BetHdp"] = hpdConv(dPrice[7]);
                    }
                  }else if(parList.id_type_num===2 && parList.BetHF===2){
                    if(dPrice[10]!==parList.id_type){
                      parList["betStatus"] = 0;
                    }else{
                      if(parList.BetType==="home"){
                        parList["BetOdds"] = formatMoney(dPrice[12], 2);
                        parList["IsHomeGive"] = false;
                      }else{
                        parList["BetOdds"] = formatMoney(dPrice[13], 2);
                        parList["IsHomeGive"] = true;
                      }
                      parList["BetHdp"] = dPrice[11];
                    }
                  }else if(parList.id_type_num===3 && parList.BetHF===2){
                    if(dPrice[14]!==parList.id_type){
                      parList["betStatus"] = 0;
                    }else{
                      if(parList.BetType==="home"){
                        parList["BetOdds"] = formatMoney(dPrice[15], 2);
                      }else if(parList.BetType==="away"){
                        parList["BetOdds"] = formatMoney(dPrice[17], 2);
                      }else{
                        parList["BetOdds"] = formatMoney(dPrice[16], 2);
                      }
      
                      parList["IsHomeGive"] = false;
                      parList["BetHdp"] = "";
                    }
                  }else if(parList.id_type_num===4 && parList.BetHF===2){
                    if(dPrice[18]!==parList.id_type){
                      parList["betStatus"] = 0;
                    }else{
                      if(parList.BetType==="odd"){
                        parList["BetOdds"] = formatMoney(dPrice[19], 2);
                      }else{
                        parList["BetOdds"] = formatMoney(dPrice[20], 2);
                      }
                    
                      parList["IsHomeGive"] = false;
                      parList["BetHdp"] = "";
                    }
                  }else if(parList.id_type_num===5 && parList.BetHF===1){
                    if(dPrice[21]!==parList.id_type){
                      parList["betStatus"] = 0;
                    } else{
                      if(parList.BetType==="home"){
                        parList["BetOdds"] = formatMoney(dPrice[24], 2);
                      }else{
                        parList["BetOdds"] = formatMoney(dPrice[25], 2);
                      }
                    
                      parList["IsHomeGive"] = ((dPrice[22]===1) ? true : false);
                      parList["BetHdp"] = hpdConv(dPrice[23]);
                    }
                  }else if(parList.id_type_num===6 && parList.BetHF===1){
                    if(dPrice[26]!==parList.id_type){
                      parList["betStatus"] = 0;
                    }else{
                      if(parList.BetType==="home"){
                        parList["BetOdds"] = formatMoney(dPrice[28], 2);
                        parList["IsHomeGive"] = false;
                      }else{
                        parList["BetOdds"] = formatMoney(dPrice[29], 2);
                        parList["IsHomeGive"] = true;
                      }
      
                      
                      parList["BetHdp"] = dPrice[27];
                    }
                  }else if(parList.id_type_num===7 && parList.BetHF===1){
                    if(dPrice[30]!==parList.id_type){
                      parList["betStatus"] = 0;
                    }else{
                      if(parList.BetType==="home"){
                        parList["BetOdds"] = formatMoney(dPrice[31], 2);
                      }else if(parList.BetType==="away"){
                        parList["BetOdds"] = formatMoney(dPrice[33], 2);
                      }else{
                        parList["BetOdds"] = formatMoney(dPrice[32], 2);
                      }
      
                      parList["IsHomeGive"] = false;
                      parList["BetHdp"] = "";
                    }
                  }else if(parList.id_type_num===8 && parList.BetHF===1){
                    if(dPrice[34]!==parList.id_type){
                      parList["betStatus"] = 0;
                    }else{
                      if(parList.BetType==="odd"){
                        parList["BetOdds"] = formatMoney(dPrice[35], 2);
                      }else{
                        parList["BetOdds"] = formatMoney(dPrice[36], 2);
                      }
                    
                      parList["IsHomeGive"] = false;
                      parList["BetHdp"] = "";
                    }
                  }else{
                    parList["betStatus"] = 0;
                  }

                  if(oldOdds!==parList["BetOdds"]){
                    parList["betBlink"] = new Date();
                    blink_sum = 1;
                  }
                }
                
              }
            }
          }
        }
        sum_odd = sum_odd*parseFloat(parList["BetOdds"]);
        parData.push(parList);
      }
      setStepBusket(parData);
      setStepOdds(sum_odd);
      if(blink_sum===1){
        setStepBlinkOdds(new Date());
      }
    }

    return () => {};
  }, [sportData , sportPrice , busketBet , busketBetPar]);

  


  const handleClickOpenMenu = (value) => {
    //console.log(value)
    setToogle_menu(value)
  };

  const handleClickOpenLive = (value) => {
    //console.log(value)
    if(value===true && value!==toogle_live){
      var hlivef = 0;
      var plivef = 1;
      for(var niLive in SportsMenu){
        var niDLive = SportsMenu[niLive];
        //console.log(niDLive["dmenu_live"].length);
        if(hlivef===0 && niDLive["dmenu_live"].length>0){
          hlivef = niDLive["sp_key"];
          plivef = niDLive["dmenu_live"][0]["menu_key"];
        }

        if(niDLive["sp_key"]===thisSportsSelect){
          if(niDLive["dmenu_live"].length>0){
            hlivef = niDLive["sp_key"];
            plivef = niDLive["dmenu_live"][0]["menu_key"];
          }
        }
      }
      if(hlivef!==thisSportsSelect){
        setthisSportsSelect(hlivef);
        onSportsSelect(hlivef);
      }
      onSportsPriceSelect(plivef);
    }
    onToogle_live(value)
  };

  const handleSportsSelect = (value) => {
    if(value===thisSportsSelect){
      setthisSportsSelect(0);
    }else{
      setthisSportsSelect(value);
    }

    if(value!==1 && value!==6 && value!==7){
      onPageMain("AccDisable")
    }
  };

  const handleSportsShowSelect = (value) => {
    onSportsSelect(thisSportsSelect);
    onSportsPriceSelect(value);
  };

  const closeBet = () => {
    setBusketBet({});
    setDataBusket([]);
    setPriceBusket({});
    setStepBusket([]);
    setBusketBetPar([]);
  };

  const removeBet = (value) => {
    var newList = [];
    for(var pli in busketBetPar){
      var parList = busketBetPar[pli];
      if(parList.BetMatch===value.BetMatch){
        continue;
      }else{
        newList.push(parList);
      }
    }

    if(newList.length <= 0){
      setBusketBet({});
      setDataBusket([]);
      setPriceBusket({});
      setStepBusket([]);
      setBusketBetPar([]);
    }else{
      setBusketBetPar(newList);
    }
  };

  //console.log(SportsSelect);
  //console.log(SportsMenu);
  var sum_all_live = 0;
  for(var niLive in SportsMenu){
    var niDLive = SportsMenu[niLive];
    for(const mx in niDLive["dmenu_live"]){
      const mxd = niDLive["dmenu_live"][mx];

      sum_all_live = sum_all_live+mxd["menu_num"];
    }
  }
  
  return (
    <div
      className={
        "leftstyle" + (toogle_left === true ? " leftstyle_toogle_left" : "")
      }
    >
      <div className="blockLeft">
        <div className="leftHx"></div>
        <div className="leftHx"></div>
        <div className="PanelMyFavBg">
          <div
            style={{
              cursor: "pointer",
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <Link
              className="ellipsis PanelSubHdrLbl"
              to="#"
              style={{ maxWidth: "174px" }}
              onClick={() => onPageMain("FavPage")}
            >
              {t("my_favorites")}
            </Link>
          </div>
        </div>
        <div className="leftHx"></div>
        {(busketBetPar.length > 0 ? <BetBoxPar busketBetPar={busketBetPar} stepBusket={StepBusket} stepOdds={StepOdds} setBusketBetPar={closeBet} stepBlinkOdds={StepBlinkOdds} removeBet={removeBet} setViewBet={setViewBet} setToogle_menu={setToogle_menu} /> : null)}
        {(Object.keys(busketBet).length > 0 ? <BetBox busketBet={busketBet} setBusketBet={closeBet} sportData={DataBusket} sportPrice={PriceBusket} setViewBet={setViewBet} setToogle_menu={setToogle_menu} /> : null)}
        <div className="trSportsMenu" style={{display:(Object.keys(busketBet).length === 0 && busketBetPar.length === 0 ? "" : "none")}}>


          <div className="PanelHideMenuBg" style={(toogle_menu===false ? { display: "none" }:{})} onClick={() => handleClickOpenMenu(false)}>
            <Link
              className="ellipsis PanelSubHdrLbl"
              to="#"
              style={{ maxWidth: "144px" }}
            >
              {t("hide_sports_menu")}
            </Link>
          </div>
          <div className="PanelShowMenuBg" style={(toogle_menu===true ? { display: "none" }:{})} onClick={() => handleClickOpenMenu(true)}>
            <Link
              className="ellipsis PanelSubHdrLbl"
              to="#"
              style={{ maxWidth: "144px" }}
            >
              {t("show_sports_menu")}
            </Link>
          </div>


          <div className="trToggleOtMenu">
            <div className="OtBg">
              <div className={(toogle_live===false ? "HeadingOt2" : "HeadingOt1")} onClick={() => handleClickOpenLive(false)}>{t("today")}</div>
              <div>
                <img alt="" src={img_divider} width="2" height="24" />
              </div>
              <div className={(toogle_live===true ? "HeadingOt2" : "HeadingOt1")} onClick={() => handleClickOpenLive(true)}>
              {t("live")}{" "}
                <label id="lblRunCnt" style={{ display: "inline" }}>
                  {sum_all_live}
                </label>
              </div>
            </div>
          </div>
          <div className="PanelItemGrid">
            <div className="SportsMenuBg" style={(toogle_menu===false ? { display: "none" }:{})}>
              {SportsMenu.map(function (tlist, j) {

                var key_show_data = (toogle_live===true ? "dmenu_live" : "dmenu_today");

                var sum_today = 0;
                var sum_live = 0;
                for(const mx in tlist["dmenu_today"]){
                  const mxd = tlist["dmenu_today"][mx];

                  sum_today = sum_today+mxd["menu_num"];
                }
                for(const mx in tlist["dmenu_live"]){
                  const mxd = tlist["dmenu_live"][mx];

                  sum_live = sum_live+mxd["menu_num"];
                }

                if(key_show_data==="dmenu_live"){
                  //console.log(sum_live)
                  if(sum_live<=0){
                    return false;
                  }
                }
                

                return (
                  <div key={j}>
                    <div className="SpMenuHeaderBg" onClick={() => handleSportsSelect(tlist.sp_key)}>
                      <div style={{ display: "table-row" }}>
                        <div style={{ width: "25px", textAlign: "center" }}>
                          <img
                            id="imgSMenuSoccer"
                            alt=""
                            src={img_icon_list[prefix.sport_list[tlist.sp_key]]}
                            align="absmiddle"
                            width={(tlist.sp_key===2 || tlist.sp_key===3 || tlist.sp_key===4 || tlist.sp_key===5 || tlist.sp_key===13 || tlist.sp_key===16 || tlist.sp_key===18 ? 14 : 18)}
                          />
                        </div>
                        <div className="SpMenuHeader" style={{ width: "92px" }}>
                          <Link
                            className="SpMenuHeader ellipsis"
                            to="#"
                            style={{ maxWidth: "90px" }}
                          >
                            {t(prefix.sport_list[tlist.sp_key])}
                          </Link>
                        </div>
                        <div style={{ width: "30px", textAlign: "right" }}>
                          {sum_live > 0 ? (
                            <img alt="" src={img_icoLive} align="absmiddle" />
                          ) : (
                            "&nbsp;"
                          )}
                        </div>
                        <div
                          style={{ width: "20px", textAlign: "right" }}
                          className="SpMenuHeader SpCnt"
                        >
                          {(toogle_live===true ? sum_live : sum_today)}
                        </div>
                      </div>
                    </div>
                    <div
                      className="SpMenuItem"
                      style={
                        tlist.sp_key === thisSportsSelect ? {} : { display: "none" }
                      }
                    >
                      {tlist[key_show_data].map(function (mlist, m) {
                        //console.log(tlist);
                        return (
                          <div key={m} style={{ display: "table-row" , cursor: "pointer" }} onClick={() => handleSportsShowSelect(mlist.menu_key)}>
                            <div style={{ width: "25px", textAlign: "center" }}>
                              &nbsp;
                            </div>
                            <div style={{ width: "122px" }}>
                              <Link
                                className="ellipsis"
                                to="#"
                                style={{ maxWidth: "115px" }}
                              >
                                {t(prefix.sport_price[mlist.menu_key])}
                              </Link>
                            </div>
                            <div
                              style={{ width: "20px", textAlign: "right" }}
                              className="SpCnt"
                            >
                              {mlist.menu_num}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {
          (Object.keys(busketBet).length === 0 && busketBetPar.length === 0 ? 
          <>
          <div className="leftHx"></div>
          <div className="trStake">
            <BetListMini ViewBet={ViewBet} setViewBet={setViewBet} />
          </div>
          </>
          : null)
        }
        <div className="leftHx"></div>
      </div>
    </div>
  );
}
function dynamicSortMultiple() {
  var props = [];
  /*Let's separate property name from ascendant or descendant keyword*/
  for (var i = 0; i < arguments.length; i++) {
    var splittedArg = arguments[i].split(/ +/);
    props[props.length] = [
      splittedArg[0],
      splittedArg[1] ? splittedArg[1].toUpperCase() : "ASC",
    ];
  }
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length;
    /*Cycle on values until find a difference!*/
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i][0], props[i][1])(obj1, obj2);
      i++;
    }
    return result;
  };
}

function dynamicSort(property, isAscDesc) {
  return function (obj1, obj2) {
    if (isAscDesc === "DESC") {
      return obj1[property] > obj2[property]
        ? -1
        : obj1[property] < obj2[property]
        ? 1
        : 0;
    }
    /*else, if isAscDesc==="ASC"*/
    return obj1[property] > obj2[property]
      ? 1
      : obj1[property] < obj2[property]
      ? -1
      : 0;
  };
}
function hpdConv(val){
  var $pos1 = val.indexOf("-");
  if(val.length===1 || $pos1<0){
    return val;
  }else{
    var lv = val.split("-");
    //console.log(lv);
    var new_val = parseFloat(lv[0])+((parseFloat(lv[1])-parseFloat(lv[0]))/2);
    return formatMoney(new_val , 2);
  }
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}