import React from 'react'
import ReactHelmet from 'react-helmet';
import { usePrefixContext } from '../context/PrefixContext';

import img_img_models_dark_mo from "../Images/maintenance/images/img-models-dark-mo.png";
import img_img_models_dark from "../Images/maintenance/images/img-models-dark.png";

import img_flag_th from "../Images/maintenance/images/flag-th.png";
import img_flag_en from "../Images/maintenance/images/flag-en.png";
import img_flag_cn from "../Images/maintenance/images/flag-cn.png";


export default function Maintainence() {
  const { prefix } = usePrefixContext();
  var mnt_time = prefix.mtn_time;
  var ex_mnt = mnt_time.split(" (");
  return (
    <>
    <ReactHelmet link={[{"rel": "stylesheet", type:"text/css", "href": "/css/bootstrap.min.css"},{"rel": "stylesheet", type:"text/css", "href": "/css/maintainence.css"}]}/>
    <div id="panMsg0" style={{height: '100%'}} className="dark-theme">
        <div className="row mr-0 h-100" style={{marginRight: '0'}}>
          <div className="col-12 col-lg-6 maintainence-left">
            <img className="maintainence-largeimage darkmo d-lg-none" src={img_img_models_dark_mo} alt="" />
            <img className="maintainence-largeimage dark d-none d-lg-block" src={img_img_models_dark} alt="" />
          </div>
          <div className="col-12 col-lg-6 col-center p-5 p-md-0">
            <table className="maintainence-table">
                <tbody>
                  <tr>
                    <td><img className="flag-icon flag-th" src={img_flag_th} alt="" /></td>
                    <td className="description thai">ปิดอัพเดตและปรับปรุงระบบ <br className="d-sm-none" /> กลับมาใช้ได้เวลา. <br /><span className="gold-font"><span className="lg">{ex_mnt[0]}</span> {"("+ex_mnt[1]}</span></td>
                  </tr>
                  <tr>
                    <td><img className="flag-icon flag-en" src={img_flag_en} alt="" /></td>
                    <td className="description">We're down for maintenance. Be right back. <br /><span className="gold-font"><span className="lg">{ex_mnt[0]}</span> {"("+ex_mnt[1]}</span></td>
                  </tr>
                  <tr style={{opacity: 0}}>
                    <td><img className="flag-icon flag-ch" src={img_flag_cn} alt="" /></td>
                    <td className="description">网站维护中，服务恢复时间。 <br /><span className="gold-font"><span className="lg">02:00 PM</span> (GMT+8)</span></td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>
      </div>
      </>
  )
}
