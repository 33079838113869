import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import img_print from "../../Images/print.gif";
import img_stakeNew from "../../Images/stakeNew.gif";
export default function BetOne(props) {
  const { bData } = props;
  const { t } = useTranslation();

  var play_type = "";
  var play_text = null;
  var play_hdc = "";
  var play_1h = null;
  if (bData[7][0][0] === 1) {
    play_type = t("Handicap");
    if(bData[7][0][9]===1){
        play_text = <span className="GiveBet">{bData[7][0][7]}&nbsp;</span>;
        play_hdc = "("+(bData[7][0][2]!=="0" ? "-" : "")+hpdConv(bData[7][0][2])+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
    }else{
        play_text = <span className="TakeBet">{bData[7][0][7]}&nbsp;</span>;
        play_hdc = "("+hpdConv(bData[7][0][2])+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
    }
  } else if (bData[7][0][0] === 2) {
    play_type = t("Handicap");
    if(bData[7][0][9]===2){
        play_text = <span className="GiveBet">{bData[7][0][8]}&nbsp;</span>;
        play_hdc = "("+(bData[7][0][2]!=="0" ? "-" : "")+hpdConv(bData[7][0][2])+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
    }else{
        play_text = <span className="TakeBet">{bData[7][0][8]}&nbsp;</span>;
        play_hdc = "("+hpdConv(bData[7][0][2])+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
    }
  } else if (bData[7][0][0] === 3) {
    play_type = t("OVER_UNDER");
    play_hdc = "("+bData[7][0][2]+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
    play_text = <span className="Over">{t("over")}</span>;
  } else if (bData[7][0][0] === 4) {
    play_type = t("OVER_UNDER");
    play_hdc = "("+bData[7][0][2]+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
    play_text = <span className="Under">{t("under")}</span>;
  } else if (bData[7][0][0] === 5) {
    play_type = "1X2";
    play_text = <span className="GiveBet">{bData[7][0][7]} ({t("WIN").toUpperCase()})</span>;
  } else if (bData[7][0][0] === 6) {
    play_type = "1X2";
    play_text = <span className="TakeBet">{bData[7][0][8]} ({t("WIN").toUpperCase()})</span>;
  } else if (bData[7][0][0] === 7) {
    play_type = "1X2";
    play_text = <span className="TakeBet">{bData[7][0][7]} ({t("DRAWBet").toUpperCase()})</span>;
  } else if (bData[7][0][0] === 8) {
    play_type = t("ODD_EVEN");
    play_text = <span className="Over">{t("Odd")}</span>;
  } else if (bData[7][0][0] === 9) {
    play_type = t("ODD_EVEN");
    play_text = <span className="Under">{t("Even")}</span>;
  } else if (bData[7][0][0] === 10) {
    play_type = t("Handicap");
    play_1h = " ("+t("first_Half")+") ";
    if(bData[7][0][9]===1){
        play_hdc = "("+(bData[7][0][2]!=="0" ? "-" : "")+hpdConv(bData[7][0][2])+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
        play_text = <span className="GiveBet">{bData[7][0][7]}&nbsp;</span>;
    }else{
        play_hdc = "("+hpdConv(bData[7][0][2])+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
        play_text = <span className="TakeBet">{bData[7][0][7]}&nbsp;</span>;
    }
  } else if (bData[7][0][0] === 11) {
    play_type = t("Handicap");
    play_1h = " ("+t("first_Half")+") ";
    if(bData[7][0][9]===2){
        play_hdc = "("+(bData[7][0][2]!=="0" ? "-" : "")+hpdConv(bData[7][0][2])+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
        play_text = <span className="GiveBet">{bData[7][0][8]}&nbsp;</span>;
    }else{
        play_hdc = "("+hpdConv(bData[7][0][2])+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
        play_text = <span className="TakeBet">{bData[7][0][8]}&nbsp;</span>;
    }
  } else if (bData[7][0][0] === 12) {
    play_type = t("OVER_UNDER");
    play_1h = " ("+t("first_Half")+") ";
    play_hdc = "("+bData[7][0][2]+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
    play_text = <span className="Over">{t("over")}</span>;
  } else if (bData[7][0][0] === 13) {
    play_type = t("OVER_UNDER");
    play_1h = " ("+t("first_Half")+") ";
    play_hdc = "("+bData[7][0][2]+(bData[7][0][10]>0 ? " @ "+bData[7][0][12].replace("-", " - ") : "")+")";
    play_text = <span className="Under">{t("under")}</span>;
  } else if (bData[7][0][0] === 14) {
    play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="GiveBet">{bData[7][0][7]} ({t("WIN").toUpperCase()})</span>;
  } else if (bData[7][0][0] === 15) {
    play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="TakeBet">{bData[7][0][8]} ({t("WIN").toUpperCase()})</span>;
  } else if (bData[7][0][0] === 16) {
    play_type = "1X2";
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="TakeBet">{bData[7][0][7]} ({t("DRAWBet").toUpperCase()})</span>;
  } else if (bData[7][0][0] === 17) {
    play_type = t("ODD_EVEN");
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="Over">{t("Odd")}</span>;
  } else if (bData[7][0][0] === 18) {
    play_type = t("ODD_EVEN");
    play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="Under">{t("Even")}</span>;
  }

  if(bData[5]===3){
    return (
      <div className="GridItemBet StakeRunBg" style={{backgroundImage: `url(${img_stakeNew})`, marginTop: 3, padding: 2, border: '1px solid #c0c0c0', borderCollapse: 'collapse', height: '100%', lineHeight: '1.3'}}>
        <font size={1}>{bData[0]}&nbsp;({bData[1]})</font>
        <br />
        {bData[7][0][7]} -vs- {bData[7][0][8]}
        <br />
        {play_text}<b>{play_hdc}&nbsp;&nbsp;{formatMoney(bData[7][0][1] , 2)}</b>
        <br />
        <font size={1}>{bData[7][0][6]}
        <br />
        {play_type}</font>
        <div style={{display: 'inline-block', width: '100%', marginTop: 5}}>
          <div style={{float: 'left'}}>
            <span className="Waiting ">{t("Waiting")}</span>
          </div>
          <div className="Bold" style={{float: 'right'}}>{formatMoney(bData[2])}</div>
        </div>
      </div>
    )
  }


  return (
    <div
      className={"GridItemBet "+(bData[7][0][10]>0 ? "StakeRunBg" : "StakeBg2")}
      style={{
        marginTop: 3,
        padding: 2,
        border: "1px solid #c0c0c0",
        borderCollapse: "collapse",
        height: "100%",
        lineHeight: "1.3",
      }}
    >
      <font size={1}>
        {bData[0]}&nbsp;({bData[1]})
      </font>
      <br />
      {bData[7][0][7]} -vs- {bData[7][0][8]}
      <br />
      {play_text}
      <b>{play_hdc}&nbsp;&nbsp;{formatMoney(bData[7][0][1] , 2)}</b>
      <br />
      <font size={1}>
        {bData[7][0][6]}
        <br />
        {play_type}
      </font>
        {play_1h}
      <div
        style={{
          display: "inline-block",
          width: "100%",
          marginTop: 5,
        }}
      >
        <div style={{ float: "left" }}>
          {(bData[5]===3 ? <span className="Waiting">{t("Waiting")}</span> : (bData[5]===1 || bData[5]===0) && bData[4]===4 ? <span className="Rejected">ถูกปฏิเสธ</span> : <span className="Accepted2">{t("RunningBet")}</span>)}
        </div>
        <div className="Bold" style={{ float: "right" }}>
          {formatMoney(bData[2])}
          {
            bData[5]===1 && bData[4]===5 ? <Link to={"/BetSlip?id="+bData[0]} target="_blank">
            <img src={img_print} border={0} height={14} width={14} alt="" />
          </Link> : null
          }
        </div>
      </div>
    </div>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
function hpdConv(val){
    var $pos1 = val.indexOf("-");
    if(val.length===1 || $pos1<0){
      return val;
    }else{
      var lv = val.split("-");
      //console.log(lv);
      var new_val = parseFloat(lv[0])+((parseFloat(lv[1])-parseFloat(lv[0]))/2);
      return formatMoney(new_val , 2);
    }
  }