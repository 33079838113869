import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function LoadSkeletonResult() {
  var bg1f = "#FBF8D5";
  var bg2f = "#EDE6D3";

  return (
    <SkeletonTheme baseColor="#816847" highlightColor="#d0bf70" duration={1}>
      <table
        width={"100%"}
        cellPadding={5}
        cellSpacing={0}
        className="GridBorder"
      >
        <thead>
          <tr className="GridHeaderStyle">
            <td className="" style={{ height: "15px" }}>
              &nbsp;
            </td>
          </tr>
        </thead>
        <tbody>
            <>
              <tr className="GridHomeStyle">
                <td style={{}}>
                  <Skeleton />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridHomeStyle">
                <td style={{}}>
                  <Skeleton />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridHomeStyle">
                <td style={{}}>
                  <Skeleton />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg1f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
              <tr className="GridItem">
                <td style={{ backgroundColor: bg2f }}>
                  <Skeleton />
                  <Skeleton width={"80%"} />
                </td>
              </tr>
            </>
        </tbody>
      </table>
    </SkeletonTheme>
  );
}
