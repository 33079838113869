import React from "react";
import { usePrefixContext } from "../../../context/PrefixContext";
import { useTranslation } from "react-i18next";

export default function ColumTime(props) {
  const { liveTB, b_add, b_live_string, b_date_play, b_run_score_full } = props;
  const { t } = useTranslation();
  const prefixContext = usePrefixContext();

  var date_data_1 = new Date(b_date_play * 1000);
  var hours_data_1 = "0" + date_data_1.getHours();
  var minutes_data_1 = "0" + date_data_1.getMinutes();
  var formattedTime = hours_data_1.substr(-2) + ":" + minutes_data_1.substr(-2);

  var live_show = "";
  var live_show_ijtime = "";

  if(b_add===1 && liveTB===1){
    var arr_tm = b_live_string.split(" ");
    if(arr_tm.length===3){
      live_show = arr_tm[0]+" "+arr_tm[1];
      live_show_ijtime = <span className="OddsInjTime">{arr_tm[2]}</span>;
    }else if(arr_tm.length===2){
      
      if(parseInt(arr_tm[1])<0){
        live_show = t("live").toUpperCase();
      }else{
        live_show = arr_tm[0]+" "+arr_tm[1];
      }
    }else{
      live_show = b_live_string;
    }
  }

  return (
    <div
      style={{
        display: "table-cell",
        width: "45px",
        textAlign: "center",
        verticalAlign: "middle",
      }}
      className="GridItemTD"
    >
      {b_add === 1 ? (
        liveTB === 1 ? (
          <>
            <div className="Heading5">
              {b_run_score_full.replace("-", " - ")}
              <br />
            </div>
            <span className={(live_show==="H.Time" ? "Heading5" : "Heading7")}>
              {(live_show==="Live" ? <span className={(prefixContext.getCurrentLanguage()==="TH" ? "HeadingLIVE_ENGB" : "HeadingLIVE")}>{t("live").toUpperCase()}</span> : <span className={(live_show==="H.Time" ? "HalfTime" : "HeadingTime")}>{(live_show==="H.Time" ? t("H_TIME") : live_show)}{live_show_ijtime}</span>)}
              
            </span>
          </>
        ) : (
          <div className="Heading5">
            <span className={(prefixContext.getCurrentLanguage()==="TH" ? "HeadingLIVE_ENGB" : "HeadingLIVE")}>{t("live").toUpperCase()}</span>
            <br />
            {formattedTime}
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
}
