import React, { useState, useEffect } from "react";
import tabHIcon from "../../Images/tabHIcon.png";

import { useTranslation } from "react-i18next";
import { usePrefixContext } from '../../context/PrefixContext';

import axios from "axios";
import apiBet from "../../js/apiBet";

import OneRow from "./tableStake/OneRow";
import StepRow from "./tableStake/StepRow";

import LoadSkeletonStake from "../LoadSkeletonStake";

export default function Stake() {
  const [isCompleted, setIsCompleted] = useState(false);
  const [betListData, setBetListData] = useState([]);
  const [sumBill, setSumBill] = useState(0);

  const { getCurrentLanguage } = usePrefixContext();
  const { t } = useTranslation();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        setIsCompleted(false);
        apiBet.defaults.headers.common["x-lang"] = getCurrentLanguage();
        await apiBet
          .get(`getListBet`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setBetListData(res.data.data);
            setSumBill(res.data.sumb);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [getCurrentLanguage]);

  return (
    <>
    <div
      style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }}
      className="Backgroundx "
    >
      <div style={{ height: "12px" }}></div>
      <div className="preferenceTB">
        <div></div>
        <div style={{ width: "775px" }} className="tabGridBg1">
          <table
            width="100%"
            height="100%"
            border="0"
            cellSpacing="1"
            cellPadding="0"
          >
            <tbody>
              <tr className="tabHeaderBg">
                <td>
                  <table
                    width="100%"
                    height="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "10px" }}></td>
                        <td style={{ width: "25px" }} align="left">
                          <img alt="" src={tabHIcon} />
                        </td>
                        <td align="left" className="GridHeaderx">
                          {t("Bet_List")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="tabGridBg2">
                  <table
                    width="100%"
                    height="100%"
                    border="0"
                    cellSpacing="5"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr>
                        <td className="tabGridBg3">
                          <table
                            width="100%"
                            height="100%"
                            border="0"
                            cellSpacing="5"
                            cellPadding="0"
                          >
                            <tbody>
                              <tr>
                                <td className="tabGridBg4">
                                  <table
                                    width="100%"
                                    height="100%"
                                    border={0}
                                    cellSpacing={0}
                                    cellPadding={0}
                                  >
                                    <tbody>
                                      <tr>
                                        <td>
                                          <table
                                            className="GridStyle"
                                            cellSpacing={0}
                                            cellPadding={3}
                                            rules="all"
                                            id="Stake_mb1_grdStake"
                                            style={{
                                              borderWidth: 1,
                                              borderStyle: "solid",
                                              width: "100%",
                                              borderCollapse: "collapse",
                                            }}
                                          >
                                            <tbody>
                                              <tr
                                                className="GridHeaderStyle"
                                                align="center"
                                                style={{ whiteSpace: "nowrap" }}
                                              >
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                  style={{ width: 20 }}
                                                >
                                                  {" "}
                                                  {t("No")}{" "}
                                                </td>
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                  style={{ width: 100 }}
                                                >
                                                  {" "}
                                                  {t("Type")}{" "}
                                                </td>
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                  style={{ width: 100 }}
                                                >
                                                  <span
                                                    className="ellipsis"
                                                    title="วันที่"
                                                    style={{ maxWidth: 100 }}
                                                  >
                                                    {t("Date")}
                                                  </span>
                                                </td>
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                  style={{ width: 202 }}
                                                >
                                                  <span
                                                    className="ellipsis"
                                                    title="คู่แข่งขัน"
                                                    style={{ maxWidth: 200 }}
                                                  >
                                                    {t("event")}
                                                  </span>
                                                </td>
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                >
                                                  <span
                                                    className="ellipsis"
                                                    title="รายละเอียด"
                                                    style={{ maxWidth: 160 }}
                                                  >
                                                    {t("Detail")}
                                                  </span>
                                                </td>
                                                <td
                                                  className="GridColor"
                                                  align="right"
                                                  style={{ width: 50 }}
                                                >
                                                  <span
                                                    className="ellipsis"
                                                    title="เดิมพัน"
                                                    style={{ maxWidth: 50 }}
                                                  >
                                                    {t("Stake")}
                                                  </span>
                                                </td>
                                                <td
                                                  className="GridColor"
                                                  align="center"
                                                  style={{ width: 80 }}
                                                >
                                                  <span
                                                    className="ellipsis"
                                                    title="เดิมพัน"
                                                    style={{ maxWidth: 50 }}
                                                  >
                                                    {t("Stake")}
                                                  </span>
                                                </td>
                                              </tr>
                                              {isCompleted === true ? 
                                              betListData.map(function (tlist, j) {
                                                var bgR = "";
                                                if((j%2)===0){
                                                  bgR = "GridItemStyle";
                                                }else{
                                                  bgR = "GridAltItemStyle";
                                                }
                                                return (
                                                  (tlist[3]==="step" ? <StepRow key={j} n={j+1} bData={tlist} bgR={bgR} /> : <OneRow key={j} n={j+1} bData={tlist} bgR={bgR} />)
                                                );
                                              })
                                              :
                                              <LoadSkeletonStake />
                                              }
                                              {
                                                isCompleted === true ?
<tr
                                                className="GridAltItemStyle"
                                                align="center"
                                              >
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                >
                                                  {" "}
                                                  &nbsp;{" "}
                                                </td>
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                />
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                />
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                />
                                                <td
                                                  className="GridColor"
                                                  align="left"
                                                />
                                                <td
                                                  className="GridColor"
                                                  align="right"
                                                >
                                                  <span className="Simple">
                                                    {formatMoney(sumBill , 2)}
                                                  </span>
                                                  <br />
                                                  <font
                                                    style={{ color: "Gray" }}
                                                  />
                                                </td>
                                                <td
                                                  className="GridColor"
                                                  align="center"
                                                />
                                              </tr>
                                                :
                                                null
                                              }
                                              

                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
   
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}