import React, { useImperativeHandle, forwardRef } from 'react'
import Lottie from "lottie-react";
// import * as loadingJson from "./../animation/rotate_gold_coin.json";
import * as loadingJson from "./../animation/loading-soccer.json";

const LoadingItem = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        stop: () => { ref.current.stop(); },
        play: () => { ref.current.play(); },
        goToAndPlay: (value, isFrame) => { ref.current.goToAndPlay(value, isFrame); },
    }));

    return (
        <div className={`full-page-loading ${props.taskCount > 0 ? 'open' : ''}`}>
            <div className="loading">
                <Lottie animationData={loadingJson} lottieRef={ref} loop={true} style={{height: '100%'}} />
            </div>
        </div>
    )
})

export default LoadingItem