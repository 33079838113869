import React from "react";
import { useTranslation } from "react-i18next";
import img_stakeNew from "../../Images/stakeNew.gif";
export default function ParList(props) {
  const { parData } = props;
  const { t } = useTranslation();

  var play_type = "";
  var play_text = null;
  var play_hdc = "";
  //var play_1h = null;
  if (parData[0] === 1) {
    play_type = t("Handicap");
    if (parData[9] === 1) {
      play_text = <span className="GiveBet">{parData[7]}&nbsp;</span>;
      play_hdc = "(" + (parData[2] !== "0" ? "-" : "") + hpdConv(parData[2]) + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
    } else {
      play_text = <span className="TakeBet">{parData[7]}&nbsp;</span>;
      play_hdc = "(" + hpdConv(parData[2]) + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
    }
  } else if (parData[0] === 2) {
    play_type = t("Handicap");
    if (parData[9] === 2) {
      play_text = <span className="GiveBet">{parData[8]}&nbsp;</span>;
      play_hdc = "(" + (parData[2] !== "0" ? "-" : "") + hpdConv(parData[2]) + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
    } else {
      play_text = <span className="TakeBet">{parData[8]}&nbsp;</span>;
      play_hdc = "(" + hpdConv(parData[2]) + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
    }
  } else if (parData[0] === 3) {
    play_type = t("OVER_UNDER");
    play_hdc = "(" + parData[2] + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
    play_text = <span className="Over">{t("over")}</span>;
  } else if (parData[0] === 4) {
    play_type = t("OVER_UNDER");
    play_hdc = "(" + parData[2] + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
    play_text = <span className="Under">{t("under")}</span>;
  } else if (parData[0] === 5) {
    play_type = "1X2";
    play_text = <span className="GiveBet">{parData[7]} ({t("FT").toUpperCase()}.1)</span>;
  } else if (parData[0] === 6) {
    play_type = "1X2";
    play_text = <span className="TakeBet">{parData[8]} ({t("FT").toUpperCase()}.2)</span>;
  } else if (parData[0] === 7) {
    play_type = "1X2";
    play_text = <span className="TakeBet">{parData[7]} ({t("FT").toUpperCase()}.X)</span>;
  } else if (parData[0] === 8) {
    play_type = t("ODD_EVEN");
    play_text = <span className="Over">{t("Odd")}</span>;
  } else if (parData[0] === 9) {
    play_type = t("ODD_EVEN");
    play_text = <span className="Under">{t("Even")}</span>;
  } else if (parData[0] === 10) {
    play_type = t("Handicap");
    //play_1h = " ("+t("first_Half")+") ";
    if (parData[9] === 1) {
      play_hdc = "(" + (parData[2] !== "0" ? "-" : "") + hpdConv(parData[2]) + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
      play_text = <span className="GiveBet">{parData[7]}&nbsp;</span>;
    } else {
      play_hdc = "(" + hpdConv(parData[2]) + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
      play_text = <span className="TakeBet">{parData[7]}&nbsp;</span>;
    }
  } else if (parData[0] === 11) {
    play_type = t("Handicap");
    //play_1h = " ("+t("first_Half")+") ";
    if (parData[9] === 2) {
      play_hdc = "(" + (parData[2] !== "0" ? "-" : "") + hpdConv(parData[2]) + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
      play_text = <span className="GiveBet">{parData[8]}&nbsp;</span>;
    } else {
      play_hdc = "(" + hpdConv(parData[2]) + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
      play_text = <span className="TakeBet">{parData[8]}&nbsp;</span>;
    }
  } else if (parData[0] === 12) {
    play_type = t("OVER_UNDER");
    //play_1h = " ("+t("first_Half")+") ";
    play_hdc = "(" + parData[2] + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
    play_text = <span className="Over">{t("over")}</span>;
  } else if (parData[0] === 13) {
    play_type = t("OVER_UNDER");
    //play_1h = " ("+t("first_Half")+") ";
    play_hdc = "(" + parData[2] + (parData[10] > 0 ? " @ " + parData[12].replace("-", " - ") : "") + ")";
    play_text = <span className="Under">{t("under")}</span>;
  } else if (parData[0] === 14) {
    play_type = "1X2";
    //play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="GiveBet">{parData[7]} ({t("HT").toUpperCase()}.1)</span>;
  } else if (parData[0] === 15) {
    play_type = "1X2";
    //play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="TakeBet">{parData[8]} ({t("HT").toUpperCase()}.2)</span>;
  } else if (parData[0] === 16) {
    play_type = "1X2";
    //play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="TakeBet">{parData[7]} ({t("HT").toUpperCase()}.X)</span>;
  } else if (parData[0] === 17) {
    play_type = t("ODD_EVEN");
    //play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="Over">{t("Odd")}</span>;
  } else if (parData[0] === 18) {
    play_type = t("ODD_EVEN");
    //play_1h = " ("+t("first_Half")+") ";
    play_text = <span className="Under">{t("Even")}</span>;
  }

  if (parData[4] === 3) {
    return (
      <div
        className="GridItemBet StakeRunBg"
        style={{
          backgroundImage: `url(${img_stakeNew})`,
          margin: "3px 1px 3px 10px",
          padding: 2,
          border: "1px solid #c0c0c0",
          borderCollapse: "collapse",
          height: "100%",
          lineHeight: "1.3",
          borderRadius: 3,
        }}
      >
        {parData[6]} <br />
        {parData[7]} -vs- {parData[8]} <br />
        {parData[5]} <br />
        {play_text}
        <b>
          {play_hdc}&nbsp;&nbsp;{formatMoney(parData[1], 2)}
        </b>
        <br />
        <font size={1}>{play_type}</font>
        <div style={{ display: "inline-block", width: "100%" }}>
          <div style={{ float: "left" }}>
            <span className="Waiting ">{t("Waiting")}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={"GridItemBet " + (parData[10] > 0 ? "StakeRunBg" : "StakeBg2")}
      style={{
        margin: "3px 1px 3px 10px",
        padding: 2,
        border: "1px solid #c0c0c0",
        borderCollapse: "collapse",
        height: "100%",
        lineHeight: "1.3",
        borderRadius: 3,
      }}
    >
      {parData[6]} <br />
      {parData[7]} -vs- {parData[8]} <br />
      {parData[5]} <br />
      {play_text}
      <b>
        {play_hdc}&nbsp;&nbsp;{formatMoney(parData[1], 2)}
      </b>
      <br />
      <font size={1}>{play_type}</font>
      <div style={{ display: "inline-block", width: "100%" }}>
        <div style={{ float: "left" }}>{parData[3] === 6 ? <span className="Rejected">ถูกปฏิเสธ</span> : <span className="Accepted2">{t("RunningBet")}</span>}</div>
      </div>
    </div>
  );
}

function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
function hpdConv(val) {
  var $pos1 = val.indexOf("-");
  if (val.length === 1 || $pos1 < 0) {
    return val;
  } else {
    var lv = val.split("-");
    //console.log(lv);
    var new_val = parseFloat(lv[0]) + (parseFloat(lv[1]) - parseFloat(lv[0])) / 2;
    return formatMoney(new_val, 2);
  }
}
