import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import IndexM from "./IndexM";
import BetSlip from "./BetSlip";
import BetSlipX from "./BetSlipX";
import ParTrans from "./ParTrans";
import "./i18nextConf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<IndexM />} />
      <Route path="/BetSlip" element={<BetSlip />} />
      <Route path="/BetSlipX" element={<BetSlipX />} />
      <Route path="/ParTrans" element={<ParTrans />} />
    </Routes>
  </BrowserRouter>
);
