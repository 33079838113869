import React , {useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import img_back from "../Images/MiniCasino/back.png";
import img_arrowDown from "../Images/MiniCasino/arrowDown.png";
import img_arrowUp from "../Images/MiniCasino/arrowUp.png";
import img_info from "../Images/MiniCasino/info.png";

import img_lsBanner_s from "../Images/MiniCasino/lsBanner_s.jpg";
import img_lcBanner_s from "../Images/MiniCasino/lcBanner_s.jpg";

import img_ft_close from "../Images/MiniCasino/ft_close.gif";

export default function Right() {
  const [toogle_liveTv, setToogle_liveTv] = useState(false);
  const [toogle_liveCenter, setToogle_liveCenter] = useState(false);
  const [toogle_info, setToogle_info] = useState(false);

  const { t } = useTranslation();

  const handleToogle_liveTv = () => {
    if (toogle_liveTv === false) {
      setToogle_liveTv(true);
    } else {
      setToogle_liveTv(false);
    }
  };

  const handleToogle_liveCenter = () => {
    if (toogle_liveCenter === false) {
      setToogle_liveCenter(true);
    } else {
      setToogle_liveCenter(false);
      if (toogle_info === true) {
        setToogle_info(false);
      }
    }
  };

  const handleToogle_info = (value) => {
    if (toogle_info !== value) {
      setToogle_info(value);
    } 
  };

  return (
    <div className={"rightstyle"}>
      <div>
        <div className="accordion">
          <div style={{display:"table" , width:"100%" , height:"100%"}}>
            <div style={{display:"table-row" , verticalAlign:"middle"}}>
              <div style={{display:"table-cell" , verticalAlign:"middle" , textAlign:"left"}}>{t("live_stream")}</div>
              <div style={{display:"table-cell" , verticalAlign:"middle" , width:"20px" , textAlign:"center"}}>
                <Link to="#">
                  <img alt="" src={img_back} border="0" />
                </Link>
              </div>
              <div style={{display:"table-cell" , verticalAlign:"middle" , width:"25px" , textAlign:"center"}}>
                <img alt="" style={{cursor:"pointer"}} src={(toogle_liveTv===false ? img_arrowDown : img_arrowUp)} border="0" onClick={handleToogle_liveTv} />
              </div>
            </div>
          </div>
        </div>
        <div style={{width:"280px" , height:"238px" , display:(toogle_liveTv===false ? "none" : "")}}>
          <img alt="" style={{display: "block" , "WebkitUserSelect": "none"}} src={img_lsBanner_s} />
        </div>
      </div>
      <div style={{position:"relative" , overflow:"hidden"}}>
        <div className="accordion">
          <div style={{display:"table" , width:"100%" , height:"100%"}}>
            <div style={{display:"table-row" , verticalAlign:"middle"}}>
              <div style={{display:"table-cell" , verticalAlign:"middle" , textAlign:"left"}}>{t("live_center")}</div>
              <div style={{display:"table-cell" , verticalAlign:"middle" , width:"20px" , textAlign:"center"}}>
                  <img alt="" style={{cursor:"pointer"}} src={img_info} border="0" onClick={() => handleToogle_info(true)} />
              </div>
              <div style={{display:"table-cell" , verticalAlign:"middle" , width:"25px" , textAlign:"center"}}>
                <img alt="" style={{cursor:"pointer"}} src={(toogle_liveCenter===false ? img_arrowDown : img_arrowUp)} border="0" onClick={handleToogle_liveCenter} />
              </div>
            </div>
          </div>
        </div>
        <div style={{width:"280px" , height:"238px" , display:(toogle_liveCenter===false ? "none" : "")}}>
          <img alt="" style={{display: "block" , "WebkitUserSelect": "none"}} src={img_lcBanner_s} />
        </div>
        <div style={{ width:"268px" , height:"150x" , backgroundColor:"#575757" , color:"#ffffff" , border:"1px solid rgb(55, 68, 102)" , padding:"5px" , textAlign:"justify" , position:"absolute" , top:"29px" , display:(toogle_info===false ? "none" : "")}}>
          <div style={{display:"table" , width:"100%" , height:"100%"}}>
            <div style={{display:"table-row" , verticalAlign:"middle"}}>
              <div style={{display:"table-cell" , verticalAlign:"middle", height:"14px" , textAlign:"right" , padding:"2px"}}>
                <img alt="" style={{cursor:"pointer"}} src={img_ft_close} align="absmiddle" onClick={() => handleToogle_info(false)} />
              </div>
            </div>
            <div style={{display:"table-row" , verticalAlign:"middle"}}>
              <div style={{display:"table-cell" , verticalAlign:"middle" , padding:"10px 2px" , fontFamily:"Arial, Helvetica, sans-serif" , fontSize:"11px"}}>
                  All live content displayed in this panel is for information purposes only and members are advised to only use this as a guide. 
                  While we make every effort to ensure the content displayed is correct, Our Company  will assume no liability for incorrect information. 
                  For markets that are based on the live score, e.g. In-Play Asian Handicaps, the correct score that the market is based off will be displayed in the Bet Slip at time of bet placement.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
