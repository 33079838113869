import React, { useState } from "react";
import tabHIcon from "../../Images/tabHIcon.png";
import { useTranslation } from "react-i18next";
import AccHis from "./tableHistory/AccHis";
import AccGameWL from "./tableHistory/AccGameWL";
import AccMatchWL from "./tableHistory/AccMatchWL";
import AccMatchTrans from "./tableHistory/AccMatchTrans";

export default function AccHistory(props) {
  const { vShow , setVShow } = props;
  //const [vShow, setVShow] = useState(0);
  const [vShowDate, setVShowDate] = useState("");
  const [vShowGame, setVShowGame] = useState(0);
  const [vShowMatch, setVShowMatch] = useState(0);

  const { t } = useTranslation();

  const handleClickLink = (p ,vdate) => {
    setVShow(p);
    setVShowDate(vdate);
    setVShowGame(0);
  };

  const handleClickLink2 = (p ,vgame) => {
    setVShow(p);
    setVShowGame(vgame);
  };

  const handleClickLink3 = (p ,vmatch) => {
    setVShow(p);
    setVShowMatch(vmatch);
  };

  return (
    <div style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }} className="Backgroundx ">
      <div style={{ height: "12px" }}></div>
      <div className="preferenceTB">
        <div></div>
        <div style={{ width: "775px" }} className="tabGridBg1">
          <table width="100%" height="100%" border="0" cellSpacing="1" cellPadding="0">
            <tbody>
              <tr className="tabHeaderBg">
                <td>
                  <table width="100%" height="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td style={{ width: "10px" }}></td>
                        <td style={{ width: "25px" }} align="left">
                          <img alt="" src={tabHIcon} />
                        </td>
                        <td align="left" className="GridHeaderx">
                        {t("Statementx")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="tabGridBg2">
                  <table width="100%" height="100%" border="0" cellSpacing="5" cellPadding="0">
                    <tbody>
                      <tr>
                        <td className="tabGridBg3">
                          <table width="100%" height="100%" border="0" cellSpacing="5" cellPadding="0">
                            <tbody>
                              <tr>
                                <td className="tabGridBg4">
                                  {
                                    (vShow===1 ? <AccGameWL cc_link={handleClickLink} cc_link2={handleClickLink2} vShowDate={vShowDate} /> : vShow===2 ? <AccMatchWL cc_link={handleClickLink} cc_link2={handleClickLink3} vShowDate={vShowDate} vShowGame={vShowGame} />: vShow===3 ? <AccMatchTrans cc_link={handleClickLink2} vShowDate={vShowDate} vShowGame={vShowGame} vShowMatch={vShowMatch} /> : <AccHis cc_link={handleClickLink} />)
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
