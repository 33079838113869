import React, { useState, useEffect } from "react";
import LoadSkeletonGameWL from "../../LoadSkeletonGameWL";
import { Link } from "react-router-dom";

import { usePrefixContext } from '../../../context/PrefixContext';
import { useTranslation } from 'react-i18next';

import axios from "axios";
import apiBet from "../../../js/apiBet";
export default function AccGameWL(props) {
  const { cc_link, cc_link2 , vShowDate } = props;
  const [isCompleted, setIsCompleted] = useState(false);
  const [betListData, setBetListData] = useState([]);

  const { prefix } = usePrefixContext();
  const { t } = useTranslation();

  var list_date_cc = [t("Sun"), t("Mon"), t("Tue"), t("Wed"), t("Thu"), t("Fri"), t("Sat")];

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        setIsCompleted(false);
        await apiBet
          .get(`getGameWL?d=${vShowDate}`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setBetListData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [vShowDate]);
  var sum_total = 0;
  var sum_winloss = 0;
  return (
    <table width="100%" height="100%" border={0} cellSpacing={0} cellPadding={0}>
      <tbody>
        <tr>
          <td align="right" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <button className="BtnNormal" style={{ height: 20 }} onClick={() => cc_link(0, "")}>
            {t("Back")}
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <table className="GridStyle" cellSpacing={0} cellPadding={3} rules="all" id="AccGameWL_mb1_g" style={{ borderWidth: 1, borderStyle: "solid", width: "100%", borderCollapse: "collapse" }}>
              <tbody>
                <tr className="GridHeaderStyle" align="right">
                  <td className="GridColor" align="left" style={{ width: 20 }}>
                    &nbsp;
                  </td>
                  <td className="GridColor" align="left" style={{ width: 100 }}>
                  {t("Date")}
                  </td>
                  <td className="GridColor" align="left">
                  {t("event")}
                  </td>
                  <td className="GridColor" align="right" style={{ width: 80 }}>
                  {t("Stake")}
                  </td>
                  <td className="GridColor" align="right" style={{ width: 80 }}>
                  {t("W_L")}
                  </td>
                  <td className="GridColor" align="right" style={{ width: 80 }}>
                  {t("Com")}
                  </td>
                </tr>
                {isCompleted === true ? (
                  betListData.map(function (tlist, j) {
                    var bgR = "";
                    if (j % 2 === 0) {
                      bgR = "GridItemStyle";
                    } else {
                      bgR = "GridAltItemStyle";
                    }
                    sum_total = sum_total+parseFloat(tlist[3]);
                    sum_winloss = sum_winloss+parseFloat(tlist[4]);
                    return (
                      <tr className={bgR} align="right" key={j}>
                        <td className="GridColor" align="left">
                          {j+1}
                        </td>
                        <td className="GridColor" align="left" style={{ whiteSpace: "nowrap" }}>
                          <Link className="Link2" to="/" onClick={() => cc_link2(2, tlist[2])}>
                            {" "}
                            {tlist[1]} ({list_date_cc[tlist[0]].substring(0, 3)}){" "}
                          </Link>
                        </td>
                        <td className="GridColor" align="left">
                          {t(prefix.sport_list[tlist[2]])}
                        </td>
                        <td className="GridColor" align="right">
                          <span className="Simple">{formatMoney(tlist[3], 2)}</span>
                        </td>
                        <td className="GridColor" align="right">
                          <span className={tlist[4] >= 0 ? "Hd9 Simple" : "Negative Simple"}>{formatMoney(tlist[4], 2)}</span>
                        </td>
                        <td className="GridColor" align="right">
                          <span className="Hd9 Simple">0.00</span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <LoadSkeletonGameWL />
                )}
                {
                  isCompleted === true ? <tr className="GridAltItemStyle" align="right">
                  <td className="GridColor" align="left"></td>
                  <td className="GridColor" align="left" style={{ whiteSpace: "nowrap" }}></td>
                  <td className="GridColor" align="left"></td>
                  <td className="GridColor" align="right">
                    <span className="Simple">{formatMoney(sum_total, 2)}</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className={sum_winloss >= 0 ? "Hd9 Simple" : "Negative Simple"}>{formatMoney(sum_winloss, 2)}</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Hd9 Simple">0.00</span>
                  </td>
                </tr> : null
                }
                
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}