import React, { useState , useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePrefixContext } from "../../context/PrefixContext";
import { useAuthContext } from "../../context/AuthContext";
import tabHIcon from "../../Images/tabHIcon.png";
import api from './../../js/api';

export default function Preference(props) {
  const {
    socketId,
    onPageMain,
    onSportsSelect,
    onSportsPriceSelect,
    onToogle_live
  } = props;
  const { t } = useTranslation();
  const prefixContext = usePrefixContext();
  const { activeUser , setActUser } = useAuthContext();

  const [currentAT, setCurrentAT] = useState(activeUser.m_at);
  const [currentAcBetter, setCurrentAcBetter] = useState(activeUser.m_ac_better);
  const [errMsg, setErrMsg] = useState("");

  const inputRef = useRef();

  const changeAT = (at) => {
    setCurrentAT(at)
  }

  const changeAcBetter = (ac_better) => {
    setCurrentAcBetter(ac_better)
  }

  const checkName = async () => {
    try {
      //alert(inputRef.current.value);
      if(inputRef.current.value===""){
        setErrMsg(t("Please_enter_the_Nick_name"));
      }else{
        const res = await api.post(`checkName`, {"val":inputRef.current.value.trim()});
        if(res.data.status===1){
          setErrMsg(t("Nick_Name_is_available"));
        }else{
          setErrMsg(t("Nick_Name_already_exists"));
        }
      }
    } catch (error) {
       alert("Error");
    }
  };

  const saveSetting = async () => {
    try {
      //alert(inputRef.current.value);
      var name_send = "";
      if(activeUser.m_name !== null && activeUser.m_name !== ""){
        name_send = "";
      }else{
        name_send = inputRef.current.value.trim();
      }
      const res = await api.post(`setting`, {"name":name_send , "at":currentAT , "ac_better":currentAcBetter, "scid":socketId});
      if(res.data.status===1){
        setActUser(res.data.data);
        onPageMain("Ball");
        onSportsSelect(6);
        onSportsPriceSelect(1);
        onToogle_live(false);
      }else{
        setErrMsg(res.data.message);
      }
      
    } catch (error) {
       alert("Error");
    }
  };

  const closeSetting = () => {
    onPageMain("Ball");
    onSportsSelect(6);
    onSportsPriceSelect(1);
    onToogle_live(false);
  };

  //var errShow = false;
  return (
    <div
      style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }}
      className="Background "
    >
      <div style={{ height: "12px" }}></div>
      <div className="preferenceTB">
        <div></div>
        <div style={{ width: "775px" }} className="tabGridBg1">
          <table
            width="100%"
            height="100%"
            border="0"
            cellSpacing="1"
            cellPadding="0"
          >
            <tbody>
              <tr className="tabHeaderBg">
                <td>
                  <table
                    width="100%"
                    height="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "10px" }}></td>
                        <td style={{ width: "25px" }} align="left">
                          <img alt="" src={tabHIcon} />
                        </td>
                        <td align="left" className="GridHeaderx">
                          {t("Setting")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="tabGridBg2">
                  <table
                    width="100%"
                    height="100%"
                    border="0"
                    cellSpacing="5"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr>
                        <td className="tabGridBg3">
                          <table
                            width="100%"
                            height="100%"
                            border="0"
                            cellSpacing="5"
                            cellPadding="0"
                          >
                            <tbody>
                              <tr>
                                <td className="tabGridBg4">
                                  <table
                                    width="100%"
                                    height="100%"
                                    border="0"
                                    cellSpacing="0"
                                    cellPadding="0"
                                    className="Bold GridTable"
                                  >
                                    <tbody>
                                      <tr>
                                        <td className="GridItemStyle PreferenceBackground">
                                          <table
                                            width="100%"
                                            height="100%"
                                            border={0}
                                            cellSpacing={0}
                                            cellPadding={5}
                                          >
                                            <tbody>
                                              <tr>
                                                <td className="r" width="50%">
                                                {t("Nick_Name")}
                                                </td>
                                                <td className="l" width="50%">
                                                  <table
                                                    cellSpacing={0}
                                                    cellPadding={0}
                                                    width="100%"
                                                    border={0}
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          <input
                                                            name="txtNickName"
                                                            type="text"
                                                            maxLength={50}
                                                            id="txtNickName"
                                                            className="Textbox"
                                                            disabled={activeUser.m_name !== null && activeUser.m_name !== "" ? true : false}
                                                            defaultValue={activeUser.m_name !== null && activeUser.m_name !== "" ? activeUser.m_name : ""}
                                                            ref={inputRef}
                                                            style={{
                                                              width: 115,
                                                            }}
                                                          />
                                                          &nbsp;
                                                          {activeUser.m_name !== null && activeUser.m_name !== "" ? "" : 
                                                          <img
                                                            src={
                                                              "/locales/" +
                                                              prefixContext.getCurrentLanguage() +
                                                              "/check.gif"
                                                            }
                                                            alt=""
                                                            onClick={checkName}
                                                            style={{
                                                              verticalAlign:
                                                                "bottom",
                                                              cursor: "pointer",
                                                            }}
                                                          />}
                                                          
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              {errMsg !== "" ? (
                                                <tr>
                                                  <td colSpan="2">
                                                    <table
                                                      width="100%"
                                                      cellPadding={0}
                                                      cellSpacing={1}
                                                      border={0}
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td
                                                            width="100%"
                                                            height="10px"
                                                            align="center"
                                                          >
                                                            <span
                                                              id="lblError"
                                                              className="Error"
                                                            >
                                                              {errMsg}
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              ) : null}
                                              <tr id="trCboAccType">
                                                <td className="r">
                                                {t("Odds_Type")}
                                                </td>
                                                <td className="l">
                                                  <select
                                                    name="cboAccType"
                                                    id="cboAccType"
                                                    className="Bold"
                                                    value={currentAT}
                                                    onChange={(event) => changeAT(event.target.value)}
                                                    style={{
                                                      width: 100,
                                                      backgroundColor:
                                                        "#FFFFFF",
                                                    }}
                                                  >
                                                    <option value="MY">
                                                      MY
                                                    </option>
                                                    <option value="HK">
                                                      ฮ่องกง
                                                    </option>
                                                    <option value="EU">
                                                      EU
                                                    </option>
                                                  </select>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="r">
                                                {t("Accept_better_odds")}
                                                </td>
                                                <td className="l">
                                                  <select
                                                    name="cboBetterOdds"
                                                    id="cboBetterOdds"
                                                    className="Bold"
                                                    value={currentAcBetter}
                                                    onChange={(event) => changeAcBetter(event.target.value)}
                                                    style={{
                                                      width: 100,
                                                      backgroundColor:
                                                        "#FFFFFF",
                                                    }}
                                                  >
                                                    <option value={0}>
                                                    {t("Not")}
                                                    </option>
                                                    <option value={1}>
                                                    {t("Yes")}
                                                    </option>
                                                  </select>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td colSpan="2" align="center">
                                                  <table
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    width="100%"
                                                    border={0}
                                                    align="center"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          align="right"
                                                          width={"55%"}
                                                        >
                                                          <button
                                                            type="button"
                                                            name="btnSave"
                                                            className="btn btnBlue"
                                                            onClick={saveSetting}
                                                          >
                                                            {t("Save")}
                                                          </button>
                                                        </td>
                                                        <td width="3px">
                                                          &nbsp;
                                                        </td>
                                                        <td align="left">
                                                          <Link
                                                            className="btnGrey"
                                                            to="/"
                                                            onClick={closeSetting}
                                                          >
                                                            <span>{t("Close")}</span>
                                                          </Link>
                                                        </td>
                                                      </tr>
                                                      <tr style={{ height: 5 }}>
                                                        <td colSpan={3} />
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
