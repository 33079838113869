import React from "react";

import BallStep from "./page/BallStep";
import AccHistory from "./page/AccHistory";
import AccInfo from "./page/AccInfo";
import Result from "./page/Result";
import Stake from "./page/Stake";
import AccDisable from "./page/AccDisable";
import FavPage from "./page/FavPage";
import Preference from "./page/Preference";
import News from "./page/News";




export default function Main(props) {
  const { toogle_live, SportsSelect, SportsPriceSelect , PageMain , sportData , sportPrice , socketId , onSportsPriceSelect , setAtChange , atChange , onPageMain , onSportsSelect , onToogle_live , setBusketBet  , setBusketBetPar , vShow , setVShow } = props;
  //console.log(toogle_live, SportsSelect, SportsPriceSelect , PageMain);
  if(PageMain==="AccHistory"){ return  <AccHistory vShow={vShow} setVShow={setVShow} />} 
  if(PageMain==="AccInfo"){ return  <AccInfo />} 
  if(PageMain==="Result"){ return  <Result />} 
  if(PageMain==="Stake"){ return  <Stake />} 
  if(PageMain==="News"){ return  <News />} 
  if(PageMain==="AccDisable"){ return  <AccDisable />} 
  if(PageMain==="Preference"){ return  <Preference socketId={socketId} onPageMain={onPageMain} onSportsPriceSelect={onSportsPriceSelect} onSportsSelect={onSportsSelect} onToogle_live={onToogle_live} />} 
  if(PageMain==="FavPage"){ return  <FavPage toogle_live={toogle_live} SportsSelect={SportsSelect} SportsPriceSelect={SportsPriceSelect} sportData={sportData} sportPrice={sportPrice} socketId={socketId} onSportsPriceSelect={onSportsPriceSelect} setAtChange={setAtChange} atChange={atChange} setBusketBet={setBusketBet} setBusketBetPar={setBusketBetPar} />} 

  return  <BallStep toogle_live={toogle_live} SportsSelect={SportsSelect} SportsPriceSelect={SportsPriceSelect} sportData={sportData} sportPrice={sportPrice} socketId={socketId} onSportsPriceSelect={onSportsPriceSelect} setAtChange={setAtChange} atChange={atChange} setBusketBet={setBusketBet} setBusketBetPar={setBusketBetPar} />

}
