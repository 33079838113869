import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function SlipOne(props) {
  const { bData } = props;

  const { t } = useTranslation();

  var play_type = "";
  var play_text = null;
  var play_hdc = "";
  var play_1h = "";
  if (bData[7][0][0] === 1) {
    play_type = t("Handicap");
    if(bData[7][0][9]===1){
        play_text = bData[7][0][7];
        play_hdc = (bData[7][0][2]!=="0" ? "-" : "")+hpdConv(bData[7][0][2]);
    }else{
        play_text = bData[7][0][7];
        play_hdc = hpdConv(bData[7][0][2]);
    }
  } else if (bData[7][0][0] === 2) {
    play_type = t("Handicap");
    if(bData[7][0][9]===2){
        play_text = bData[7][0][8];
        play_hdc = (bData[7][0][2]!=="0" ? "-" : "")+hpdConv(bData[7][0][2]);
    }else{
        play_text = bData[7][0][8];
        play_hdc = hpdConv(bData[7][0][2]);
    }
  } else if (bData[7][0][0] === 3) {
    play_type = t("OVER_UNDER");
    play_hdc = bData[7][0][2];
    play_text = "สูง";
  } else if (bData[7][0][0] === 4) {
    play_type = t("OVER_UNDER");
    play_hdc = bData[7][0][2];
    play_text = "ต่ำ";
  } else if (bData[7][0][0] === 5) {
    play_type = "1X2";
    play_text = "Home Win";
  } else if (bData[7][0][0] === 6) {
    play_type = "1X2";
    play_text = "Away Win";
  } else if (bData[7][0][0] === 7) {
    play_type = "1X2";
    play_text = "Draw";
  } else if (bData[7][0][0] === 8) {
    play_type = "คี่ / คู่";
    play_text = "คี่";
  } else if (bData[7][0][0] === 9) {
    play_type = "คี่ / คู่";
    play_text = "คู่";
  } else if (bData[7][0][0] === 10) {
    play_type = t("Handicap");
    play_1h = "ครึ่งแรก";
    if(bData[7][0][9]===1){
        play_hdc = (bData[7][0][2]!=="0" ? "-" : "")+hpdConv(bData[7][0][2]);
        play_text = bData[7][0][7];
    }else{
        play_hdc = hpdConv(bData[7][0][2]);
        play_text = bData[7][0][7];
    }
  } else if (bData[7][0][0] === 11) {
    play_type = t("Handicap");
    play_1h = "ครึ่งแรก";
    if(bData[7][0][9]===2){
        play_hdc = (bData[7][0][2]!=="0" ? "-" : "")+hpdConv(bData[7][0][2]);
        play_text = bData[7][0][8];
    }else{
        play_hdc = hpdConv(bData[7][0][2]);
        play_text = bData[7][0][8];
    }
  } else if (bData[7][0][0] === 12) {
    play_type = t("OVER_UNDER");
    play_1h = "ครึ่งแรก";
    play_hdc = bData[7][0][2];
    play_text = "สูง";
  } else if (bData[7][0][0] === 13) {
    play_type = t("OVER_UNDER");
    play_1h = "ครึ่งแรก";
    play_hdc = bData[7][0][2];
    play_text = "ต่ำ";
  } else if (bData[7][0][0] === 14) {
    play_type = "1X2";
    play_1h = "ครึ่งแรก";
    play_text = "Home Win";
  } else if (bData[7][0][0] === 15) {
    play_type = "1X2";
    play_1h = "ครึ่งแรก";
    play_text = "Away Win";
  } else if (bData[7][0][0] === 16) {
    play_type = "1X2";
    play_1h = "ครึ่งแรก";
    play_text = "Draw";
  } else if (bData[7][0][0] === 17) {
    play_type = "คี่ / คู่";
    play_1h = "ครึ่งแรก";
    play_text = "คี่";
  } else if (bData[7][0][0] === 18) {
    play_type = "คี่ / คู่";
    play_1h = "ครึ่งแรก";
    play_text = "คู่";
  }

  useEffect(() => {
    window.print();
    return () => {
    };
  }, []);

  //console.log(play_hdc);
  return (
    <>
      <div id="panSingle" />
      <table className="bold" cellSpacing={0} cellPadding={0} width="100%">
        <tbody>
          <tr>
            <td width={100}>S/No :</td>
            <td>{bData[0]}</td>
          </tr>
          <tr>
            <td width={100}>Branch Code :</td>
            <td>{bData[9]}</td>
          </tr>
          <tr>
            <td width={100}>วันที่ / เวลา :</td>
            <td>{bData[1]}</td>
          </tr>
          <tr>
            <td width={100}>ประเภทเกม :</td>
            <td>Single Bet</td>
          </tr>
          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <table className="bold" style={{ borderCollapse: "collapse" }} cellSpacing={0} cellPadding={3} width="100%" border={0}>
                <tbody>
                  <tr>
                    <td>{bData[7][0][6]}</td>
                  </tr>
                  <tr>
                    <td>
                    {bData[7][0][7]} -vs- {bData[7][0][8]} {(bData[7][0][10]>0 ? "("+bData[7][0][12].replace("-", " - ")+")" : "")}
                    {(play_1h!=="" ? <br /> : "")}{play_1h}
                      <br />
                      {bData[7][0][5]}
                    </td>
                  </tr>
                  <tr>
                    <td>ชนิด: {play_type} &nbsp;&nbsp;&nbsp; อัตราต่อรอง: {formatMoney(bData[7][0][1] , 2)}</td>
                  </tr>
                  <tr>
                    <td>เดิมพัน : {play_text}&nbsp;{play_hdc}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td width={100}>จำนวนเงินที่สามารถวางเดิมพันได้ :</td>
            <td>{formatMoney(bData[2])}</td>
          </tr>
          <tr>
            <td width={100}>อัตราต่อรอง :</td>
            <td>{formatMoney(bData[7][0][1] , 2)}</td>
          </tr>
          <tr>
            <td width={100}>จ่ายเงินประมาณ :</td>
            <td>{formatMoney(bData[8] , 2)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
function hpdConv(val){
    var $pos1 = val.indexOf("-");
    if(val.length===1 || $pos1<0){
      return val;
    }else{
      var lv = val.split("-");
      //console.log(lv);
      var new_val = parseFloat(lv[0])+((parseFloat(lv[1])-parseFloat(lv[0]))/2);
      return formatMoney(new_val , 2);
    }
  }