import React from "react";
import { Link } from "react-router-dom";
import { usePrefixContext } from "../../../context/PrefixContext";
import { useTranslation } from "react-i18next";

export default function StepRow(props) {
  const { bData, n, bgR } = props;
  const { prefix, getCurrentLanguage } = usePrefixContext();
  const { t } = useTranslation();

  var bet_status = {
    "1" : t("WON"),
    "2" : "คืนทุน",
    "3" : t("LOSE"),
    "4" : "ยกเลิก",
    "5" : "รอผล"};

  return (
    <tr className={bgR} align="right">
      <td className="GridColor" align="left">
      {n}
      </td>
      <td className="GridColor" align="left">
        <span className="bold">{bData[0]}</span>
        <br />
        {bData[1]}
      </td>
      <td className="GridColor" align="left">
      {t(prefix.sport_list[bData[8]])}
        <br />
        {t("mix_parlay")}
      </td>
      <td className="GridColor" align="left">
        <span>{bData[7][0][14]}</span>
        <br />
        &nbsp;
        <Link to="/" className="normal" onClick={() => window.open("/ParTrans?id="+bData[0], "", "width=300,height=450,top=200,left=400,toolbars=no,scrollbars=yes,status=no,resizable=yes").focus()}>
          <span className="GBParlay Bold2">({t("mix_parlay")})</span>
          <br />
          &nbsp;@&nbsp;&nbsp;<span className="Bold">{formatMoney(bData[6], 3)}</span>
        </Link>
      </td>
      <td className="GridColor" align="center">
        <span className="Normal" />
        <span className={(bData[4]>2 ? "Error" : "Normal")}>{bet_status[bData[4]]}</span>
      </td>
      <td className="GridColor" align="right">
        <span className="Simple">{formatMoney(bData[2] , 2)}</span>
      </td>
      <td className="GridColor" align="center">
      {bData[5] === 3 ? <span className="Error">{t("Waiting")}</span> : (bData[5] === 1 || bData[5] === 0) && bData[4] === 4 ? <span className="GBGive4">ถูกปฏิเสธ</span> : <span className="Accepted">{t("ACCEPTED")}</span>}
      </td>
      <td className="GridColor" align="right">
        <span className="Hd9 Simple">0.00</span>
      </td>
      <td className="GridColor" align="right">
      <span className={(bData[11]<0 ? "Negative Simple" : "Hd9 Simple")}>{formatMoney(bData[11], 2)}</span>
      </td>
    </tr>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}