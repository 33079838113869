import React from "react";
import { useTranslation } from 'react-i18next';

export default function TitleBall1X2() {
  const { t } = useTranslation();
  return (
    <>
        <div style={{ display: "table-row" }} className="GridHeader">
          <div style={{ display: "table-cell", width: "45px" }} className="table_th3">{t("time")}</div>
          <div style={{ display: "table-cell", width: "300px" }} className="table_th4">{t("event")}</div>
          <div style={{ display: "table-cell" , width: "71px"}}>
            <div style={{ width: "1px", border:"none" }}>
                <div style={{ position: "relative", width: "428px", overflow: "hidden", border:"none" }}>
                    <div style={{ display: "table" , borderWidth:"0px 0px 0px 0px" }}>
                        <div style={{ display: "table-row", borderWidth:"0px 0px 0px 0px" }} className="GridHeader">
                            <div style={{ display: "table-cell" , width: "71px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">
                            <div style={{ width: "1px", border:"none" }}>
                                <div style={{ position: "relative", width: "214px", overflow: "hidden", border:"none" }}>{t("full_Time")}</div>
                            </div>
                            </div>
                            <div style={{ display: "table-cell" , width: "71px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                            <div style={{ display: "table-cell" , width: "71px" , borderWidth:"0px 1px 1px 0px" }} className="table_th1">&nbsp;</div>
                            <div style={{ display: "table-cell" , width: "71px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">
                            <div style={{ width: "1px", border:"none" }}>
                                <div style={{ position: "relative", width: "214px", overflow: "hidden", border:"none" }}>{t("first_Half")}</div>
                            </div>
                            </div>
                            <div style={{ display: "table-cell" , width: "71px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                            <div style={{ display: "table-cell" , width: "71px" , borderWidth:"0px 0px 1px 0px" }} className="table_th1">&nbsp;</div>
                        </div>
                        <div style={{ display: "table-row", borderWidth:"0px 0px 0px 0px" }} className="GridHeader">
                            <div style={{ display: "table-cell" , width: "71px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">1</div>
                            <div style={{ display: "table-cell" , width: "71px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">X</div>
                            <div style={{ display: "table-cell" , width: "71px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">2</div>
                            <div style={{ display: "table-cell" , width: "71px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">1</div>
                            <div style={{ display: "table-cell" , width: "71px", borderWidth:"0px 1px 0px 0px" }} className="table_th1">X</div>
                            <div style={{ display: "table-cell" , width: "71px", borderWidth:"0px" }} className="table_th1">2</div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div style={{ display: "table-cell" , width: "71px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "71px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "71px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "71px" }}>&nbsp;</div>
          <div style={{ display: "table-cell" , width: "71px" }}>&nbsp;</div>
      </div>
      
    </>
  );
}
