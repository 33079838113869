import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ViewWait from './betView/ViewWait'
import ViewVoid from './betView/ViewVoid'
import ViewLast10bet from './betView/ViewLast10bet'

export default function BetListMini(props) {
  const { ViewBet , setViewBet } = props;
  //const [ViewBet, setViewBet] = useState(0);
  const { t } = useTranslation();

  var Img_btn1 = "";
  var Img_btn2 = "";

  if(ViewBet===0){
    Img_btn1 = t("Waiting_Bet");
    Img_btn2 = t("Void");
  }else if(ViewBet===1){
    Img_btn1 = t("Bet_List_Mini");
    Img_btn2 = t("Void");
  }else if(ViewBet===2){
    Img_btn1 = t("Waiting_Bet");
    Img_btn2 = t("Void");
  }else if(ViewBet===3){
    Img_btn1 = t("Bet_List_Mini");
    Img_btn2 = t("Waiting_Bet");
  }


  const handleViewBet = (value) => {
    if(ViewBet===0 && value==="1"){
        setViewBet(1);
    }else if(ViewBet===0 && value==="2"){
        setViewBet(3);
    }else if(ViewBet===1 && value==="1"){
        setViewBet(2);
    }else if(ViewBet===2 && value==="1"){
        setViewBet(1);
    }else if(ViewBet===3 && value==="2"){
        setViewBet(1);
    }else if(ViewBet===1 && value==="2"){
        setViewBet(3);
    }else if(ViewBet===3 && value==="1"){
        setViewBet(2);
    }else if(ViewBet===2 && value==="2"){
        setViewBet(3);
    }
  };

  return (
    <>
      <div className="PanelSubHdrBg">
        <label
          className="ellipsis PanelStakeLbl"
          style={{ maxWidth: 174, height: "24px", lineHeight: "24px" }}
        >
          {ViewBet===0 || ViewBet===1 ? t("Waiting_Cancelled") : ViewBet===2 ? t("Bet_List_Mini") : ViewBet===3 ? t("Void_Cancelled_Bet") : ""}
        </label>
      </div>
      <div className="PanelItemGrid">
        <div className="PanelItemBg">
          <div style={{ height: "10px" }}></div>
          <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
            <div style={{ textAlign: "center" }}>
              <span className="buttonPanel" style={{ margin: "auto" }} onClick={() => handleViewBet("1")}>{Img_btn1}</span>
            </div>
            <div style={{ textAlign: "center" }}>
              <span className="buttonPanel" style={{ margin: "auto" }} onClick={() => handleViewBet("2")}>{Img_btn2}</span>
            </div>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="tableStake" style={{display:(ViewBet !== 0 ? "" : "none")}}>
            <div className="StakeBg1" style={{padding:"2px"}}>
                {ViewBet===1 ? <ViewWait /> : ViewBet===2 ? <ViewLast10bet /> : ViewBet===3 ? <ViewVoid /> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}