import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import SlipOneX from './components/betSlip/SlipOneX';
import SlipParX from './components/betSlip/SlipParX';

import axios from "axios";
import apiBet from "./js/apiBet";
//import apiBet from "./js/api_test";

export default function BetSlipX() {
  const [betData, setBetData] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    async function fetchData() {
      try {
        var stk = searchParams.get("id");
        //setIsCompleted(false);
        const token = localStorage.getItem("a-token");
        apiBet.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiBet.defaults.headers.common["x-lang"] = localStorage.getItem("lang");
        await apiBet
          .get(`getBet?id=${stk}`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setBetData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
        //setIsCompleted(true);
      } catch (error) {
        //setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, []);

  if(betData.length<=0){
    return null;
  }

  return (
    <table className="Grid" cellSpacing={2} cellPadding={2} width={250} border={1}>
      <tbody>
        <tr>
          <td>
            {(betData[3]==="step" ? <SlipParX bData={betData} /> : <SlipOneX bData={betData} />)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
