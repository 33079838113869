import React from "react";
import LeagueTitle from "./LeagueTitle";
import ListBall from "./ListBall";

export default function RowGTB(props) {
  const { dataLg, dataSp } = props;

  dataSp.sort(dynamicSortMultiple("2 Asc" , "6 Asc"));
  var ttoday_bid = 0;
  var ttoday_bnow = "GridAltItemStyle";

  var sppdt = [];
  for (var pop in dataSp) {
    var dpsp = dataSp[pop];

    var dp_obj = {};
    dp_obj["data"] = dpsp;
    sppdt.push(dp_obj);
  }

  //console.log(sppdt);

  return (
    <>
      <LeagueTitle dataLg={dataLg} />
      {sppdt.map(function (tlist, j) {
        var tldata = tlist.data;

        if (ttoday_bid !== tldata[0]) {
          ttoday_bid = tldata[0];
          if (ttoday_bnow === "GridAltItemStyle") {
            ttoday_bnow = "GridItemStyle";
          } else {
            ttoday_bnow = "GridAltItemStyle";
          }
        }

        return <ListBall bgColor={ttoday_bnow} dBall={tldata} key={j} />;
      })}
    </>
  );
}

function dynamicSortMultiple() {
  var props = [];
  /*Let's separate property name from ascendant or descendant keyword*/
  for (var i = 0; i < arguments.length; i++) {
    var splittedArg = arguments[i].split(/ +/);
    props[props.length] = [
      splittedArg[0],
      splittedArg[1] ? splittedArg[1].toUpperCase() : "ASC",
    ];
  }
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length;
    /*Cycle on values until find a difference!*/
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i][0], props[i][1])(obj1, obj2);
      i++;
    }
    return result;
  };
}

function dynamicSort(property, isAscDesc) {
  return function (obj1, obj2) {
    if (isAscDesc === "DESC") {
      return obj1[property] > obj2[property]
        ? -1
        : obj1[property] < obj2[property]
        ? 1
        : 0;
    }
    /*else, if isAscDesc==="ASC"*/
    return obj1[property] > obj2[property]
      ? 1
      : obj1[property] < obj2[property]
      ? -1
      : 0;
  };
}