import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePrefixContext } from "../context/PrefixContext";
import { availableLanguages } from "../i18nextConf";

import img_a13 from "../Images/a13.jpg";
import img_CN from "../Images/CN.jpg";
import img_EN from "../Images/EN.jpg";
import img_ES from "../Images/ES.jpg";
import img_FR from "../Images/FR.jpg";
import img_IN from "../Images/IN.jpg";
import img_JP from "../Images/JP.jpg";
import img_KH from "../Images/KH.jpg";
import img_KR from "../Images/KR.jpg";
import img_LA from "../Images/LA.jpg";
import img_MM from "../Images/MM.jpg";
import img_PT from "../Images/PT.jpg";
import img_TH from "../Images/TH.jpg";
import img_YN from "../Images/YN.jpg";

var img_icon_list = {};
img_icon_list["CN"] = img_CN;
img_icon_list["EN"] = img_EN;
img_icon_list["ES"] = img_ES;
img_icon_list["FR"] = img_FR;
img_icon_list["IN"] = img_IN;
img_icon_list["JP"] = img_JP;
img_icon_list["KH"] = img_KH;
img_icon_list["KR"] = img_KR;
img_icon_list["LA"] = img_LA;
img_icon_list["MM"] = img_MM;
img_icon_list["PT"] = img_PT;
img_icon_list["TH"] = img_TH;
img_icon_list["YN"] = img_YN;

export default function SelMenu() {
  const [toogle_lang, setToogle_lang] = useState(false);
  const { t } = useTranslation();
  const prefixContext = usePrefixContext();

  const handleToogle_lang = () => {
    if (toogle_lang === false) {
      setToogle_lang(true);
    } else {
      setToogle_lang(false);
    }
  };

  const handleHoverOff_lang = () => {
    setToogle_lang(false);
  };

  return (
    <div
      className="menuLang"
      onClick={handleToogle_lang}
      onMouseLeave={handleHoverOff_lang}
    >
      <div>
        <img alt="" src={img_a13} />
      </div>
      <div>
        {t(prefixContext.getCurrentLanguage())}&nbsp;
        <img
          alt=""
          src={img_icon_list[prefixContext.getCurrentLanguage()]}
          style={{ verticalAlign: "inherit" }}
        />
      </div>
      <div
        className="SelMenu"
        style={{
          width: "190px",
          position: "absolute",
          marginTop: "-38px",
          display: toogle_lang === true ? "grid" : "none",
        }}
      >
        {availableLanguages.map((l) => {
          return (
            <div
              className="SelMenuItemBg"
              key={`selected-lang-${l}`}
              onClick={(e) => {
                prefixContext.setCurrentLanguage(l, "cm");
              }}
            >
              <Link className="SelMenuItem" to="/">
                {t(l)}
              </Link>
            </div>
          );
        })}

        <div className="SelMenuItemBg">&nbsp;</div>
      </div>
    </div>
  );
}
