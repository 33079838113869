import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SelectTable from "../SelectTable";

import BarOption from "./tableBall/BarOption";
import TitleTBBall from "./tableBall/TitleTBBall";
import RowGTB from "./tableBall/RowGTB";

import LoadSkeleton from "../LoadSkeleton";

export default function FavPage(props) {
  const {
    toogle_live,
    SportsSelect,
    SportsPriceSelect,
    sportData,
    sportPrice,
    socketId,
    onSportsPriceSelect,
    setAtChange,
    atChange,
    setBusketBet,
    setBusketBetPar,
  } = props;
  const [pt, setPt] = useState(null);

  //console.log(SportsPriceSelect);

  const [sortTable, setSortTable] = useState(null);

  const [zoneSelect, setZoneSelect] = useState([]);
  const [closeZoneSelect, setCloseZoneSelect] = useState(true);

  const [blockLeague, setBlockLeague] = useState("0px");

  const [toogleSelectLeague, setToogleSelectLeague] = useState("none");

  const [teamFev, setTeamFev] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    setCurrentPT(getCurrentPT());
    setCurrentSortTable(getCurrentSortTable());
    setCurrentTeamFav(getCurrentTeamFav());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //console.log(sportPrice);

  const getCurrentTeamFav = () => {
    //if (teamFev !== null) return teamFev;
    return localStorage.getItem("teamFev");
  };

  const setCurrentTeamFav = async (l) => {
    if (l === "" || l === "null" || l === null) {
      l = [];
    } else {
      l = l.split(",");
    }
    setTeamFev(l);
    localStorage.setItem("teamFev", l);
    return l;
  };

  const getCurrentPT = () => {
    if (pt !== null) return pt;
    return localStorage.getItem("pt");
  };

  const setCurrentPT = async (l) => {
    if (l === null) {
      l = 1;
    }
    setPt(parseInt(l));
    localStorage.setItem("pt", l);
    return l;
  };

  const handlePt = (value) => {
    if (value !== pt) {
      setPt(parseInt(value));
      localStorage.setItem("pt", value);
    }
  };

  const getCurrentSortTable = () => {
    if (sortTable !== null) return sortTable;
    return localStorage.getItem("sortTable");
  };

  const setCurrentSortTable = async (l) => {
    if (l === null) {
      l = 0;
    }
    setSortTable(parseInt(l));
    localStorage.setItem("sortTable", l);
    return l;
  };

  const handleSortTable = () => {
    if (sortTable === 0) {
      setSortTable(1);
      localStorage.setItem("sortTable", 1);
    } else {
      setSortTable(0);
      localStorage.setItem("sortTable", 0);
    }
  };

  const handleOpenSelectLeague = (value, etop) => {
    //if (value !== toogleSelectLeague) {
    setBlockLeague(etop + "px");
    setToogleSelectLeague(value);
    //}
  };

  const handleCloseSelectLeague = (value) => {
    if (value !== toogleSelectLeague) {
      setToogleSelectLeague(value);
    }
  };

  const handleZoneSelect = (value) => {
    setZoneSelect(value);
  };

  const handleCloseZoneSelect = (value) => {
    setCloseZoneSelect(value);
  };

  const handleTeamFav = (value) => {
    var arr_fev = teamFev;
    for (var ifx in value) {
      var tfv = value[ifx];
      //console.log(inArray(tfv , arr_fev));
      if (!inArray(String(tfv), arr_fev)) {
        arr_fev.push(String(tfv));
      } else {
        arr_fev = arr_fev.filter((item) => item !== String(tfv));
      }
    }
    for (var ifxx in arr_fev) {
      var tfvx = arr_fev[ifxx];
      if (zbData[tfvx] === undefined) {
        arr_fev = arr_fev.filter((item) => item !== String(tfvx));
      }
    }
    setTeamFev(arr_fev);
    localStorage.setItem("teamFev", arr_fev);
  };

  //console.log(teamFev);
  //console.log(SportsSelect);
  var zone_live = [];
  var zone_today = [];
  var zone_all = [];

  var zone_live_data = {};
  var zone_today_data = {};

  var zone_live_price = {};
  var zone_today_price = {};

  var zone_live_data_count = 0;
  var zone_today_data_count = 0;

  var zbData = {};
  var zbPrice = {};
  if (sportData !== "") {
    zbData = JSON.parse(sportData);
  }
  if (sportPrice !== "") {
    var zbPricex = JSON.parse(sportPrice);
    if (SportsPriceSelect === 4) {
      zbPrice = zbPricex[1];
    } else {
      zbPrice = zbPricex[0];
    }
    //console.log(zbPrice);
  }

  const RemoveDuplicates = (array, key) => {
    return array.reduce((arr, item) => {
      const removed = arr.filter((i) => i[key] !== item[key]);
      return [...removed, item];
    }, []);
  };
  //console.log(teamFev);
  for (var iy in zbData) {
    var spx = zbData[iy];

    var arr_fev = teamFev;
    //console.log(inArray(String(spx[0]) , arr_fev) , String(spx[0]));
    if (!inArray(String(spx[0]), arr_fev)) {
      continue;
    }
    //console.log(spx);
    if (spx[5] === SportsSelect && spx[10] !== 4) {
      zone_all.push({
        zone_key: spx[15],
        zone_id: spx[15],
        zone_en: spx[25],
        zone_th: spx[28],
        zone_cn: spx[31],
        zASC: spx[2],
      });
    }

    if (spx[5] === SportsSelect && closeZoneSelect === false) {
      if (!zoneSelect.includes(spx[15])) {
        continue;
      }
    }

    var bidd = spx[0];
    var have_oe = 0;
    var have_1x2 = 0;
    for (var idp in zbPrice[bidd]) {
      var spp = zbPrice[bidd][idp];
      if (spp[19] !== 0 && spp[20] !== 0) {
        have_oe = 1;
      }

      if (
        (spp[15] > 0.0 && spp[16] > 0.0 && spp[17] > 0.0) ||
        (spp[31] > 0.0 && spp[32] > 0.0 && spp[33] > 0.0)
      ) {
        have_1x2 = 1;
      }
    }

    if (SportsPriceSelect === 2) {
      if (have_oe === 0) {
        continue;
      }
    } else if (SportsPriceSelect === 3) {
      if (have_1x2 === 0) {
        continue;
      }
    } else if (SportsPriceSelect === 4) {
      if (spx[4] !== 2) {
        continue;
      }
    }

    if (spx[10] === 0 && spx[5] === SportsSelect) {
      if (zone_today_price[spx[15]] === undefined) {
        zone_today_price[spx[15]] = {};
      }
      zone_today_price[spx[15]][spx[0]] = zbPrice[spx[0]];
      if (sortTable === 1 && SportsPriceSelect !== 2 && SportsSelect === 6) {
        if (zone_today_data[spx[15] + "_" + spx[2]] === undefined) {
          zone_today_data[spx[15] + "_" + spx[2]] = [];
        }
        zone_today_data[spx[15] + "_" + spx[2]].push(spx);
        //"44896_1657824300"
        //console.log(spx[15] + "_" + spx[2]);

        zone_today.push({
          zone_key: spx[15] + "_" + spx[2],
          zone_id: spx[15],
          zone_en: spx[25],
          zone_th: spx[28],
          zone_cn: spx[31],
          zASC: spx[2],
        });
        zone_today_data_count++;
      } else {
        if (zone_today_data[spx[15]] === undefined) {
          zone_today_data[spx[15]] = [];
        }
        zone_today_data[spx[15]].push(spx);
        if (SportsPriceSelect === 4) {
          zone_today.push({
            zone_key: spx[15],
            zone_id: spx[15],
            zone_en: spx[25],
            zone_th: spx[28],
            zone_cn: spx[31],
            zASC: spx[35],
          });
        } else {
          zone_today.push({
            zone_key: spx[15],
            zone_id: spx[15],
            zone_en: spx[25],
            zone_th: spx[28],
            zone_cn: spx[31],
            zASC: spx[23],
          });
        }

        zone_today_data_count++;
      }
    } else if (spx[10] > 0 && spx[10] < 4 && spx[5] === SportsSelect) {
      if (zone_live_price[spx[15]] === undefined) {
        zone_live_price[spx[15]] = {};
      }

      zone_live_price[spx[15]][spx[0]] = zbPrice[spx[0]];
      if (sortTable === 1 && SportsPriceSelect !== 2 && SportsSelect === 6) {
        if (zone_live_data[spx[15] + "_" + spx[2]] === undefined) {
          zone_live_data[spx[15] + "_" + spx[2]] = [];
        }
        zone_live_data[spx[15] + "_" + spx[2]].push(spx);

        zone_live.push({
          zone_key: spx[15] + "_" + spx[2],
          zone_id: spx[15],
          zone_en: spx[25],
          zone_th: spx[28],
          zone_cn: spx[31],
          zASC: spx[2],
        });
        zone_live_data_count++;
      } else {
        if (zone_live_data[spx[15]] === undefined) {
          zone_live_data[spx[15]] = [];
        }
        zone_live_data[spx[15]].push(spx);
        if (SportsPriceSelect === 4) {
          zone_live.push({
            zone_key: spx[15],
            zone_id: spx[15],
            zone_en: spx[25],
            zone_th: spx[28],
            zone_cn: spx[31],
            zASC: spx[36],
          });
        } else {
          zone_live.push({
            zone_key: spx[15],
            zone_id: spx[15],
            zone_en: spx[25],
            zone_th: spx[28],
            zone_cn: spx[31],
            zASC: spx[24],
          });
        }

        zone_live_data_count++;
      }
    }
  }

  zone_live = RemoveDuplicates(zone_live, "zone_key");
  zone_today = RemoveDuplicates(zone_today, "zone_key");

  zone_live.sort(dynamicSortMultiple("zASC Asc", "zone_id Asc"));
  zone_today.sort(dynamicSortMultiple("zASC Asc", "zone_id Asc"));

  if (sortTable === 1 && SportsPriceSelect !== 2 && SportsSelect === 6) {
    var oldzx_today = 0;
    var newzxd_today = [];

    for (var zzo in zone_today) {
      var zzod = zone_today[zzo];
      if (oldzx_today !== zzod["zone_id"]) {
        oldzx_today = zzod["zone_id"];
        newzxd_today.push(zzod);
      } else {
        var zkeyx = zone_today[zzo - 1]["zone_key"];
        if (zone_today_data[zkeyx] !== undefined) {
          var zkeyo = zzod["zone_key"];
          for (var zzddx in zone_today_data[zkeyo]) {
            zone_today_data[zkeyx].push(zone_today_data[zkeyo][zzddx]);
          }
          delete zone_today_data[zkeyo];
        }
      }
    }
    zone_today = newzxd_today;

    var oldzx_live = 0;
    var newzxd_live = [];
    for (var zzo in zone_live) {
      var zzod = zone_live[zzo];
      if (oldzx_live !== zzod["zone_id"]) {
        oldzx_live = zzod["zone_id"];
        newzxd_live.push(zzod);
      } else {
        var zkeyx = zone_live[zzo - 1]["zone_key"];
        if (zone_live_data[zkeyx] !== undefined) {
          var zkeyo = zzod["zone_key"];
          for (var zzddx in zone_live_data[zkeyo]) {
            zone_live_data[zkeyx].push(zone_live_data[zkeyo][zzddx]);
          }
          delete zone_live_data[zkeyo];
        }
      }
    }
    zone_live = newzxd_live;
  }

  zone_all = RemoveDuplicates(zone_all, "zone_key");
  zone_all.sort(dynamicSortMultiple("zone_en Asc", "zone_id Asc"));
  //console.log(toogle_live);

  return (
    <div
      style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }}
      className="Background "
    >
      <div style={{ height: "5px" }}></div>
      <div className="sportTB">
        <div></div>
        <div style={{ width: "775px" }}>
          <div
            id="tableRun"
            style={{
              display:
                zone_live_data_count > 0 || toogle_live === true ? "" : "none",
            }}
          >
            <BarOption
              onOpenSelectLeague={handleOpenSelectLeague}
              onPt={handlePt}
              setAtChange={setAtChange}
              onSortTable={handleSortTable}
              pt={pt}
              sortTable={sortTable}
              liveTB={1}
              toogle_live={toogle_live}
              SportsSelect={SportsSelect}
              SportsPriceSelect={SportsPriceSelect}
              socketId={socketId}
              onSportsPriceSelect={onSportsPriceSelect}
              thisFavPage={1}
              setBusketBet={setBusketBet}
              setBusketBetPar={setBusketBetPar}
            />
            {socketId === "" || pt === null ? (
              <LoadSkeleton liveTB={1} />
            ) : (
              <div className="GridBorder">
                <TitleTBBall
                  pt={pt}
                  liveTB={1}
                  SportsSelect={SportsSelect}
                  SportsPriceSelect={SportsPriceSelect}
                />
                {zone_live_data_count > 0 ? (
                  zone_live.map(function (tlist, j) {
                    return (
                      <RowGTB
                        pt={pt}
                        atChange={atChange}
                        SportsSelect={SportsSelect}
                        SportsPriceSelect={SportsPriceSelect}
                        liveTB={1}
                        key={j}
                        dataLg={tlist}
                        dataSp={zone_live_data[tlist.zone_key]}
                        priceSp={zone_live_price[tlist.zone_id]}
                        sortTable={sortTable}
                        teamFev={teamFev}
                        onTeamFav={handleTeamFav}
                        setBusketBet={setBusketBet}
                      />
                    );
                  })
                ) : (
                  <div style={{ display: "table-row" }} className="GridItem">
                    <div
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        textAlign: "center",
                        height: "40px",
                        color: "black",
                        fontWeight: "bold",
                        backgroundColor: "#fbcbbd",
                      }}
                    >
                      <div style={{ width: "1px" }}>
                        <div
                          style={{
                            position: "relative",
                            width: "773px",
                            overflow: "hidden",
                          }}
                        >
                          {t("no_events")}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "table-cell",
                        backgroundColor: "#fbcbbd",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        backgroundColor: "#fbcbbd",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        backgroundColor: "#fbcbbd",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        backgroundColor: "#fbcbbd",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        backgroundColor: "#fbcbbd",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        backgroundColor: "#fbcbbd",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "table-cell",
                        backgroundColor: "#fbcbbd",
                      }}
                    ></div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            style={{
              height: "20px",
              display:
                zone_live_data_count > 0 && toogle_live === false ? "" : "none",
            }}
          ></div>
          <div id="tableToday">
            {toogle_live === false ? (
              <>
                <BarOption
                  onOpenSelectLeague={handleOpenSelectLeague}
                  onPt={handlePt}
                  setAtChange={setAtChange}
                  onSortTable={handleSortTable}
                  pt={pt}
                  sortTable={sortTable}
                  liveTB={0}
                  toogle_live={toogle_live}
                  SportsSelect={SportsSelect}
                  SportsPriceSelect={SportsPriceSelect}
                  socketId={socketId}
                  onSportsPriceSelect={onSportsPriceSelect}
                  thisFavPage={1}
                  setBusketBet={setBusketBet}
                  setBusketBetPar={setBusketBetPar}
                />
                {socketId === "" || pt === null ? (
                  <LoadSkeleton liveTB={0} />
                ) : (
                  <div className="GridBorder">
                    <TitleTBBall
                      pt={pt}
                      liveTB={0}
                      SportsSelect={SportsSelect}
                      SportsPriceSelect={SportsPriceSelect}
                    />
                    {zone_today_data_count > 0 ? (
                      zone_today.map(function (tlist, j) {
                        return (
                          <RowGTB
                            pt={pt}
                            atChange={atChange}
                            SportsSelect={SportsSelect}
                            SportsPriceSelect={SportsPriceSelect}
                            liveTB={0}
                            key={j}
                            dataLg={tlist}
                            dataSp={zone_today_data[tlist.zone_key]}
                            priceSp={zone_today_price[tlist.zone_id]}
                            sortTable={sortTable}
                            teamFev={teamFev}
                            onTeamFav={handleTeamFav}
                            setBusketBet={setBusketBet}
                          />
                        );
                      })
                    ) : (
                      <div
                        style={{ display: "table-row" }}
                        className="GridItem"
                      >
                        <div
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle",
                            textAlign: "center",
                            height: "40px",
                            color: "black",
                            fontWeight: "bold",
                            backgroundColor: "#e8e8e8",
                          }}
                        >
                          <div style={{ width: "1px" }}>
                            <div
                              style={{
                                position: "relative",
                                width: "773px",
                                overflow: "hidden",
                              }}
                            >
                              {t("no_events")}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "table-cell",
                            backgroundColor: "#e8e8e8",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "table-cell",
                            backgroundColor: "#e8e8e8",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "table-cell",
                            backgroundColor: "#e8e8e8",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "table-cell",
                            backgroundColor: "#e8e8e8",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "table-cell",
                            backgroundColor: "#e8e8e8",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "table-cell",
                            backgroundColor: "#e8e8e8",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "table-cell",
                            backgroundColor: "#e8e8e8",
                          }}
                        ></div>
                        <div
                          style={{
                            display: "table-cell",
                            backgroundColor: "#e8e8e8",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : null}
          </div>
          <div
            className="morebets"
            style={{
              display: toogleSelectLeague,
              top: blockLeague,
              overflow: "hidden",
              left: "100px",
            }}
          >
            <SelectTable
              onCloseSelectLeague={handleCloseSelectLeague}
              zone_all={zone_all}
              zoneSelect={zoneSelect}
              onZoneSelect={handleZoneSelect}
              closeZoneSelect={closeZoneSelect}
              onCloseZoneSelect={handleCloseZoneSelect}
            />
          </div>
          <div style={{ height: "20px" }}></div>
        </div>
      </div>
    </div>
  );
}

function dynamicSortMultiple() {
  var props = [];
  /*Let's separate property name from ascendant or descendant keyword*/
  for (var i = 0; i < arguments.length; i++) {
    var splittedArg = arguments[i].split(/ +/);
    props[props.length] = [
      splittedArg[0],
      splittedArg[1] ? splittedArg[1].toUpperCase() : "ASC",
    ];
  }
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length;
    /*Cycle on values until find a difference!*/
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i][0], props[i][1])(obj1, obj2);
      i++;
    }
    return result;
  };
}

function dynamicSort(property, isAscDesc) {
  return function (obj1, obj2) {
    if (isAscDesc === "DESC") {
      return obj1[property] > obj2[property]
        ? -1
        : obj1[property] < obj2[property]
        ? 1
        : 0;
    }
    /*else, if isAscDesc==="ASC"*/
    return obj1[property] > obj2[property]
      ? 1
      : obj1[property] < obj2[property]
      ? -1
      : 0;
  };
}
function inArray(needle, haystack) {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] === needle) return true;
  }
  return false;
}
