import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function LoadSkeletonStake() {
  return (
    <SkeletonTheme baseColor="#816847" highlightColor="#d0bf70" duration={1}>
      <tr className="GridItemStyle" align="center">
        <td className="GridColor" align="left" colSpan={7}>
          <Skeleton />
        </td>
      </tr>
      <tr className="GridAltItemStyle" align="center">
        <td className="GridColor" align="left" colSpan={7}>
        <Skeleton width={"80%"} />
        </td>
      </tr>
      <tr className="GridItemStyle" align="center">
        <td className="GridColor" align="left" colSpan={7}>
          <Skeleton />
        </td>
      </tr>
      <tr className="GridAltItemStyle" align="center">
        <td className="GridColor" align="left" colSpan={7}>
        <Skeleton width={"80%"} />
        </td>
      </tr>
    </SkeletonTheme>
  );
}
