import React from "react";

import img_icoleft from "../../Images/icoleft.png";

export default function AccDisable() {
  return (
    <div
      style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }}
      className="Background "
    >
      <div style={{ height: "5px" }}></div>
      <div className="sportTB">
        <div></div>
        <div style={{ width: "775px" }}>
          <div className="tbBarOption">
            <div style={{ display: "table-row" }}>
              <div
                style={{
                  display: "table-cell",
                  width: "20px",
                  textAlign: "left",
                  verticalAlign: "middle",
                }}
              >
                <img
                  src={img_icoleft}
                  width="15"
                  height="15"
                  align="absmiddle"
                  alt=""
                />
              </div>
              <div
                style={{
                  display: "table-cell",
                  verticalAlign: "middle",
                  width: "745px",
                }}
              >
                <div
                  className="optCont"
                  style={{
                    height: "25px",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "table-row" }}>
                    <div
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                      }}
                    >
                      <span className="GridTitle" style={{color:"red"}}>
                        Account Game disabled
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "table-cell",
                  width: "10px",
                }}
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
