import React, { useState, useEffect } from "react";
import LoadSkeletonMatchTrans from "../../LoadSkeletonMatchTrans";

import OneRow from "./OneRow";
import StepRow from "./StepRow";

import { usePrefixContext } from '../../../context/PrefixContext';
import { useTranslation } from 'react-i18next';

import axios from "axios";
import apiBet from "../../../js/apiBet";

export default function AccMatchTrans(props) {
  const { cc_link, vShowDate, vShowGame, vShowMatch } = props;
  const [isCompleted, setIsCompleted] = useState(false);
  const [betListData, setBetListData] = useState([]);

  const { getCurrentLanguage } = usePrefixContext();
  const { t } = useTranslation();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        setIsCompleted(false);
        apiBet.defaults.headers.common["x-lang"] = getCurrentLanguage();
        await apiBet
          .get(`getMatchTrans?d=${vShowDate}&g=${vShowGame}&m=${vShowMatch}`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setBetListData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [vShowDate, vShowGame, vShowMatch , getCurrentLanguage]);

  var sum_total = 0;
  var sum_winloss = 0;

  return (
    <table width="100%" height="100%" border={0} cellSpacing={0} cellPadding={0}>
      <tbody>
        <tr className="ResBg">
          <td align="right" style={{ paddingTop: 10, paddingBottom: 10 }}>
            <button className="BtnNormal" style={{ height: 20 }} onClick={() => cc_link(2, vShowGame)}>
            {t("Back")}
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <table className="GridStyle" cellSpacing={0} cellPadding={3} rules="all" id="AccMatchTrans_mb1_grdTrans" style={{ borderWidth: 1, borderStyle: "solid", width: "100%", borderCollapse: "collapse" }}>
              <tbody>
                <tr className="GridHeaderStyle" align="right">
                  <td className="GridColor" align="left" style={{ width: 20 }}>
                    &nbsp;
                  </td>
                  <td className="GridColor" align="left" style={{ width: 100 }}>
                  {t("Date")}
                  </td>
                  <td className="GridColor" align="left" style={{ width: 100 }}>
                  {t("event")}
                  </td>
                  <td className="GridColor" align="left">
                  {t("Detail")}
                  </td>
                  <td className="GridColor" align="center" style={{ width: 60 }}>
                  {t("Result")}
                  </td>
                  <td className="GridColor" align="right" style={{ width: 50 }}>
                  {t("Stake")}
                  </td>
                  <td className="GridColor" align="center" style={{ width: 80 }}>
                  {t("Status")}
                  </td>
                  <td className="GridColor" align="right" style={{ width: 50 }}>
                  {t("Com")}
                  </td>
                  <td className="GridColor" align="right" style={{ width: 50 }}>
                  {t("W_L")}
                  </td>
                </tr>

                {isCompleted === true ? (
                  betListData.map(function (tlist, j) {
                    var bgR = "";
                    if (j % 2 === 0) {
                      bgR = "GridItemStyle";
                    } else {
                      bgR = "GridAltItemStyle";
                    }
                    sum_total = sum_total+parseFloat(tlist[2]);
                    sum_winloss = sum_winloss+parseFloat(tlist[11]);
                    return (
                      (tlist[3]==="step" ? <StepRow key={j} n={j+1} bData={tlist} bgR={bgR} /> : <OneRow key={j} n={j+1} bData={tlist} bgR={bgR} />)
                    );
                  })
                ) : (
                  <LoadSkeletonMatchTrans />
                )}
                {isCompleted === true ? <tr className="GridAltItemStyle" align="right">
                  <td className="GridColor" align="left"></td>
                  <td className="GridColor" align="left"></td>
                  <td className="GridColor" align="left">
                    <br />
                  </td>
                  <td className="GridColor" align="left"></td>
                  <td className="GridColor" align="center">
                    <br />
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Simple">{formatMoney(sum_total, 2)}</span>
                  </td>
                  <td className="GridColor" align="center"></td>
                  <td className="GridColor" align="right">
                    <span className="Hd9 Simple">0.00</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className={sum_winloss >= 0 ? "Hd9 Simple" : "Negative Simple"}>{formatMoney(sum_winloss, 2)}</span>
                  </td>
                </tr> : null}
                
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
function formatMoney(n, cx, dx, tx, jx) {
  var c = isNaN((cx = Math.abs(cx))) ? 0 : cx,
    d = dx === undefined ? "." : dx,
    t = tx === undefined ? "," : tx,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (jx = i.length) > 3 ? jx % 3 : 0;

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
/*

<tr className="GridItemStyle" align="right">
                  <td className="GridColor" align="left">
                    1
                  </td>
                  <td className="GridColor" align="left">
                    <span className="bold">PAR4650033408</span>
                    <br />
                    05/10 6:05:23 pm
                  </td>
                  <td className="GridColor" align="left">
                    ฟุตบอล
                    <br />
                    มิกซ์ พาร์เลย์
                  </td>
                  <td className="GridColor" align="left">
                    <span className>05/10</span>
                    <br />
                    &nbsp;
                    <Link to="/" className="normal">
                      <span className="GBParlay Bold2">(มิกซ์ พาร์เลย์)</span>
                      <br />
                      &nbsp;@&nbsp;&nbsp;<span className="Bold">6.891</span>
                    </Link>
                  </td>
                  <td className="GridColor" align="center">
                    <span className="Normal" />
                    <span className="Error">แพ้</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Simple">10.00</span>
                  </td>
                  <td className="GridColor" align="center">
                    <span className="Accepted">ได้รับการยอมรับ</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Hd9 Simple">0.00</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Negative Simple">-10.00</span>
                  </td>
                </tr>
                <tr className="GridAltItemStyle" align="right">
                  <td className="GridColor" align="left">
                    2
                  </td>
                  <td className="GridColor" align="left">
                    <span className="bold">OU4649888862</span>
                    <br />
                    05/10 5:58:57 pm
                  </td>
                  <td className="GridColor" align="left">
                    ฟุตบอล
                    <br />
                    สูง/ต่ำ
                  </td>
                  <td className="GridColor" align="left">
                    <span className>
                      เจแปน เอ็มเพอเรอ คัพ
                      <br />
                      เกียวโต ซันงะ
                    </span>{" "}
                    -vs- <span className>ซานเฟรซเซ ฮิโรชิม่า&nbsp;&nbsp;</span>&nbsp;(ครึ่งแรก)&nbsp;
                    <br />
                    <span className>05/10</span>
                    <br />
                    <span className="GBOver Bold2">สูง</span>
                    <br />
                    -0.75&nbsp;@&nbsp;<span className>0.61(MY)</span>
                  </td>
                  <td className="GridColor" align="center">
                    <span className="Normal">0 - 1</span>
                    <br />
                    <span className="Normal">ชนะ (0.5)</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Simple">10.00</span>
                  </td>
                  <td className="GridColor" align="center">
                    <span className="Accepted">ได้รับการยอมรับ</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Hd9 Simple">0.00</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Hd9 Simple">3.05</span>
                  </td>
                </tr>
                <tr className="GridItemStyle" align="right">
                  <td className="GridColor" align="left">
                    3
                  </td>
                  <td className="GridColor" align="left">
                    <span className="bold">OU4649887294</span>
                    <br />
                    05/10 5:58:52 pm
                  </td>
                  <td className="GridColor" align="left">
                    ฟุตบอล
                    <br />
                    สูง/ต่ำ
                  </td>
                  <td className="GridColor" align="left">
                    <span className>
                      เจแปน เอ็มเพอเรอ คัพ
                      <br />
                      เกียวโต ซันงะ
                    </span>{" "}
                    -vs- <span className>ซานเฟรซเซ ฮิโรชิม่า&nbsp;&nbsp;</span>&nbsp;(ครึ่งแรก)&nbsp;
                    <br />
                    <span className>05/10</span>
                    <br />
                    <span className="GBUnder Bold2">ต่ำ</span>
                    <br />
                    0.75&nbsp;@&nbsp;<span className>-0.71(MY)</span>
                  </td>
                  <td className="GridColor" align="center">
                    <span className="Normal">0 - 1</span>
                    <br />
                    <span className="Error">แพ้ (0.5)</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Simple">10.00</span>
                  </td>
                  <td className="GridColor" align="center">
                    <span className="Accepted">ได้รับการยอมรับ</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Hd9 Simple">0.00</span>
                  </td>
                  <td className="GridColor" align="right">
                    <span className="Negative Simple">-3.55</span>
                  </td>
                </tr>
*/
