import React from "react";
import App from "./App";
import AuthContextProvider from "./context/AuthContext";
import LoadingContextProvider from "./context/LoadingContext";
import PrefixContextProvider from "./context/PrefixContext";
import "./i18nextConf";

function IndexM() {
  


  return (
    <LoadingContextProvider>
      <PrefixContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </PrefixContextProvider>
    </LoadingContextProvider>
  );
}

export default IndexM;
