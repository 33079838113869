import React, { useState, useEffect } from "react";
import tabHIcon from "../../Images/tabHIcon.png";

import { useTranslation } from "react-i18next";
import { usePrefixContext } from "../../context/PrefixContext";
import apiBet from "../../js/apiBet";
import axios from "axios";

import LoadSkeletonNews from "../LoadSkeletonNews";

export default function News() {
  const { getCurrentLanguage } = usePrefixContext();
  const { t } = useTranslation();
  const [isCompleted, setIsCompleted] = useState(false);
  const [newsListData, setNewsListData] = useState([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    async function fetchData() {
      try {
        setIsCompleted(false);
        apiBet.defaults.headers.common["x-lang"] = getCurrentLanguage();
        await apiBet
          .get(`getNews`, { cancelToken: cancelToken.token })
          .then((res) => {
            //console.log(res.data.data);
            setNewsListData(res.data.data);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              //console.log("cancel");
            }
          });
        setIsCompleted(true);
      } catch (error) {
        setIsCompleted(true);
      }
    }
    fetchData();
    return () => {
      cancelToken.cancel();
    };
  }, [getCurrentLanguage]);

  return (
    <div style={{ marginLeft: "0px", marginRight: "0px", marginTop: "0px" }} className="Backgroundx ">
      <div style={{ height: "12px" }}></div>
      <div className="newsTB">
        <div></div>
        <div style={{ width: "775px" }} className="tabGridBg1">
          <table width="100%" height="100%" border="0" cellSpacing="1" cellPadding="0">
            <tbody>
              <tr className="tabHeaderBg">
                <td>
                  <table width="100%" height="100%" border="0" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr>
                        <td style={{ width: "10px" }}></td>
                        <td style={{ width: "25px" }} align="left">
                          <img alt="" src={tabHIcon} />
                        </td>
                        <td align="left" className="GridHeaderx">
                          {t("Messages")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td className="tabGridBg2">
                  <table width="100%" height="100%" border="0" cellSpacing="5" cellPadding="0">
                    <tbody>
                      <tr>
                        <td className="tabGridBg3">
                          <table width="100%" height="100%" border="0" cellSpacing="5" cellPadding="0">
                            <tbody>
                              <tr>
                                <td className="tabGridBg4">
                                  <table
                                    className="GridStyle"
                                    cellSpacing={0}
                                    cellPadding={3}
                                    align="Center"
                                    rules="all"
                                    border={1}
                                    id="g"
                                    style={{
                                      width: "100%",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    <tbody>
                                      <tr className="GridHeaderStyle" align="center">
                                        <td style={{ width: 80 }}> {t("Date_Time")} </td>
                                        <td> {t("Messages")} </td>
                                      </tr>

                                      {isCompleted === true ? (
                                        newsListData.map(function (tlist, j) {
                                          var bgR = "";
                                          if (j % 2 === 0) {
                                            bgR = "GridItemStyle";
                                          } else {
                                            bgR = "GridAltItemStyle";
                                          }
                                          return (
                                            <tr className={bgR} align="center" key={j}>
                                              <td className="Normal_NoBold GridColor" >
                                                {" "}
                                                {tlist[0]}{" "}
                                              </td>
                                              <td className="Normal_NoBold GridColor" align="left" valign="top">
                                                {" "}
                                                {(tlist[2]===99 ? <font color="red"><font size="2">{tlist[1]}</font></font> : tlist[1])}{" "}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <LoadSkeletonNews />
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
