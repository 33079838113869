import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function LoadSkeletonStake() {
  return (
    <SkeletonTheme baseColor="#816847" highlightColor="#d0bf70" duration={1}>
      <table width="100%" height="100%" border={0} cellSpacing={0} cellPadding={5} className="GridTable">
        <tbody>
          <tr>
            <td className="r GridItemStyle AccItemStyle" width="30%">
              <Skeleton />
            </td>
            <td className="l GridAltItemStyle AccItemStyle" width="70%">
              <Skeleton />
            </td>
          </tr>
          <tr>
            <td className="r GridItemStyle AccItemStyle">
              <Skeleton />
            </td>
            <td className="l GridAltItemStyle AccItemStyle">
              <Skeleton />
            </td>
          </tr>
          <tr>
            <td className="r GridItemStyle AccAltItemStyle">
              <Skeleton />
            </td>
            <td className="l GridAltItemStyle AccAltItemStyle">
              <Skeleton />
            </td>
          </tr>
          <tr>
            <td className="r GridItemStyle AccItemStyle">
              <Skeleton />
            </td>
            <td className="l GridAltItemStyle AccItemStyle">
              <Skeleton />
            </td>
          </tr>
          <tr>
            <td className="r GridItemStyle AccItemStyle">
              <Skeleton />
            </td>
            <td className="l GridAltItemStyle AccItemStyle">
              <Skeleton />
            </td>
          </tr>
          <tr>
            <td className="r GridItemStyle AccAltItemStyle">
              <Skeleton />
            </td>
            <td className="l GridAltItemStyle AccAltItemStyle">
              <Skeleton />
            </td>
          </tr>
        </tbody>
      </table>
    </SkeletonTheme>
  );
}
